import { v4 as uuid } from "uuid"

const state = {
	alert: []
}

const getters = {
	getAlert: (state) => [...state.alert].reverse().slice(0, 4)
}

const actions = {
	appendAlert({ commit }, alert) {
		let id = Math.floor(Math.random() * 10000)
		console.log({ alert })
		const formattedAlert = { id, ...alert }
		console.log({ formattedAlert })
		commit("setAlert", formattedAlert)
	},

	removeAlertById({ commit }, id) {
		commit("removeAlert", id)
	}
}

const mutations = {
	setAlert: (state, alertContent) => {
		state.alert.push(alertContent)

		setTimeout(() => {
			let temp = state.alert
			temp.shift()
			state.alert = temp
		}, 15000)
	},
	removeAlert: (state, id) => (state.alert = state.alert.filter((a) => a.id !== id))
}

export default {
	state,
	getters,
	actions,
	mutations
}
