<template>
	<section class="p-3 md:p-9 min-h-screen overflow-y-auto">
		<!-- header -->
		<div class="flex md:flex-row flex-col justify-between md:items-center ">
			<h1 class="font-bold text-gray-900 dark:text-gray-300 text-xl md:text-3xl">
				Staff Information
			</h1>
			<!-- control -->
			<div class="mt-3 md:mt-0  flex items-center justify-between space-x-4">
				<Loader
					v-if="getStaffFilterLoading"
					class="flex-shrink-0"
					:smallBrand="true"
				/>
				<!-- search -->
				<div
					class="px-3 py-2 w-full flex items-center justify-between bg-white dark:bg-darkLight border-gray-300 border dark:border-darkForeground rounded shadow"
				>
					<input
						@input="handleFilter"
						v-model="searchQuery"
						type="text"
						placeholder="Search by email"
						class="w-full h-full flex-shrink focus:outline-none text-xs bg-transparent dark:placeholder-gray-300 placeholder-gray-400 text-gray-800 dark:text-white"
					/>
					<svg
						class="w-4 h-4 flex-shrink-0 text-gray-400"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fill-rule="evenodd"
							d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
							clip-rule="evenodd"
						/>
					</svg>
				</div>
				<!-- end of search -->
				<!-- status filter -->
				<Menu as="div" class="relative" v-slot="{ open }">
					<MenuButton
						:class="{
							'bg-gray-400 dark:bg-darkActive text-white': open,
							'text-gray-800  dark:text-gray-300': !open
						}"
						class="px-3 py-2 text-xs font-medium bg-gray-300 dark:bg-darkLight hover:bg-gray-400 hover:text-white dark:hover:bg-darkActive rounded-md flex items-center justify-between focus:outline-none transition-all duration-300 outline-none"
					>
						<span>{{ statusFilter === "none" ? "Status" : statusFilter }}</span>
						<svg class="h-4 w-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
							<path
								fill-rule="evenodd"
								d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
								clip-rule="evenodd"
							/>
						</svg>
					</MenuButton>

					<Transition
						enter-active-class="transition transform duration-100 ease-in"
						enter-from-class="opacity-0 scale-95"
						enter-to-class="opacity-100 scale-100"
						leave-active-class="transition transform duration-300 ease-out"
						leave-from-class="opacity-100 scale-100"
						leave-to-class="opacity-0 scale-95"
					>
						<MenuItems
							class="absolute z-50 py-2 w-36 mt-12 top-0 right-0 focus:outline-none outline-none rounded-md shadow-lg bg-white  dark:bg-darkLight overflow-hidden"
						>
							<MenuItem @click="handleStatusFilter('none')" v-slot="{ active }">
								<div
									:class="[
										active
											? 'bg-gray-100 dark:bg-darkActive dark:text-white text-gray-400'
											: 'bg-white text-gray-800 dark:text-gray-500  dark:bg-darkLight'
									]"
									class="px-3 py-2 cursor-pointer text-xs focus:outline-none outline-none"
								>
									None
								</div>
							</MenuItem>
							<MenuItem @click="handleStatusFilter('Active')" v-slot="{ active }">
								<div
									:class="[
										active
											? 'bg-gray-100 dark:bg-darkActive text-green-500'
											: 'bg-white text-gray-800 dark:text-gray-500  dark:bg-darkLight'
									]"
									class="px-3 py-2 cursor-pointer text-xs focus:outline-none outline-none"
								>
									Active
								</div>
							</MenuItem>
							<MenuItem @click="handleStatusFilter('Deactive')" v-slot="{ active }">
								<div
									:class="[
										active
											? 'bg-gray-100 dark:bg-darkActive text-yellow-500'
											: 'bg-white text-gray-800 dark:text-gray-500  dark:bg-darkLight'
									]"
									class="px-3 py-2 cursor-pointer text-xs focus:outline-none outline-none"
								>
									Deactive
								</div>
							</MenuItem>
							<MenuItem @click="handleStatusFilter('Blocked')" v-slot="{ active }">
								<div
									:class="[
										active
											? 'bg-gray-100 dark:bg-darkActive text-red-500'
											: 'bg-white text-gray-800 dark:text-gray-500  dark:bg-darkLight'
									]"
									class="px-3 py-2 cursor-pointer text-xs focus:outline-none outline-none"
								>
									Blocked
								</div>
							</MenuItem>
						</MenuItems>
					</Transition>
				</Menu>
				<!-- end of status filter -->

				<!-- add staff -->
				<button
					@click="handleAddStaffModal"
					class="px-3 py-2 text-xs font-medium text-white bg-gray-800 hover:bg-gray-700 rounded-md flex items-center flex-shrink-0 justify-between transition-all duration-300 focus:outline-none outline-none"
				>
					<svg class="w-4 h-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
						<path
							fill-rule="evenodd"
							d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
							clip-rule="evenodd"
						/>
					</svg>
					<span class="mr-1">Add staff</span>
				</button>
				<!-- end of add staff -->
			</div>
			<!-- end of control -->
		</div>
		<!-- end of header -->

		<!-- table -->
		<main class="mt-8 min-h-table overflow-y-hidden overflow-x-auto">
			<header
				class="min-w-table h-12 px-4 w-full flex-shrink-0 flex items-center justify-center bg-gray-100 dark:bg-darkWhite rounded-md border border-gray-300 dark:border-darkForeground shadow"
			>
				<CustomCheckbox v-if="getStaffs.length > 0" :selected="allStaffSelected">
					<input
						@input="handleAllStaffSelect"
						v-model="allStaffSelected"
						type="checkbox"
						class="absolute inset-y-0 my-auto mx-auto z-10 appearance-none w-5 h-5 checked:bg-brandColorDark dark:checked:bg-brandColorDark checked:border-brandColor bg-white dark:bg-gray-500 rounded border-2 dark:border-darkLight border-gray-300 focus:outline-none cursor-pointer transition-colors duration-300 outline-none overflow-hidden"
					/>
				</CustomCheckbox>

				<section
					class="ml-4 grid grid-cols-9 w-full items-center place-content-center select-none"
				>
					<div
						class="col-span-2 uppercase text-xs font-semibold text-gray-500 dark:text-gray-300"
					>
						user
					</div>
					<div
						class="uppercase text-xs font-semibold text-gray-500 dark:text-gray-300"
					>
						Phone number
					</div>
					<div
						class="uppercase text-xs font-semibold text-gray-500 dark:text-gray-300"
					>
						country
					</div>
					<div
						class="uppercase text-xs font-semibold text-gray-500 dark:text-gray-300"
					>
						position
					</div>
					<div
						class="uppercase text-xs font-semibold text-gray-500 dark:text-gray-300"
					>
						join date
					</div>
					<div
						class="uppercase text-xs font-semibold text-gray-500 dark:text-gray-300"
					>
						Salary
					</div>
					<div
						class="uppercase text-xs font-semibold text-gray-500 dark:text-gray-300"
					>
						status
					</div>
					<!-- action button -->
					<div
						v-if="individualStaffSelected.length > 0 || allStaffSelected"
						class="ml-auto mt-1"
					>
						<Menu
							as="div"
							class="relative focus:outline-none outline-none"
							v-slot="{ open }"
						>
							<MenuButton class="focus:outline-none outline-none">
								<EffectButton
									:rounded="true"
									:active="open"
									class="focus:outline-none outline-none"
								>
									<svg
										class="w-5 h-5 text-gray-700 dark:text-gray-400"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
										/>
									</svg>
								</EffectButton>
							</MenuButton>
							<Transition
								enter-active-class="transition transform duration-100 ease-in"
								enter-from-class="opacity-0 scale-95"
								enter-to-class="opacity-100 scale-100"
								leave-active-class="transition transform duration-300 ease-out"
								leave-from-class="opacity-100 scale-100"
								leave-to-class="opacity-0 scale-95"
							>
								<MenuItems
									class="absolute z-50 py-2 w-36 mt-12 top-0 right-0 focus:outline-none outline-none rounded-md shadow-lg bg-white  dark:bg-darkLight overflow-hidden border border-gray-300 dark:border-darkBackground"
								>
									<MenuItem
										@click="handleSelectedStatusChange('Active')"
										v-slot="{ active }"
										class="focus:outline-none outline-none"
									>
										<div
											:class="[
												active
													? 'bg-gray-100 dark:bg-darkActive text-green-500'
													: 'bg-white text-gray-800 dark:text-gray-500  dark:bg-darkLight'
											]"
											class="px-3 py-2 cursor-pointer text-xs focus:outline-none outline-none"
										>
											Active
										</div>
									</MenuItem>
									<MenuItem
										@click="handleSelectedStatusChange('Deactive')"
										v-slot="{ active }"
										class="focus:outline-none outline-none"
									>
										<div
											:class="[
												active
													? 'bg-gray-100 dark:bg-darkActive text-yellow-500'
													: 'bg-white text-gray-800 dark:text-gray-500  dark:bg-darkLight'
											]"
											class="px-3 py-2 cursor-pointer text-xs focus:outline-none outline-none"
										>
											Deactive
										</div>
									</MenuItem>
									<MenuItem
										@click="handleSelectedStatusChange('Blocked')"
										v-slot="{ active }"
										class="focus:outline-none outline-none"
									>
										<div
											:class="[
												active
													? 'bg-gray-100 dark:bg-darkActive text-red-500'
													: 'bg-white text-gray-800 dark:text-gray-500  dark:bg-darkLight'
											]"
											class="px-3 py-2 cursor-pointer text-xs focus:outline-none outline-none"
										>
											Blocked
										</div>
									</MenuItem>

									<div
										class="border-t mt-2 pb-2 border-gray-300 dark:border-gray-700"
									></div>

									<MenuItem v-slot="{ active }">
										<div
											:class="[
												active
													? 'bg-gray-100 dark:bg-darkActive dark:text-white text-gray-800'
													: 'bg-white text-gray-500 dark:text-gray-500  dark:bg-darkLight '
											]"
											class="px-3 py-2 cursor-pointer text-xs flex items-center justify-start space-x-2 focus:outline-none"
										>
											<svg
												class="w-4 h-4"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
												/>
											</svg>
											<span>Send email</span>
										</div>
									</MenuItem>
								</MenuItems>
							</Transition>
						</Menu>
					</div>
				</section>
			</header>

			<div
				v-if="getStaffs.length === 0"
				class="h-72 w-full flex items-center justify-center"
			>
				<h1 v-if="!getStaffLoading" class="text-xs text-gray-500">
					No staff Members to show
				</h1>
			</div>

			<Transition
				enter-active-class="transition transform duration-100 ease-in"
				enter-from-class="opacity-50"
				enter-to-class="opacity-100"
				leave-active-class="transition transform duration-300 ease-out"
				leave-from-class="opacity-100"
				leave-to-class="opacity-0"
			>
				<div
					v-if="getStaffLoading"
					:class="[sidebarToggled ? 'md:ml-72 mt-16' : 'md:ml-16 mt-16']"
					class="fixed z-30 inset-0 flex items-center justify-center transition duration-300"
				>
					<Loader :large="true" :brand="true" />
				</div>
			</Transition>

			<main
				class="mt-4 max-h-table min-h-table min-w-table overflow-y-auto overflow-x-hidden flex flex-col space-y-4"
			>
				<section
					v-for="staff in getStaffs"
					:key="staff.id"
					class="min-w-table px-4 h-26 py-3 w-full flex items-center justify-center bg-white dark:bg-darkWhite rounded-md hover:shadow-md cursor-pointer"
				>
					<CustomCheckbox :selected="staff.selected">
						<input
							@input="handleIndividualStaffSelect(staff.id)"
							:checked="staff.selected"
							type="checkbox"
							class="absolute inset-y-0 my-auto mx-auto z-10 appearance-none w-5 h-5 checked:bg-brandColorDark dark:checked:bg-brandColorDark checked:border-brandColor bg-white dark:bg-gray-500 rounded border-2 dark:border-darkLight border-gray-300 focus:outline-none cursor-pointer transition-all duration-300 outline-none overflow-hidden"
						/>
					</CustomCheckbox>

					<section
						class="ml-4 grid grid-cols-9 w-full items-center place-content-center select-none"
					>
						<div class="col-span-2 py-1 font-semibold text-gray-500 dark:text-white">
							<div class="flex items-center space-x-4">
								<Avatar class="flex-shrink-0 overflow-hidden" :img="staff.profile" />
								<div class="">
									<h1 class="text-sm w-56 truncate text-gray-800 dark:text-white">
										{{ staff.firstName }} {{ staff.lastName }}
									</h1>
									<h3 class="text-xs w-56 truncate text-gray-500 dark:text-gray-400">
										{{ staff.email }}
									</h3>
								</div>
							</div>
						</div>
						<div class="w-36 truncate text-xs  text-gray-500 dark:text-white">
							{{ staff.phone }}
						</div>
						<div class="w-36 truncate text-xs  text-gray-500 dark:text-white">
							{{ staff.country }}
						</div>
						<div class="text-xs w-36 truncate  text-gray-500 dark:text-white">
							{{ staff.position }}
						</div>
						<div class="text-xs w-36 truncate text-gray-500 dark:text-white">
							{{ staff.createdAt.split("T")[0] }}
						</div>
						<div class="text-xs w-36 truncate text-gray-500 dark:text-white">
							Rs {{ staff.salary }}
						</div>
						<div class="text-xs font-semibold text-gray-500 dark:text-white">
							<Badge :type="staff.status" />
						</div>
						<!-- action button -->
						<div class="ml-auto">
							<Menu
								as="div"
								class="relative focus:outline-none outline-none"
								v-slot="{ open }"
							>
								<MenuButton class=" focus:outline-none outline-none">
									<EffectButton
										id="menuBtn"
										ref="menuBtn"
										:rounded="true"
										:active="open"
										class="focus:outline-none outline-none"
									>
										<svg
											class="w-5 h-5 text-gray-700 dark:text-gray-400"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
											/>
										</svg>
									</EffectButton>
								</MenuButton>
								<Transition
									class="absolute z-50"
									enter-active-class="transition transform duration-100 ease-in"
									enter-from-class="opacity-0 scale-95"
									enter-to-class="opacity-100 scale-100"
									leave-active-class="transition transform duration-300 ease-out"
									leave-from-class="opacity-100 scale-100"
									leave-to-class="opacity-0 scale-95"
								>
									<MenuItems
										ref="menuItems"
										id="menuItems"
										class="absolute z-50 py-2 w-36 mt-12 top-0 right-0 focus:outline-none outline-none rounded-md shadow-lg bg-white  dark:bg-darkLight overflow-hidden border border-gray-300 dark:border-darkBackground"
									>
										<router-link :to="`/staffprofile/${staff.id}/personal`">
											<MenuItem v-slot="{ active }">
												<div
													:class="[
														active
															? 'bg-gray-100 dark:bg-darkActive dark:text-white text-gray-800'
															: 'bg-white text-gray-500 dark:text-gray-500  dark:bg-darkLight'
													]"
													class="px-3 py-2 cursor-pointer text-xs flex items-center justify-start space-x-2 focus:outline-none"
												>
													<svg
														class="w-4 h-4"
														fill="none"
														viewBox="0 0 24 24"
														stroke="currentColor"
													>
														<path
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
															d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
														/>
														<path
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
															d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
														/>
													</svg>
													<span>View details</span>
												</div>
											</MenuItem>
										</router-link>
										<MenuItem
											@click="handleEmailCopy(staff.email)"
											v-slot="{ active }"
										>
											<div
												:class="[
													active
														? 'bg-gray-100 dark:bg-darkActive dark:text-white text-gray-800'
														: 'bg-white text-gray-500 dark:text-gray-500  dark:bg-darkLight'
												]"
												class="px-3 py-2 cursor-pointer text-xs flex items-center justify-start space-x-2 focus:outline-none"
											>
												<svg
													class="w-4 h-4"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														stroke-width="2"
														d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
													/>
												</svg>
												<span>Send email</span>
											</div>
										</MenuItem>
										<MenuItem v-slot="{ active }">
											<div
												:class="[
													active
														? 'bg-gray-100 dark:bg-darkActive dark:text-white text-gray-800'
														: 'bg-white text-gray-500 dark:text-gray-500  dark:bg-darkLight'
												]"
												class="px-3 py-2 cursor-pointer text-xs flex items-center justify-start space-x-2 focus:outline-none"
											>
												<svg
													class="w-4 h-4"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														stroke-width="2"
														d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
													/>
												</svg>
												<span>Reset password</span>
											</div>
										</MenuItem>
									</MenuItems>
								</Transition>
							</Menu>
						</div>
					</section>
				</section>
			</main>

			<!-- table footer -->
		</main>
		<!-- table footer -->
		<div
			class="mt-4 py-5 px-5 rounded-md bg-white dark:bg-darkWhite flex flex-col md:flex-row items-center justify-between"
		>
			<Pagination :pages="getStaffPages" v-on:onClick="currentPage = $event" />

			<div class="flex items-center space-x-6">
				<div class="">
					<Select
						:opts="getStaffListSize"
						v-on:onChange="staffsPerPage = $event.name"
					/>
				</div>
				<h1 class="mt-4 md:mt-0 text-gray-700 dark:text-gray-300 text-xs md:text-sm">
					page{{ getStaffPages === 0 ? 0 : currentPage }} of {{ getStaffPages }}
				</h1>
			</div>
		</div>
		<!-- end of table -->
	</section>

	<Modal :loading="getStaffLoading">
		<div
			class="py-10 flex flex-col xl:flex-row items-start justify-between xl:space-x-20 2xl:space-x-64"
		>
			<div class="flex-shrink-0">
				<h1
					class="text-xl md:text-2xl font-semibold text-gray-900 dark:text-gray-200"
				>
					Personal Information
				</h1>
				<h3 class="text-gray-500 dark:text-gray-400 text-sm">
					Use permanant address where you can recieve mail
				</h3>
			</div>

			<!-- personal information -->
			<div
				class="w-full mt-10 xl:mt-0 bg-white dark:bg-darkWhite shadow-md rounded-md  border border-gray-300 dark:border-gray-700"
			>
				<Form
					@submit="handleSubmit"
					v-slot="{ handleReset }"
					:validation-schema="personalInformationSchema"
					:initial-values="formValues"
				>
					<div class="p-6 grid grid-cols-1 md:grid-cols-3 gap-x-12 gap-y-8">
						<div class="w-full flex flex-col flex-shrink">
							<label
								id="profileLabel"
								for="profile"
								class="flex-shrink-0 font-medium text-gray-900 dark:text-gray-300 text-sm"
								>Profile</label
							>
							<div class="mt-1 flex items-center space-x-4">
								<Avatar
									ref="staffProfileInput"
									id="preview_img"
									:large="true"
									img="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
								/>
								<label
									id="profileLabel"
									for="profile"
									class="px-5 py-2 border border-gray-300 dark:border-gray-700 rounded-md flex-shrink-0 font-medium text-gray-900 dark:text-gray-300 text-sm cursor-pointer hover:bg-gray-100 transition duration-300"
									>{{ profile ? "Change" : "Choose" }}
									<input
										@input="handleProfileInput"
										type="file"
										id="profile"
										accept=".jpg, .jpeg, .png"
										aria-labelledby="profileLabel"
										placeholder="Choose image"
										class="hidden w-full flex-shrink focus:ring-gray-800 border"
									/>
									<div
										v-if="profileError || getStaffErrors.profile"
										class="mt-1 text-sm text-red-700 dark:text-red-500"
									>
										{{
											getStaffErrors.profile ? getStaffErrors.profile : profileError
										}}
									</div>
								</label>
							</div>
						</div>

						<Field
							name="firstName"
							v-slot="{ field, errorMessage }"
							:validateOnBlur="true"
							:validateOnInput="true"
							:validateOnChange="false"
						>
							<TextInput
								class="w-1/2"
								v-bind="field"
								:error="errorMessage"
								label="First name"
								placeholder="Joe"
							/>
						</Field>

						<Field
							name="lastName"
							v-slot="{ field, errorMessage }"
							:validateOnBlur="true"
							:validateOnInput="true"
							:validateOnChange="false"
						>
							<TextInput
								class="w-1/2"
								label="Last name"
								v-bind="field"
								:error="errorMessage"
								placeholder="Doe"
							/>
						</Field>

						<Field
							name="email"
							v-slot="{ field, errorMessage }"
							:validateOnBlur="true"
							:validateOnInput="false"
							:validateOnChange="false"
						>
							<TextInput
								v-bind="field"
								:error="
									errorMessage || (getStaffErrors.email && getStaffErrors.email)
								"
								type="email"
								class="w-1/2"
								label="Email"
								placeholder="JoeDoe@exvaxo.com"
							/>
						</Field>

						<Field
							name="dob"
							v-slot="{ field, errorMessage }"
							:validateOnBlur="true"
							:validateOnInput="true"
							:validateOnChange="false"
						>
							<TextInput
								class="w-1/2"
								type="date"
								v-bind="field"
								:error="errorMessage"
								label="Date of birth"
								placeholder="dd/mm/yyyy"
							/>
						</Field>

						<Field
							name="phone"
							v-slot="{ field, errorMessage }"
							:validateOnBlur="true"
							:validateOnInput="true"
							:validateOnChange="false"
						>
							<InputWithSelect
								class="w-1/2"
								v-bind="field"
								:error="errorMessage"
								label="Phone number"
								:opts="getPhoneCodes"
								v-on:onChange="numberCode = $event.value"
								placeholder="### ### ####"
							/>
						</Field>

						<Select
							:opts="getCountries"
							label="Country"
							v-on:onChange="country = $event.name"
						/>
					</div>

					<div class="px-6 md:mt-4 w-full">
						<Field
							name="address"
							v-slot="{ field, errorMessage }"
							:validateOnBlur="true"
							:validateOnInput="true"
							:validateOnChange="false"
						>
							<TextInput
								v-bind="field"
								:error="errorMessage"
								label="Street Address"
								placeholder="No## Temple Street..."
							/>
						</Field>
					</div>

					<div
						class="p-6 w-full flex-shrink md:mt-8 grid grid-cols-1 md:grid-cols-3 gap-5"
					>
						<Field
							name="city"
							v-slot="{ field, errorMessage }"
							:validateOnBlur="true"
							:validateOnInput="true"
							:validateOnChange="false"
						>
							<TextInput
								v-bind="field"
								:error="errorMessage"
								label="City"
								placeholder="Vavuniya"
							/>
						</Field>

						<Field
							name="state"
							v-slot="{ field, errorMessage }"
							:validateOnBlur="true"
							:validateOnInput="true"
							:validateOnChange="false"
						>
							<TextInput
								v-bind="field"
								:error="errorMessage"
								label="Province/State"
								placeholder="Northern"
							/>
						</Field>

						<Field
							name="postalCode"
							v-slot="{ field, errorMessage }"
							:validateOnBlur="true"
							:validateOnInput="true"
							:validateOnChange="false"
						>
							<TextInput
								v-bind="field"
								:error="errorMessage"
								label="Postal code"
								placeholder="######"
							/>
						</Field>
					</div>

					<!-- submit and reset -->

					<div class="py-4 px-6 bg-gray-100 dark:bg-darkBackground rounded-b-md">
						<div class="flex items-center justify-end w-full space-x-6">
							<button
								@click.prevent="handlePersonalInformationNativeReset(handleReset)"
								class="py-2 px-5 border border-gray-600 dark:border-gray-700 rounded-md text-gray-800 dark:text-gray-300 font-medium text-sm focus:outline-none outline-none dark:hover:bg-gray-900 hover:bg-gray-200 transition-color duration-300"
							>
								Reset
							</button>
							<button
								type="submit"
								class="py-2 px-5 border border-gray-800 bg-gray-800 rounded-md text-white font-medium text-sm focus:outline-none outline-none hover:bg-gray-700 transition-color duration-300"
							>
								Save
							</button>
						</div>
					</div>
				</Form>
			</div>
		</div>

		<!-- divder -->
		<div class="w-full border-t border-gray-300 dark:border-gray-700"></div>
		<!-- end of divder -->

		<!-- companyInformation -->
		<div
			class="py-10 flex flex-col xl:flex-row items-start justify-between xl:space-x-20 2xl:space-x-64"
		>
			<div class="flex-shrink-0">
				<h1
					class="text-xl  md:text-2xl font-semibold text-gray-900 dark:text-gray-200"
				>
					Company Information
				</h1>
				<h3 class="text-gray-500 text-sm dark:text-gray-300">
					Use permanant address where you can recieve mail
				</h3>
			</div>

			<div
				class="relative w-full mt-10 xl:mt-0 bg-white dark:bg-darkWhite shadow-md rounded-md  border border-gray-300 dark:border-gray-700"
			>
				<div
					v-if="!personalInformation"
					class="formDisabled absolute inset-0 z-50 rounded-md"
				></div>
				<Form
					@submit="handleCompanySubmit"
					v-slot="{ handleReset }"
					:validation-schema="companyInformationSchema"
					:initial-values="companyFormValues"
				>
					<div class="p-6 grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-8">
						<Select
							:opts="getPositions"
							label="Position"
							v-on:onChange="position = $event.name"
						/>

						<Field
							name="salary"
							v-slot="{ field, errorMessage }"
							:validateOnBlur="true"
							:validateOnInput="true"
							:validateOnChange="false"
						>
							<TextInput
								salaryRef
								v-bind="field"
								:error="errorMessage"
								label="Salary"
								placeholder="25000"
							/>
						</Field>
					</div>

					<!-- submit and reset -->
					<div class="py-4 px-6 bg-gray-100 dark:bg-darkBackground rounded-b-md">
						<div class="flex items-center justify-end w-full space-x-6">
							<button
								@click.prevent="handleCompanyInformationNativeReset(handleReset)"
								class="py-2 px-5 border border-gray-600 dark:border-gray-700 rounded-md text-gray-800 dark:text-gray-300 font-medium text-sm focus:outline-none outline-none dark:hover:bg-gray-900 hover:bg-gray-200 transition-color duration-300"
							>
								Reset
							</button>
							<button
								ref="companyInformationFormRef"
								type="submit"
								class="py-2 px-5 border border-gray-800 bg-gray-800 rounded-md text-white font-medium text-sm focus:outline-none outline-none hover:bg-gray-700 transition-color duration-300"
							>
								Save
							</button>
						</div>
					</div>
				</Form>
			</div>
		</div>
	</Modal>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue"
import EffectButton from "../components/utils/EffectButton.vue"
import CustomCheckbox from "../components/inputs/CustomCheckbox.vue"
import TextInput from "../components/inputs/TextInput.vue"
import InputWithSelect from "../components/inputs/InputWithSelect.vue"
import Select from "../components/inputs/Select.vue"
import Avatar from "../components/utils/Avatar.vue"
import Badge from "../components/utils/Badge.vue"
import { mapActions, mapGetters } from "vuex"
import Modal from "../components/Modal.vue"
import { Form, Field, ErrorMessage } from "vee-validate"
import * as yup from "yup"
import Loader from "../components/utils/Loader.vue"
import Pagination from "../components/utils/Pagination.vue"

export default {
	name: "Staff",
	props: ["sidebarToggled"],
	components: {
		Menu,
		MenuButton,
		MenuItems,
		MenuItem,
		EffectButton,
		CustomCheckbox,
		Avatar,
		Badge,
		Modal,
		TextInput,
		InputWithSelect,
		Select,
		Form,
		Field,
		ErrorMessage,
		Loader,
		Pagination
	},

	methods: {
		...mapActions(["setAddStaffModalState"]),
		...mapActions(["createStaff"]),
		...mapActions(["getAllStaff"]),
		...mapActions(["filterStaff"]),
		...mapActions(["filterStaffStatus"]),
		...mapActions(["appendAlert"]),
		...mapActions(["selectIndividualStaff"]),
		...mapActions(["selectAllStaff"]),
		...mapActions(["changeSelectedStaffStatus"]),
		...mapActions(["clearStaffErrors"]),

		handleSelectedStatusChange(type) {
			this.changeSelectedStaffStatus(type)
			console.log({ type, filterStatus: this.statusFilter })
			setTimeout(() => {
				this.filterStaffStatus({
					type: this.statusFilter,
					size: this.getStaffListSize[0].name,
					page: 0
				})
			}, 800)

			this.allStaffSelected = false
		},

		handleAllStaffSelect() {
			this.individualStaffSelected = []
			this.selectAllStaff(!this.allStaffSelected)
		},

		handleIndividualStaffSelect(id) {
			if (this.individualStaffSelected.includes(id)) {
				this.individualStaffSelected = this.individualStaffSelected.filter(
					(uid) => uid !== id
				)
			} else {
				this.individualStaffSelected.push(id)
			}

			this.selectIndividualStaff(id)
		},

		handleStatusFilter(type) {
			this.allStaffSelected = false
			this.individualStaffSelected = []
			this.statusFilter = type
			if (type !== "none") {
				this.filterStaffStatus({
					type,
					size: this.getStaffListSize[0].name,
					page: 0
				})
			} else {
				this.getAllStaff({ size: this.getStaffListSize[0].name, page: 0 })
			}
		},

		handleFilter(e) {
			this.filterStaff({
				query: e.target.value,
				size: this.getStaffListSize[0].name,
				page: 0
			})
		},

		handleAddStaffModal() {
			this.setAddStaffModalState(true)
		},

		async handleEmailCopy(email) {
			navigator.clipboard
				.writeText(email)
				.then(() => {
					this.appendAlert({
						title: "Email Copied!",
						sub: `${email} has been copied to clipboard`,
						icon: "heroicons-solid:check-circle",
						type: "success"
					})
				})
				.catch(() => console.log("copying email failed"))
		},

		handleSubmit(form) {
			const personalInformationForm = {
				...form,
				profile: this.profile,
				country: this.country,
				phone: `${this.numberCode}${form.phone}`
			}

			this.personalInformation = personalInformationForm
			this.$refs.companyInformationFormRef.scrollIntoView({ behavior: "smooth" })
		},

		handleCompanySubmit(form) {
			const comapnyInformationForm = {
				...this.personalInformation,
				...form,
				position: this.position
			}
			this.createStaff(comapnyInformationForm)
		},

		handleCompanyInformationNativeReset(reset) {
			reset()
		},

		handlePersonalInformationNativeReset(reset) {
			this.clearStaffErrors()
			reset()
			this.personalInformation = null
			this.country = this.getCountries[0].name
			this.numberCode = this.getPhoneCodes[0].value
			this.profile = null
			this.profileError = null
			const output = document.getElementById("preview_img")
			output.src =
				"https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
		},

		handleProfileInput(e) {
			const file = e.target.files[0]

			const types = ["image/jpeg", "image/jpg", "image/png"]

			if (file) {
				if (!types.includes(file.type)) {
					this.profileError = "Only jpg,jpeg and png are allowed"
					this.formValues.profile = null
				} else {
					this.profileError = null
					this.profile = file
					const reader = new FileReader()
					reader.onload = function() {
						const output = document.getElementById("preview_img")
						output.src = reader.result
					}
					reader.readAsDataURL(file)
				}
			} else {
				this.formValues.profile = null
			}
		}
	},

	mounted() {
		this.country = this.getCountries[0].name
		this.numberCode = this.getPhoneCodes[0].value
		this.position = this.getPositions[0].name
		this.getAllStaff({ size: this.getStaffListSize[0].name, page: 0 })
		this.staffsPerPage = this.getStaffListSize[0].name
	},

	computed: {
		...mapGetters(["getStaffs"]),
		...mapGetters(["getCountries"]),
		...mapGetters(["getPhoneCodes"]),
		...mapGetters(["getPositions"]),
		...mapGetters(["getStaffLoading"]),
		...mapGetters(["getStaffListSize"]),
		...mapGetters(["getStaffPages"]),
		...mapGetters(["getStaffFilterLoading"]),
		...mapGetters(["getStaffErrors"]),

		date() {
			let d = new Date()
			return d.getFullYear()
		}
	},

	data() {
		const companyInformationSchema = yup.object({
			salary: yup
				.number()
				.typeError("That doesn't look like a salary")
				.required()
		})
		const personalInformationSchema = yup.object({
			profile: yup.string().required(),
			email: yup
				.string()
				.required()
				.email(),
			firstName: yup.string().required("First name is required"),
			lastName: yup.string().required("Last name is required"),
			dob: yup
				.date()
				.required("Date of birth is required")
				.typeError("That doesn't look like a date"),
			address: yup.string().required("Address is required"),
			city: yup.string().required("City is required"),
			state: yup.string().required("Province / State is required"),
			phone: yup
				.number("Phone number cannot contain text")
				.typeError("That doesn't look like a phone number")
				.required("Phone number is required"),
			postalCode: yup.string().required("Postal code is required")
		})

		return {
			personalInformation: null,
			comapanyInformation: null,
			personalInformationSchema,
			companyInformationSchema,
			profileError: null,
			profile: null,
			country: null,
			position: null,
			numberCode: null,
			formValues: {
				firstName: "",
				lastName: "",
				email: "",
				phone: "",
				state: "",
				postalCode: "",
				city: "",
				dob: "",
				profile: null
			},
			companyFormValues: {
				salary: ""
			},
			staffsPerPage: 25,
			currentPage: 1,
			searchQuery: "",
			statusFilter: "none",
			individualStaffSelected: [],
			allStaffSelected: false
		}
	},

	watch: {
		staffsPerPage: function(nv, ov) {
			this.allStaffSelected = false
			this.individualStaffSelected = []
			if (this.searchQuery.length > 0) {
				this.filterStaff({
					query: this.searchQuery,
					size: nv,
					page: this.currentPage - 1
				})
			} else if (this.statusFilter !== "none") {
				this.filterStaffStatus({
					type: this.statusFilter,
					size: nv,
					page: this.currentPage - 1
				})
			} else {
				this.getAllStaff({ size: nv, page: this.currentPage - 1 })
			}
		},

			searchQuery: function(nv, ov) {
			if (nv.length <= 0) {
				this.getAllStaff({ size: this.getStaffListSize[0].name, page: 0 })
			}
		},

		currentPage: function(nv, ov) {
			this.individualStaffSelected = []
			this.allStaffSelected = false
			if (this.searchQuery.length > 0) {
				this.filterStaff({
					query: this.searchQuery,
					size: this.staffsPerPage,
					page: (nv - 1) * this.staffsPerPage
				})
			} else if (this.statusFilter !== "none") {
				this.filterStaffStatus({
					type: this.statusFilter,
					size: this.staffsPerPage,
					page: (nv - 1) * this.staffsPerPage
				})
			} else {
				this.getAllStaff({
					size: this.staffsPerPage,
					page: (nv - 1) * this.staffsPerPage
				})
			}
		}
	}
}
</script>
