import { v4 as uuid } from "uuid";
import axios from "axios";
import setAuthToken from "../../config/setAuthToken";

const state = {
  affiliates: [],
  affiliatePayout: [],
  totalAffiliatePayoutAmount: 0.0,
  affiliateLoading: false,
  affiliateFilterLoading: false,
  affiliateCount: 0,
  affiliatePages: 0,
};

const getters = {
  getAffiliates: (state) => state.affiliates,
  getAffiliateLoading: (state) => state.affiliateLoading,
  getAffiliateFilterLoading: (state) => state.affiliateFilterLoading,
  getAffiliateCount: (state) => state.affiliateCount,
  getAffiliatePages: (state) => state.affiliatePages,

  getAffiliatePayout: (state) => state.affiliatePayout,
  getTotalAffiliatePayoutAmount: (state) => state.totalAffiliatePayoutAmount,
};

const actions = {
  async fetchAffiliatePayouts({ commit }, params) {
    try {
      commit("setAffiliateLoading", true);
      const { data } = await axios.get(
        `${process.env.VUE_APP_BASE_URl}/affiliate/payouts/${params.size}/${params.page}?search=${params.query}&month=${params.month}&year=${params.year}&status=${params.status}`
      );
      commit("setTotalAffiliatePayoutAmount", data.totals);
      commit("setAffiliatesPayout", data.data.rows);
      commit("setAffiliatePages", data.pages);
      commit("setAffiliateLoading", false);
    } catch (error) {
      commit("setAffiliateLoading", false);
    }
  },

  async fetchAffiliate({ commit }, params) {
    try {
      commit("setAffiliateLoading", true);
      const { data: affiliates } = await axios.get(
        `${process.env.VUE_APP_BASE_URl}/affiliate/affiliates/${params.size}/${params.page}`
      );
      console.log({ affiliates });
      commit("setAffiliates", affiliates.rows);
      commit("setAffiliateCount", affiliates.count);
      commit("setAffiliatePages", affiliates.pages);
      commit("setAffiliateLoading", false);
    } catch (error) {
      commit("setAffiliateLoading", false);
      console.log(error);
    }
  },

  /* filter user according to user name */
  async filterAffiliate({ commit }, params) {
    if (params.query.length > 0) {
      try {
        commit("setAffiliateFilterLoading", true);
        const {
          data,
        } = await axios.post(
          `${process.env.VUE_APP_BASE_URl}/affiliate/filter/${params.size}/${params.page}`,
          { query: params.query }
        );
        commit("setAffiliates", data.data.rows);
        commit("setAffiliateCount", data.data.count);
        commit("setAffiliatePages", data.pages);
        commit("setAffiliateFilterLoading", false);
      } catch (error) {
        commit("setAffiliateFilterLoading", false);
        console.log(error);
      }
    }
  },
};

const mutations = {
  setAffiliateLoading: (state, val) => (state.affiliateLoading = val),
  setAffiliateFilterLoading: (state, val) =>
    (state.affiliateFilterLoading = val),
  setAffiliates: (state, val) => (state.affiliates = val),
  setAffiliatesPayout: (state, val) => (state.affiliatePayout = val),
  setAffiliateCount: (state, val) => (state.affiliateCount = val),
  setAffiliatePages: (state, val) => (state.affiliatePages = val),
  setTotalAffiliatePayoutAmount: (state, val) =>
    (state.totalAffiliatePayoutAmount = val),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
