<template>
	<button
		@click="handleExpansion"
		class="-mx-3 px-3 py-3 flex items-center justify-between font-medium text-sm rounded-lg focus:outline-none outline-none transition-all duration-300"
		:class="expanded ? 'bg-indigo-200' : 'bg-white'"
	>
		<span class="inline-flex items-center">
			<span
				class="iconify w-5 h-5 text-gray-500"
				:data-icon="icon"
				data-inline="false"
			></span>
			<span class="ml-3 text-sm font-medium text-gray-500">{{ name }}</span>
		</span>
		<svg
			class="w-5 h-5 text-gray-500 transform transition-all duration-300"
			:class="expanded ? 'rotate-5' : '-rotate-90'"
			viewBox="0 0 20 20"
			fill="currentColor"
		>
			<path
				fill-rule="evenodd"
				d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
				clip-rule="evenodd"
			/>
		</svg>
	</button>
	<div
		class="overflow-hidden transition-height duration-500 ease-in-out"
		:class="expanded ? getHeight : 'h-0'"
	>
		<div class="py-3 pl-6 flex flex-col space-y-3 ">
			<router-link to="#">
				<span class="py-3 text-xs text-gray-600">project card</span>
			</router-link>
			<router-link to="#">
				<span class="py-3 text-xs text-gray-600">project card</span>
			</router-link>
			<router-link to="#">
				<span class="py-3 text-xs text-gray-600">project card</span>
			</router-link>
			<router-link to="#">
				<span class="py-3 text-xs text-gray-600">project card</span>
			</router-link>
			<router-link to="#">
				<span class="py-3 text-xs text-gray-600">project card</span>
			</router-link>
			<router-link to="#">
				<span class="py-3 text-xs text-gray-600">project card</span>
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: "Expander",
	components: {},
	props: ["name", "value", "active", "icon", "id", "height", "subLinks"],
	emits: ["state"],
	computed: {
		getHeight() {
			return `h-${this.height}`
		}
	},
	data() {
		return { expanded: false }
	},
	methods: {
		handleExpansion() {
			this.expanded = !this.expanded
			this.$emit("state", this.expanded)
		}
	}
}
</script>
