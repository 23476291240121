import axios from "axios";
import { v4 as uuid } from "uuid";

const state = {
  authors: [],
  authorPayouts: [],
  totalPayoutAmount: {},
  requestAuthors: [],
  authorInformation: null,
  authorLoading: false,
  authorFilterLoading: false,
  authorPages: 0,
  requestAuthorPages: 0,
  numberOfAuthors: 0,
  numberOfRequestAuthors: 0,

  authorListSize: [
    {
      id: uuid(),
      name: 25,
      unavailable: false,
    },
    {
      id: uuid(),
      name: 50,
      unavailable: false,
    },
    {
      id: uuid(),
      name: 100,
      unavailable: false,
    },
  ],
};

const getters = {
  getNumberOfAuthors: (state) => state.numberOfAuthors,
  getTotalPayoutAmount: (state) => state.totalPayoutAmount,
  getNumberOfRequestAuthors: (state) => state.numberOfRequestAuthors,
  getAuthorLoading: (state) => state.authorLoading,
  getAuthorFilterLoading: (state) => state.authorFilterLoading,
  getAuthors: (state) => state.authors,
  getAuthorPayouts: (state) => state.authorPayouts,
  getRequestAuthors: (state) => state.requestAuthors,
  getAuthorInformation: (state) => state.authorInformation,
  getAuthorPages: (state) => state.authorPages,
  getRequestAuthorPages: (state) => state.requestAuthorPages,
  getAuthorListSize: (state) => state.authorListSize,
};

const actions = {
  async markAsPaid({ commit, state }, id) {
    const payout = state.authorPayouts.map((pay) => {
      if (pay.id === id) {
        pay.isPaid = true;
        return pay;
      }
      return pay;
    });
    commit("setAuthorPayouts", payout);
    try {
      await axios.patch(
        `${process.env.VUE_APP_BASE_URl}/author/update-earnings`,
        {
          id,
        }
      );
    } catch (error) {
      console.log({ error });
    }
  },
  async fetchUserDownloads({ commit }, params) {
    try {
      commit("setAuthorLoading", true);
      const { data } = await axios.get(
        `${process.env.VUE_APP_BASE_URl}/author/downloads/${params.size}/${params.page}?search=${params.query}&month=${params.month}&year=${params.year}&status=${params.status}`
      );
      commit("setAuthorPayouts", data.data.rows);
      commit("setAuthorPages", data.pages);
      commit("setAuthorLoading", false);
    } catch (error) {
      commit("setAuthorLoading", false);
    }
  },

  async fetchAuthorEarnings({ commit }, params) {
    try {
      commit("setAuthorLoading", true);
      const { data } = await axios.get(
        `${process.env.VUE_APP_BASE_URl}/author/earnings/${params.size}/${params.page}?search=${params.query}&month=${params.month}&year=${params.year}&status=${params.status}`
      );
      commit("setTotalPayoutAmount", data.totals);
      commit("setAuthorPayouts", data.data.rows);
      commit("setAuthorPages", data.pages);
      commit("setAuthorLoading", false);
    } catch (error) {
      commit("setAuthorLoading", false);
    }
  },

  async fetchAuthorPayouts({ commit }, params) {
    try {
      commit("setAuthorLoading", true);
      const { data } = await axios.get(
        `${process.env.VUE_APP_BASE_URl}/author/payouts/${params.size}/${params.page}?search=${params.query}&month=${params.month}&year=${params.year}&status=${params.status}`
      );
      commit("setTotalPayoutAmount", data.totals);
      commit("setAuthorPayouts", data.data.rows);
      commit("setAuthorPages", data.pages);
      commit("setAuthorLoading", false);
    } catch (error) {
      commit("setAuthorLoading", false);
    }
  },

  async approveSingleAuthor({ commit, state }, id) {
    console.log(id);
    try {
      commit("setAuthorLoading", true);
      const {
        data,
      } = await axios.patch(
        `${process.env.VUE_APP_BASE_URl}/author/approve-author`,
        { id }
      );

      const updatedData = state.requestAuthors.filter((a) => a.id !== id);

      console.log({ updatedData });

      commit("setAllRequestAuthors", updatedData);
      commit("setNumberOfRequestAuthors", state.numberOfRequestAuthors - 1);
      commit("setAuthorLoading", false);
    } catch (error) {
      commit("setAuthorLoading", false);
      console.log(error);
    }
  },

  async rejectSingleAuthor({ commit, state }, id) {
    try {
      commit("setDeleteModalLoading", true);
      commit("setAuthorLoading", true);
      const { data } = await axios.patch(
        `${process.env.VUE_APP_BASE_URl}/author/reject-author`,
        {
          id,
        }
      );

      const updatedData = state.requestAuthors.filter((a) => a.id !== id);

      commit("setDeleteModalLoading", false);
      commit("setDeleteModalState", false);
      commit("setAllRequestAuthors", updatedData);
      commit("setNumberOfRequestAuthors", state.numberOfRequestAuthors - 1);
      commit("setAuthorLoading", false);
    } catch (error) {
      commit("setAuthorLoading", false);
      console.log(error);
    }
  },

  async changeAuthorType({ commit, state }, type) {
    commit("setAuthorLoading", true);
    try {
      state.requestAuthors.forEach(async (Author) => {
        if (Author.selected) {
          if (type === "approve") {
            const {} = await axios.patch(
              `${process.env.VUE_APP_BASE_URl}/author/approve-author`,
              {
                id: Author.id,
              }
            );
            const updatedData = state.requestAuthors.filter(
              (a) => a.id !== Author.id
            );

            commit("setAllRequestAuthors", updatedData);
            commit(
              "setNumberOfRequestAuthors",
              state.numberOfRequestAuthors - 1
            );
          } else {
            await axios.patch(
              `${process.env.VUE_APP_BASE_URl}/author/reject-author`,
              {
                id: Author.id,
              }
            );
            const updatedData = state.requestAuthors.filter(
              (a) => a.id !== Author.id
            );

            commit("setAllRequestAuthors", updatedData);
            commit(
              "setNumberOfRequestAuthors",
              state.numberOfRequestAuthors - 1
            );
          }
          commit("setAuthorLoading", false);
        }
      });
    } catch (error) {
      commit("setAuthorLoading", false);
      console.log(error);
    }
  },

  async getAllAuthors({ commit }, params) {
    try {
      commit("setAuthorLoading", true);
      const { data } = await axios.get(
        `${process.env.VUE_APP_BASE_URl}/author/authors/${params.size}/${params.page}`
      );
      commit("setAllAuthors", data.rows);
      commit("setNumberOfAuthors", data.count);
      commit("setAuthorPages", data.pages);
      commit("setAuthorLoading", false);
    } catch (error) {
      commit("setAuthorLoading", false);
      console.log(error);
    }
  },

  async getAllSubscribers({ commit }, params) {
    try {
      commit("setAuthorLoading", true);
      const { data } = await axios.get(
        `${process.env.VUE_APP_BASE_URl}/subscriber/subscriber/${params.size}/${params.page}`
      );
      commit("setAllAuthors", data.rows);
      commit("setNumberOfAuthors", data.count);
      commit("setAuthorPages", data.pages);
      commit("setAuthorLoading", false);
    } catch (error) {
      commit("setAuthorLoading", false);
      console.log(error);
    }
  },

  async getAllRequestAuthors({ commit }, params) {
    try {
      commit("setAuthorLoading", true);
      const { data } = await axios.get(
        `${process.env.VUE_APP_BASE_URl}/author/request-authors/${params.size}/${params.page}`
      );
      console.log("request author", data.rows);
      commit("setAllRequestAuthors", data.rows);
      commit("setNumberOfRequestAuthors", data.count);
      commit("setRequestAuthorPages", data.pages);
      commit("setAuthorLoading", false);
    } catch (error) {
      commit("setAuthorLoading", false);
      console.log(error);
    }
  },

  /* get author info by id */
  async getAuthorInformationById({ commit }, id) {
    try {
      commit("setAuthorLoading", true);
      const { data } = await axios.post(
        `${process.env.VUE_APP_BASE_URl}/author/getauthor`,
        {
          id,
        }
      );

      console.log(data);
      commit("setAuthorLoading", false);
      commit("setAuthorInformation", data);
    } catch (error) {
      commit("setAuthorLoading", false);
      console.log(error);
    }
  },

  /* filter authors according to status */
  async filterAuthorStatus({ commit }, params) {
    if (params.type !== "none") {
      try {
        commit("setAuthorFilterLoading", true);
        const {
          data,
        } = await axios.post(
          `${process.env.VUE_APP_BASE_URl}/author/searchfilter/${params.size}/${params.page}`,
          { type: params.type }
        );

        commit("setAllAuthors", data.data.rows);
        commit("setAuthorPages", data.pages);
        commit("setAuthorFilterLoading", false);
      } catch (error) {
        commit("setAuthorFilterLoading", false);
        console.log(error);
      }
    }
  },

  async filterSubscriberStatus({ commit }, params) {
    if (params.type !== "none") {
      try {
        commit("setAuthorFilterLoading", true);
        const {
          data,
        } = await axios.post(
          `${process.env.VUE_APP_BASE_URl}/subscriber/searchfilter/${params.size}/${params.page}`,
          { type: params.type }
        );

        commit("setAllAuthors", data.data.rows);
        commit("setAuthorPages", data.pages);
        commit("setNumberOfAuthors", data.data.count);
        commit("setAuthorFilterLoading", false);
      } catch (error) {
        commit("setAuthorFilterLoading", false);
        console.log(error);
      }
    }
  },

  /* filter author according to author name */
  async filterAuthor({ commit }, params) {
    if (params.query.length > 0) {
      try {
        commit("setAuthorFilterLoading", true);
        const {
          data,
        } = await axios.post(
          `${process.env.VUE_APP_BASE_URl}/author/filter/${params.size}/${params.page}`,
          { query: params.query }
        );
        commit("setAllAuthors", data.data.rows);
        commit("setAuthorPages", data.pages);
        commit("setAuthorFilterLoading", false);
      } catch (error) {
        commit("setAuthorFilterLoading", false);
        console.log(error);
      }
    }
  },

  async filterSubscriber({ commit }, params) {
    if (params.query.length > 0) {
      try {
        commit("setAuthorFilterLoading", true);
        const {
          data,
        } = await axios.post(
          `${process.env.VUE_APP_BASE_URl}/subscriber/filter/${params.size}/${params.page}`,
          { query: params.query }
        );

        commit("setAllAuthors", data.data.rows);
        commit("setAuthorPages", data.pages);
        commit("setNumberOfAuthors", data.data.count);
        commit("setAuthorFilterLoading", false);
      } catch (error) {
        commit("setAuthorFilterLoading", false);
        console.log(error);
      }
    }
  },

  /* filter author according to request author name */
  async filterRequestAuthor({ commit }, params) {
    if (params.query.length > 0) {
      try {
        commit("setAuthorFilterLoading", true);
        const {
          data,
        } = await axios.post(
          `${process.env.VUE_APP_BASE_URl}/author/request-filter/${params.size}/${params.page}`,
          { query: params.query }
        );
        commit("setAllRequestAuthors", data.data.rows);
        commit("setRequestAuthorPages", data.pages);
        commit("setAuthorFilterLoading", false);
      } catch (error) {
        commit("setAuthorFilterLoading", false);
        console.log(error);
      }
    }
  },

  // select individual Author checkbox

  selectIndividualAuthor({ commit }, id) {
    commit("toggleAuthorSelected", id);
  },

  selectAllAuthor({ commit }, val) {
    commit("toggleAllRequestAuthorSelected", val);
  },

  selectIndividualRequestAuthor({ commit }, id) {
    commit("toggleRequestAuthorSelected", id);
  },

  selectAllRequestAuthor({ commit }, val) {
    commit("toggleAllRequestAuthorSelected", val);
  },

  async changeSelectedAuthorStatus({ commit, state }, type) {
    commit("setAuthorLoading", true);
    try {
      state.authors.forEach(async (Author) => {
        if (Author.selected) {
          await axios.post(`${process.env.VUE_APP_BASE_URl}/author/status`, {
            type,
            id: Author.id,
          });
          commit("setAuthorLoading", false);
        }
      });

      commit("setSelectedAuthorStatus", type);
    } catch (error) {
      commit("setAuthorLoading", false);
      console.log(error);
    }
  },

  async changeSelectedSubscriberStatus({ commit, state }, type) {
    commit("setAuthorLoading", true);
    try {
      state.authors.forEach(async (Author) => {
        if (Author.selected) {
          await axios.post(
            `${process.env.VUE_APP_BASE_URl}/subscriber/status`,
            {
              type,
              id: Author.id,
            }
          );
          commit("setAuthorLoading", false);
        }
      });

      commit("setSelectedAuthorStatus", type);
    } catch (error) {
      commit("setAuthorLoading", false);
      console.log(error);
    }
  },
};

const mutations = {
  setTotalPayoutAmount: (state, val) => (state.totalPayoutAmount = val),
  setAuthorPayouts: (state, val) => (state.authorPayouts = val),
  setAuthorLoading: (state, val) => (state.authorLoading = val),
  setAuthorFilterLoading: (state, val) => (state.authorFilterLoading = val),
  setNumberOfAuthors: (state, val) => (state.numberOfAuthors = val),
  setNumberOfRequestAuthors: (state, val) =>
    (state.numberOfRequestAuthors = val),
  setAllAuthors: (state, val) => (state.authors = val),
  setAllRequestAuthors: (state, val) => (state.requestAuthors = val),
  setAuthorPages: (state, val) => (state.authorPages = val),
  setRequestAuthorPages: (state, val) => (state.requestAuthorPages = val),
  setAuthorInformation: (state, val) => (state.authorInformation = val),
  setSelectedAuthorStatus: (state, type) =>
    (state.authors = state.authors.map((Author) => {
      if (Author.selected) {
        Author.status = type;
        return Author;
      }
      return Author;
    })),

  toggleAuthorSelected: (state, val) =>
    (state.authors = state.authors.map((Author) => {
      if (Author.id === val) {
        Author.selected = !Author.selected;
        return Author;
      }
      return Author;
    })),

  toggleAllAuthorSelected: (state, val) =>
    (state.requestAuthors = state.requestAuthors.map((Author) => {
      Author.selected = val;
      return Author;
    })),

  setSelectedRequestAuthorStatus: (state, type) =>
    (state.requestAuthors = state.requestAuthors.map((Author) => {
      if (Author.selected) {
        Author.status = type;
        return Author;
      }
      return Author;
    })),

  toggleRequestAuthorSelected: (state, val) =>
    (state.requestAuthors = state.requestAuthors.map((Author) => {
      if (Author.id === val) {
        Author.selected = !Author.selected;
        return Author;
      }
      return Author;
    })),

  toggleAllRequestAuthorSelected: (state, val) =>
    (state.requestAuthors = state.requestAuthors.map((Author) => {
      Author.selected = val;
      return Author;
    })),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
