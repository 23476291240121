import axios from "axios"
import { v4 as uuid } from "uuid"
import { storage } from "../../firebase"

const state = {
	staffLoading: false,
	staffFilterLoading: false,

	staffs: [],

	staffPages: 0,

	staffListSize: [
		{
			id: uuid(),
			name: 25,
			unavailable: false
		},
		{
			id: uuid(),
			name: 50,
			unavailable: false
		},
		{
			id: uuid(),
			name: 100,
			unavailable: false
		}
	],

	positions: [
		{
			id: uuid(),
			name: "Marketting Head",
			unavailable: false
		},
		{
			id: uuid(),
			name: "Developer Head",
			unavailable: false
		}
	],

	countries: [
		{
			id: uuid(),
			name: "Sri Lanka",
			unavailable: false
		},
		{
			id: uuid(),
			name: "India",
			unavailable: false
		},
		{
			id: uuid(),
			name: "Japan",
			unavailable: false
		}
	],

	phoneCodes: [
		{
			id: uuid(),
			name: "Sri Lanka (+94)",
			value: "+94",
			unavailable: false
		},

		{
			id: uuid(),
			name: "India (+91)",
			value: "+91",
			unavailable: false
		},

		{
			id: uuid(),
			name: "japan (+111)",
			value: "+111",
			unavailable: false
		}
	],

	staffErrors: {
		email: null,
		profile: null,
		updateEmail: null
	},

	staffInformation: null
}

const getters = {
	getStaffErrors: (state) => state.staffErrors,
	getStaffLoading: (state) => state.staffLoading,
	getStaffFilterLoading: (state) => state.staffFilterLoading,
	getStaffs: (state) => state.staffs,
	getCountries: (state) => state.countries,
	getPhoneCodes: (state) => state.phoneCodes,
	getPositions: (state) => state.positions,
	getStaffListSize: (state) => state.staffListSize,
	getStaffPages: (state) => state.staffPages,
	getStaffInformation: (state) => state.staffInformation
}

const actions = {
	clearStaffErrors({ commit }) {
		commit("clearStaffErrors", { email: null, profile: null })
	},

	/* get staff info by id */
	async getStaffInformationById({ commit }, id) {
		try {
			commit("setStaffLoading", true)
			const { data } = await axios.get(
				`${process.env.VUE_APP_BASE_URl}/staff/staffbyid/${id}`
			)
			commit("setStaffLoading", false)
			commit("setStaffInformation", data)
		} catch (error) {
			commit("setStaffLoading", false)
			console.log(error)
		}
	},

	async updateEmail({ commit }, { id, email }) {
		try {
			await axios.post(`${process.env.VUE_APP_BASE_URl}/staff/updateemail`, {
				id,
				email
			})
		} catch (error) {
			commit("setStaffErrors", {
				updateEmail: error.response.data.msg,
				email: null,
				profile: null
			})
			console.log(error.response)
		}
	},

	async updateSalary({ commit }, { id, salary }) {
		try {
			await axios.post(`${process.env.VUE_APP_BASE_URl}/staff/updatesalary`, {
				id,
				salary
			})
		} catch (error) {
			console.log(error.response)
		}
	},

	async updatePhone({ commit }, { id, phone }) {
		try {
			await axios.post(`${process.env.VUE_APP_BASE_URl}/staff/updatephone`, {
				id,
				phone
			})
		} catch (error) {
			console.log(error.response)
		}
	},

	async updatePosition({ commit }, { id, position }) {
		try {
			await axios.post(`${process.env.VUE_APP_BASE_URl}/staff/updateposition`, {
				id,
				position
			})
		} catch (error) {
			console.log(error.response)
		}
	},

	async createStaff({ commit }, form) {
		try {
			commit("setStaffLoading", true)

			let staffDetail = form

			if (form.profile) {
				const storageRef = storage.ref(
					`staff-profile-pic/${uuid()}${form.profile.name}`
				)

				await storageRef.put(form.profile)

				const url = await storageRef.getDownloadURL()

				staffDetail = { ...form, profile: url }
			}

			const { data } = await axios.post(
				`${process.env.VUE_APP_BASE_URl}/staff/create`,
				staffDetail
			)

			const modifiedData = { ...data, selected: false }

			commit("setStaff", modifiedData)
			commit("setAddStaffModalState", false)
			commit("setStaffLoading", false)
			commit("setAlert", {
				id: Math.floor(Math.random() * 10000),
				title: "New Staff Member Added",
				sub: `Exvaxo welcomes our new member ${data.firstName}`,
				icon: "heroicons-solid:check-circle",
				type: "success"
			})
		} catch (error) {
			commit("setStaffLoading", false)
			const err = error.response.data.errors && error.response.data.errors[0]
			if (err && err.param === "profile") {
				commit("setStaffErrors", { email: null, profile: err.msg })
			}

			if (error.response.data.code === "auth/email-already-in-use") {
				commit("setStaffErrors", { email: error.response.data.msg, profile: null })
			}
		}
	},

	/* get all staff with pagination */
	async getAllStaff({ commit }, params) {
		try {
			commit("setStaffLoading", true)
			const { data } = await axios.get(
				`${process.env.VUE_APP_BASE_URl}/staff/staffs/${params.size}/${params.page}`
			)

			const modifiedData = data.rows.map((data) => {
				return { ...data, selected: false }
			})

			commit("setAllStaff", modifiedData)
			commit("setStaffPages", data.pages)
			commit("setStaffLoading", false)
		} catch (error) {
			commit("setStaffLoading", false)
			console.log(error)
		}
	},

	/* filter staff according to email */
	async filterStaff({ commit }, params) {
		if (params.query.length > 0) {
			try {
				commit("setStaffFilterLoading", true)
				const {
					data
				} = await axios.post(
					`${process.env.VUE_APP_BASE_URl}/staff/filter/${params.size}/${params.page}`,
					{ query: params.query }
				)

				const modifiedData = data.data.rows.map((data) => {
					return { ...data, selected: false }
				})

				commit("setAllStaff", modifiedData)
				commit("setStaffPages", data.pages)
				commit("setStaffFilterLoading", false)
			} catch (error) {
				commit("setStaffFilterLoading", false)
				console.log(error)
			}
		}
	},

	/* filter staff according to status */
	async filterStaffStatus({ commit }, params) {
		if (params.type !== "none") {
			try {
				commit("setStaffFilterLoading", true)
				const {
					data
				} = await axios.post(
					`${process.env.VUE_APP_BASE_URl}/staff/searchfilter/${params.size}/${params.page}`,
					{ type: params.type }
				)

				const modifiedData = data.data.rows.map((data) => {
					return { ...data, selected: false }
				})

				commit("setAllStaff", modifiedData)
				commit("setStaffPages", data.pages)
				commit("setStaffFilterLoading", false)
			} catch (error) {
				commit("setStaffFilterLoading", false)
				console.log(error)
			}
		}
	},

	// select individual staff checkbox

	selectIndividualStaff({ commit }, id) {
		commit("toggleStaffSelected", id)
	},

	selectAllStaff({ commit }, val) {
		commit("toggleAllStaffSelected", val)
	},

	async changeSelectedStaffStatus({ commit, state }, type) {
		commit("setStaffLoading", true)
		try {
			state.staffs.forEach(async (staff) => {
				if (staff.selected) {
					await axios.post(`${process.env.VUE_APP_BASE_URl}/staff/status`, {
						type,
						id: staff.id
					})
					commit("setStaffLoading", false)
				}
			})

			commit("setSelectedStaffStatus", type)
		} catch (error) {
			commit("setStaffLoading", false)
			console.log(error)
		}
	}
}

const mutations = {
	setStaffErrors: (state, val) => (state.staffErrors = val),
	clearStaffErrors: (state, val) => (state.staffErrors = val),
	setStaffLoading: (state, val) => (state.staffLoading = val),
	setStaffFilterLoading: (state, val) => (state.staffFilterLoading = val),
	setStaff: (state, val) => state.staffs.push(val),
	setAllStaff: (state, val) => (state.staffs = val),
	setStaffInformation: (state, val) => (state.staffInformation = val),
	setSelectedStaffStatus: (state, type) =>
		(state.staffs = state.staffs.map((staff) => {
			if (staff.selected) {
				staff.status = type
				return staff
			}
			return staff
		})),

	setStaffPages: (state, val) => (state.staffPages = val),
	toggleStaffSelected: (state, val) =>
		(state.staffs = state.staffs.map((staff) => {
			if (staff.id === val) {
				staff.selected = !staff.selected
				return staff
			}
			return staff
		})),
	toggleAllStaffSelected: (state, val) =>
		(state.staffs = state.staffs.map((staff) => {
			staff.selected = val
			return staff
		}))
}

export default {
	state,
	getters,
	actions,
	mutations
}
