import { v4 as uuid } from "uuid";
import axios from "axios";
import moment from "moment";

const state = {
  informationForCheck: null,

  authorQuery: "",

  publishedProducts: [],
  underReviewProducts: [],

  publishedProductsLoading: false,
  underReviewProductsLoading: false,

  publishedProductsFilterLoading: false,
  underReviewProductsFilterLoading: false,

  publishedProductPages: 0,
  underReviewProductPages: 0,

  numberOfPublishedProducts: 0,
  numberOfUnderReviewProducts: 0,

  deleteModalState: false,
  deleteModalLoading: false,
};

const getters = {
  getInformationForCheck: (state) => state.informationForCheck,

  getAuthorQuery: (state) => state.authorQuery,

  getDeleteModalState: (state) => state.deleteModalState,
  getDeleteModalLoading: (state) => state.deleteModalLoading,

  getPublishedProducts: (state) => state.publishedProducts,
  getUnderReviewProducts: (state) => state.underReviewProducts,

  getPublishedProductsLoading: (state) => state.publishedProductsLoading,
  getUnderReviewProductsLoading: (state) => state.underReviewProductsLoading,

  getPublishedProductsFilterLoading: (state) =>
    state.publishedProductsFilterLoading,
  getUnderReviewProductsFilterLoading: (state) =>
    state.underReviewProductsFilterLoading,

  getNumberOfPublishedProducts: (state) => state.numberOfPublishedProducts,
  getNumberOfUnderReviewProducts: (state) => state.numberOfUnderReviewProducts,

  getPublishedProductPages: (state) => state.publishedProductPages,
  getUnderReviewProductPages: (state) => state.underReviewProductPages,
};

const actions = {
  async downloadPackage({ commit }, id) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_BASE_URl}/product/download-package-admin/${id}`
      );

      const hiddenElement = document.createElement("a");

      hiddenElement.href = data;
      hiddenElement.target = "_self";
      hiddenElement.click();
    } catch (error) {
      console.log(error);
    }
  },

  async downloadLicense({ commit }, params) {
    try {
      const { data } = await axios.post(
        `${process.env.VUE_APP_BASE_URl}/license/get-license-admin`,
        params
      );

      console.log({ data });

      const date = data.licenses.createdAt.split("T")[0];
      const year = date.split("-")[0];
      const month = moment(date.split("-")[1]).format("MMMM");

      const day = `${moment(date.split("-")[2], "DD").format("Do")}`;

      const template = `
LICENSE CERTIFICATE: Motion Ridge Item
=================================================
This license certificate documents a license to use the item listed below
on a non-exclusive, commercial, worldwide and revokable basis, for
one Single Use for this Registered Project.

Item Title:                      ${data.contributor.productName}
Item URL:                        https://motionridge.com/item/${data.contributor.productName
        .toLowerCase()
        .split(" ")
        .join("-")}/${params.productId}
Item ID:                         ${params.productId}
Author Username:                 ${data.contributor.user.userName}
Licensee:                        ${params.firstName} ${params.lastName}
Registered Project Name:         ${data.licenses.name}
License Date:                    ${month} ${day}, ${year}
Item License Code:               ${data.licenses.id}

The license you hold for this item is only valid if you complete your End
Product while your subscription is active. Then the license continues
for the life of the End Product (even if your subscription ends).

For any queries related to this document or license please contact
Exvaxo Support via https://help.motionridge.exvaxo.com/hc/en-us/requests/new

Exvaxo Pvt Ltd
==== THIS IS NOT A TAX RECEIPT OR INVOICE ====`;

      const hiddenElement = document.createElement("a");

      hiddenElement.href = "data:attachment/text," + encodeURI(template);
      hiddenElement.target = "_blank";
      hiddenElement.download = `license_certificate-${data.licenses.id}.txt`;
      hiddenElement.click();
    } catch (error) {
      console.log(error);
    }
  },

  setDeleteModalState({ commit }, val) {
    commit("setDeleteModalState", val);
  },

  setAuthorQuery({ commit }, query) {
    commit("setAuthorQuery", query);
  },

  async getDetailForCheck({ commit }, id) {
    try {
      commit("setPublishedProductsLoading", true);

      const { data } = await axios.get(
        `${process.env.VUE_APP_BASE_URl}/product/get-initial-product-admin/${id}`
      );

      commit("setInformationForCheck", data);
      commit("setPublishedProductsLoading", false);
    } catch (error) {
      commit("setPublishedProductsLoading", false);
      console.log(error);
    }
  },

  async fetchPublishedProducts({ commit }, params) {
    try {
      commit("setPublishedProductsLoading", true);
      const {
        data,
      } = await axios.get(
        `${process.env.VUE_APP_BASE_URl}/product/admin-published-products/${params.size}/${params.page}`,
        { type: params.type }
      );

      commit("setPublishedProducts", data.rows);
      commit("setPublishedProductPages", data.pages);
      commit("setNumberOfPublishedProducts", data.count);
      commit("setPublishedProductsLoading", false);
    } catch (error) {
      commit("setPublishedProductsLoading", false);
      console.log(error);
    }
  },

  async fetchUnderReviewProducts({ commit }, params) {
    try {
      commit("setUnderReviewProductsLoading", true);
      const { data } = await axios.get(
        `${process.env.VUE_APP_BASE_URl}/product/admin-under-review-products/${
          params.size
        }/${params.page}/${params.contributorType && params.contributorType}`,
        { type: params.type }
      );
      commit("setUnderReviewProducts", data.rows);
      commit("setUnderReviewProductPages", data.pages);
      commit("setNumberOfUnderReviewProducts", data.count);
      commit("setUnderReviewProductsLoading", false);
    } catch (error) {
      commit("setUnderReviewProductsLoading", false);
      console.log(error);
    }
  },

  /* filter product according to product name, id , category */
  async filterPublishedProduct({ commit }, params) {
    if (params.query.length > 0) {
      try {
        commit("setPublishedProductsFilterLoading", true);
        const {
          data,
        } = await axios.post(
          `${process.env.VUE_APP_BASE_URl}/product/published-product-filter/${params.size}/${params.page}`,
          { query: params.query }
        );

        console.log(data);
        commit("setPublishedProducts", data.data.rows);
        commit("setPublishedProductPages", data.pages);
        commit("setNumberOfPublishedProducts", data.data.count);
        commit("setPublishedProductsFilterLoading", false);
      } catch (error) {
        commit("setPublishedProductsFilterLoading", false);
        console.log(error);
      }
    }
  },

  async filterUnderReviewProduct({ commit }, params) {
    if (params.query.length > 0) {
      try {
        commit("setUnderReviewProductsFilterLoading", true);
        const {
          data,
        } = await axios.post(
          `${process.env.VUE_APP_BASE_URl}/product/under-review-product-filter/${params.size}/${params.page}`,
          { query: params.query }
        );

        commit("setUnderReviewProducts", data.data.rows);
        commit("setUnderReviewProductPages", data.pages);
        commit("setNumberOfUnderReviewProducts", data.data.count);
        commit("setUnderReviewProductsFilterLoading", false);
      } catch (error) {
        commit("setUnderReviewProductsFilterLoading", false);
        console.log(error);
      }
    }
  },

  /* filter product according to author name  */
  async filterPublishedProductByAuthor({ commit }, params) {
    if (params.query.length > 0) {
      try {
        commit("setPublishedProductsFilterLoading", true);
        const {
          data,
        } = await axios.post(
          `${process.env.VUE_APP_BASE_URl}/product/filter-published-product-author/${params.size}/${params.page}`,
          { query: params.query }
        );
        commit("setPublishedProducts", data.data.rows);
        commit("setPublishedProductPages", data.pages);
        commit("setNumberOfPublishedProducts", data.data.count);
        commit("setPublishedProductsFilterLoading", false);
      } catch (error) {
        commit("setPublishedProductsFilterLoading", false);
        console.log(error);
      }
    }
  },

  async filterUnderReviewProductByAuthor({ commit }, params) {
    if (params.query.length > 0) {
      try {
        commit("setUnderReviewProductsFilterLoading", true);
        const {
          data,
        } = await axios.post(
          `${process.env.VUE_APP_BASE_URl}/product/filter-under-review-product-author/${params.size}/${params.page}`,
          { query: params.query }
        );
        commit("setUnderReviewProducts", data.data.rows);
        commit("setUnderReviewProductPages", data.pages);
        commit("setNumberOfUnderReviewProducts", data.data.count);
        commit("setUnderReviewProductsFilterLoading", false);
      } catch (error) {
        commit("setUnderReviewProductsFilterLoading", false);
        console.log(error);
      }
    }
  },

  /* filter products according to type (on hold) */
  async filterPublishedProductStatus({ commit }, params) {
    if (params.type !== "none") {
      try {
        commit("setPublishedProductsFilterLoading", true);
        const {
          data,
        } = await axios.post(
          `${process.env.VUE_APP_BASE_URl}/product/type-filter/${params.size}/${params.page}`,
          { type: params.type }
        );

        commit("setPublishedProducts", data.data.rows);
        commit("setPublishedProductPages", data.pages);
        commit("setNumberOfPublishedProducts", data.data.count);
        commit("setPublishedProductsFilterLoading", false);
      } catch (error) {
        commit("setPublishedProductsFilterLoading", false);
        console.log(error);
      }
    }
  },

  async filterUnderReviewProductStatus({ commit }, params) {
    if (params.type !== "none") {
      try {
        commit("setUnderReviewProductsFilterLoading", true);
        const {
          data,
        } = await axios.post(
          `${process.env.VUE_APP_BASE_URl}/product/type-filter/${params.size}/${params.page}`,
          { type: params.type }
        );

        commit("setUnderReviewProducts", data.data.rows);
        commit("setUnderReviewProductPages", data.pages);
        commit("setNumberOfUnderReviewProducts", data.data.count);
        commit("setUnderReviewProductsFilterLoading", false);
      } catch (error) {
        commit("setUnderReviewProductsFilterLoading", false);
        console.log(error);
      }
    }
  },

  // select individual PublishedProduct checkbox
  selectIndividualPublishedProduct({ commit }, id) {
    commit("togglePublishedProductSelected", id);
  },

  selectIndividualUnderReviewProduct({ commit }, id) {
    commit("toggleUnderReviewProductSelected", id);
  },

  selectAllPublishedProduct({ commit }, val) {
    commit("toggleAllPublishedProductSelected", val);
  },

  selectAllUnderReviewProduct({ commit }, val) {
    commit("toggleAllUnderReviewProductSelected", val);
  },

  async changeSelectedPublishedProductType({ commit, state }, type) {
    commit("setPublishedProductsLoading", true);
    try {
      state.publishedProducts.forEach(async (publishedProduct) => {
        if (publishedProduct.selected) {
          await axios.patch(
            `${process.env.VUE_APP_BASE_URl}/product/published-products-type`,
            {
              id: publishedProduct.id,
              type,
            }
          );

          const {
            data,
          } = await axios.get(
            `${process.env.VUE_APP_BASE_URl}/product/admin-published-products/25/0`,
            { type: type }
          );

          commit("setPublishedProducts", data.rows);
          commit("setPublishedProductPages", data.pages);
          commit("setNumberOfPublishedProducts", data.count);
        }
      });

      commit("setPublishedProductsLoading", false);
    } catch (error) {
      commit("setPublishedProductsLoading", false);
      console.log(error);
    }
  },

  async changeUnderReviewProductType({ commit, state }, params) {
    commit("setPublishedProductsLoading", true);
    try {
      await axios.patch(
        `${process.env.VUE_APP_BASE_URl}/product/under-review-products-type`,
        {
          id: params.id,
          type: params.type,
          additional: params.additional,
        }
      );

      params.router.push("/motionridge/items-request");

      commit("setPublishedProductsLoading", false);
    } catch (error) {
      commit("setPublishedProductsLoading", false);
      console.log(error);
    }
  },

  async changeSelectedUnderReviewProductType({ commit, state }, type) {
    commit("setUnderReviewProductsLoading", true);
    try {
      state.underReviewProducts.forEach(async (UnderReviewProduct) => {
        if (UnderReviewProduct.selected) {
          await axios.patch(
            `${process.env.VUE_APP_BASE_URl}/product/under-review-products-type`,
            {
              id: UnderReviewProduct.id,
              type,
            }
          );

          const {
            data,
          } = await axios.get(
            `${process.env.VUE_APP_BASE_URl}/product/admin-under-review-products/25/0`,
            { type: type }
          );

          commit("setUnderReviewProducts", data.rows);
          commit("setUnderReviewProductPages", data.pages);
          commit("setNumberOfUnderReviewProducts", data.count);
        }
      });

      commit("setUnderReviewProductsLoading", false);
    } catch (error) {
      commit("setUnderReviewProductsLoading", false);
      console.log(error);
    }
  },

  async deletePublishedProduct({ commit }) {
    try {
      commit("setDeleteModalLoading", true);
      state.publishedProducts.forEach(async (publishedProduct) => {
        if (publishedProduct.selected) {
          await axios.delete(
            `${process.env.VUE_APP_BASE_URl}/product/delete-published-product/${publishedProduct.id}`
          );

          const {
            data,
          } = await axios.get(
            `${process.env.VUE_APP_BASE_URl}/product/admin-published-products/25/0`,
            { type: "published" }
          );

          commit("setPublishedProducts", data.rows);
          commit("setPublishedProductPages", data.pages);
          commit("setNumberOfPublishedProducts", data.count);
          commit("setDeleteModalLoading", false);
          commit("setDeleteModalState", false);
        }
      });
    } catch (error) {
      commit("setDeleteModalLoading", true);
      console.log(error);
    }
  },

  async deleteUnderReviewProduct({ commit }) {
    try {
      commit("setDeleteModalLoading", true);
      state.underReviewProducts.forEach(async (UnderReviewProduct) => {
        if (UnderReviewProduct.selected) {
          await axios.delete(
            `${process.env.VUE_APP_BASE_URl}/product/delete-published-product/${UnderReviewProduct.id}`
          );

          const {
            data,
          } = await axios.get(
            `${process.env.VUE_APP_BASE_URl}/product/admin-under-review-products/25/0`,
            { type: "under review" }
          );

          commit("setUnderReviewProducts", data.rows);
          commit("setUnderReviewProductPages", data.pages);
          commit("setNumberOfUnderReviewProducts", data.count);
          commit("setDeleteModalLoading", false);
          commit("setDeleteModalState", false);
        }
      });
    } catch (error) {
      commit("setDeleteModalLoading", true);
      console.log(error);
    }
  },
};

const mutations = {
  setAuthorQuery: (state, val) => (state.authorQuery = val),

  setInformationForCheck: (state, val) => (state.informationForCheck = val),

  setDeleteModalState: (state, val) => (state.deleteModalState = val),
  setDeleteModalLoading: (state, val) => (state.deleteModalLoading = val),

  setPublishedProductsLoading: (state, val) =>
    (state.publishedProductsLoading = val),
  setUnderReviewProductsLoading: (state, val) =>
    (state.underReviewProductsLoading = val),

  setPublishedProductsFilterLoading: (state, val) =>
    (state.publishedProductsFilterLoading = val),
  setUnderReviewProductsFilterLoading: (state, val) =>
    (state.underReviewProductsFilterLoading = val),

  setPublishedProducts: (state, val) => (state.publishedProducts = val),
  setUnderReviewProducts: (state, val) => (state.underReviewProducts = val),

  setNumberOfPublishedProducts: (state, val) =>
    (state.numberOfPublishedProducts = val),
  setNumberOfUnderReviewProducts: (state, val) =>
    (state.numberOfUnderReviewProducts = val),

  setPublishedProductPages: (state, val) => (state.publishedProductPages = val),
  setUnderReviewProductPages: (state, val) =>
    (state.underReviewProductPages = val),

  togglePublishedProductSelected: (state, val) =>
    (state.publishedProducts = state.publishedProducts.map(
      (publishedProduct) => {
        if (publishedProduct.id === val) {
          publishedProduct.selected = !publishedProduct.selected;
          return publishedProduct;
        }
        return publishedProduct;
      }
    )),
  toggleUnderReviewProductSelected: (state, val) =>
    (state.underReviewProducts = state.underReviewProducts.map(
      (UnderReviewProduct) => {
        if (UnderReviewProduct.id === val) {
          UnderReviewProduct.selected = !UnderReviewProduct.selected;
          return UnderReviewProduct;
        }
        return UnderReviewProduct;
      }
    )),

  toggleAllPublishedProductSelected: (state, val) =>
    (state.publishedProducts = state.publishedProducts.map(
      (publishedProduct) => {
        publishedProduct.selected = val;
        return publishedProduct;
      }
    )),
  toggleAllUnderReviewProductSelected: (state, val) =>
    (state.underReviewProducts = state.underReviewProducts.map(
      (UnderReviewProduct) => {
        UnderReviewProduct.selected = val;
        return UnderReviewProduct;
      }
    )),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
