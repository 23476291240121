<template>
  <router-link
    :to="url"
    class="-mx-3 min-w-min px-3 py-3 bg-white dark:bg-darkForeground flex font-medium text-sm rounded-lg focus:outline-none outline-none transition-all duration-300 hover:bg-indigo-50 dark:hover:bg-darkActive"
    :class="{
      'bg-indigo-50 dark:bg-darkActive dark:text-brandColor':
        $route.path === url,
      'items-center justify-start': !isExpanded,
      'justify-between items-center': isExpanded,
    }"
  >
    <transition-group
      enter-active-class="transition transform duration-300"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition transform duration-300"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <span
        key="1"
        class="inline-flex items-center flex-shrink-0 text-gray-500"
        :class="{ 'text-brandColor': $route.path === url }"
      >
        <span
          v-if="!isPersonal"
          class="iconify w-5 h-5"
          :data-icon="icon"
          data-inline="false"
        ></span>

        <span v-else class="">
          <svg fill="none" class="w-5 h-5" viewBox="0 0 68 68">
            <path
              fill="#596980"
              d="M55.8895 67.0276H11.1381c-2.95134-.0086-5.77935-1.1849-7.86628-3.2718C1.18489 61.6689.00863735 58.8409 0 55.8895V11.1381c.00863206-2.95131 1.18488-5.77933 3.27181-7.86627C5.35875 1.1849 8.18676.00864001 11.1381 0h44.7514c2.9514.00864001 5.7794 1.1849 7.8663 3.27183 2.0869 2.08694 3.2632 4.91496 3.2718 7.86627v44.7514c-.0086 2.9514-1.1849 5.7794-3.2718 7.8663s-4.9149 3.2632-7.8663 3.2718z"
            />
            <path
              fill="#fff"
              d="M60.4641 11.5359c0 1.0622-.315 2.1004-.905 2.9835-.5901.8832-1.4288 1.5715-2.4101 1.9779-.9813.4065-2.061.5128-3.1027.3056-1.0418-.2072-1.9986-.7186-2.7497-1.4697-.751-.751-1.2624-1.7079-1.4697-2.7496-.2072-1.0417-.1008-2.1215.3056-3.10273.4065-.98127 1.0948-1.81998 1.9779-2.41006.8831-.59008 1.9214-.90504 2.9835-.90504.7052-.00001 1.4036.13889 2.0551.40876.6516.26987 1.2436.66544 1.7422 1.1641.4987.49867.8943 1.09068 1.1641 1.74222.2699.65155.4088 1.34985.4088 2.05505"
            />
            <path
              stroke="#2A3742"
              stroke-miterlimit="10"
              stroke-width=".39779"
              d="M55.0939 16.9061c2.9659 0 5.3702-2.4043 5.3702-5.3702 0-2.96582-2.4043-5.37013-5.3702-5.37013-2.9658 0-5.3701 2.40431-5.3701 5.37013 0 2.9659 2.4043 5.3702 5.3701 5.3702z"
            />
            <path
              fill="#fff"
              d="M34.4729 63.6189c-.0439.0446-.0884.0884-.1332.1315-.8248.7775-1.8342 1.3317-2.9328 1.6104-1.0986.2786-2.2502.2723-3.3457-.0182-1.0956-.2905-2.0989-.8556-2.9152-1.642-.8162-.7863-1.4184-1.7679-1.7495-2.8519-.8996-2.907-2.3953-7.195-3.749-8.5346-1.3538-1.3395-5.6574-2.7898-8.5738-3.6588-1.08736-.3197-2.07523-.9115-2.87016-1.7194-.79492-.8079-1.37063-1.8052-1.67267-2.8976-.30204-1.0924-.32043-2.2438-.05343-3.3453.267-1.1015.81056-2.1167 1.57928-2.9495.04276-.0454.08612-.0903.13008-.1349 1.10948-1.1211 2.5541-1.85 4.1151-2.0765 1.5609-.2264 3.1531.0621 4.5354.8217 1.6182.9029 3.325 1.637 5.0935 2.1904 2.8703.8808 5.0842.6564 7.9178 3.4604 2.9958 2.9644 2.633 5.0201 3.5439 7.881.5721 1.7626 1.324 3.4615 2.244 5.07.7742 1.3742 1.0794 2.9633.8694 4.5266-.2099 1.5632-.9236 3.0155-2.033 4.1367zm-5.6118-53.5453c2.6163 1.46 5.376 2.6468 8.2354 3.5415 4.6409 1.4241 8.2202 1.0615 12.8019 5.5954 4.8437 4.7925 4.2572 8.1167 5.73 12.742.925 2.8498 2.1408 5.5968 3.6283 8.1976 1.2518 2.2219 1.7453 4.7913 1.4059 7.3189-.3395 2.5276-1.4934 4.8758-3.2871 6.6887-.0712.0718-.1431.1425-.2156.2122-1.3334 1.2571-2.9655 2.1533-4.7418 2.6038-1.7763.4505-3.6382.4404-5.4096-.0293-1.7713-.4696-3.3936-1.3834-4.7134-2.6549-1.3197-1.2714-2.2933-2.8585-2.8287-4.6111-1.4545-4.7003-3.8729-11.6334-6.0617-13.7992-2.1889-2.166-9.1472-4.5109-13.8626-5.9159-1.7582-.5169-3.3554-1.4737-4.6407-2.7799-1.2853-1.3063-2.2161-2.9188-2.7044-4.6851-.4884-1.7663-.5181-3.628-.0864-5.409.4318-1.781 1.3107-3.4224 2.5536-4.769.069-.0732.1391-.1457.2102-.2176v-.0002c1.7939-1.8127 4.1297-2.99134 6.6535-3.35745 2.5238-.36611 5.0982.10028 7.3332 1.32855"
            />
            <path
              stroke="#2A3742"
              stroke-miterlimit="10"
              stroke-width=".39779"
              d="M34.4729 63.6189c-.0439.0446-.0884.0884-.1332.1315-.8248.7775-1.8342 1.3317-2.9328 1.6103-1.0986.2786-2.2502.2724-3.3457-.0181-1.0956-.2905-2.0989-.8556-2.9152-1.642-.8162-.7864-1.4184-1.7679-1.7495-2.8519-.8996-2.907-2.3953-7.195-3.749-8.5346-1.3538-1.3396-5.6574-2.7899-8.5738-3.6589-1.08736-.3196-2.07523-.9114-2.87016-1.7193-.79492-.8079-1.37063-1.8052-1.67267-2.8976-.30204-1.0924-.32043-2.2438-.05343-3.3453.267-1.1015.81056-2.1167 1.57928-2.9496.04276-.0453.08612-.0902.13008-.1348 1.10948-1.1211 2.5541-1.8501 4.1151-2.0765 1.5609-.2264 3.1531.062 4.5354.8217 1.6182.9029 3.325 1.6369 5.0935 2.1904 2.8703.8807 5.0842.6564 7.9178 3.4604 2.9958 2.9643 2.633 5.0201 3.5439 7.881.5721 1.7625 1.324 3.4615 2.244 5.07.7742 1.3742 1.0794 2.9633.8695 4.5266-.21 1.5632-.9237 3.0155-2.0331 4.1367v0zm-5.6118-53.5453c2.6163 1.46 5.376 2.6467 8.2354 3.5415 4.6409 1.4241 8.2202 1.0615 12.8019 5.5953 4.8437 4.7926 4.2572 8.1167 5.73 12.742.925 2.8498 2.1408 5.5968 3.6283 8.1977 1.2518 2.2219 1.7453 4.7913 1.4059 7.3189-.3395 2.5276-1.4934 4.8757-3.2871 6.6887-.0712.0718-.1431.1425-.2156.2122-1.3334 1.2571-2.9655 2.1533-4.7418 2.6038-1.7763.4505-3.6382.4404-5.4096-.0293-1.7713-.4697-3.3936-1.3834-4.7134-2.6549-1.3197-1.2714-2.2933-2.8585-2.8287-4.6112-1.4545-4.7002-3.8729-11.6333-6.0617-13.7991-2.1889-2.166-9.1472-4.5109-13.8626-5.9159-1.7582-.5169-3.3554-1.4737-4.6407-2.78-1.2853-1.3062-2.2161-2.9188-2.7044-4.6851-.4884-1.7663-.5181-3.6279-.0864-5.4089.4318-1.781 1.3107-3.4224 2.5536-4.769.069-.0732.1391-.1457.2102-.2176v-.0002c1.7939-1.8127 4.1297-2.99135 6.6535-3.35745 2.5238-.36611 5.0982.10029 7.3332 1.32855z"
            />
            <path
              stroke="#2A3742"
              stroke-miterlimit="10"
              stroke-width=".39779"
              d="M18.6962 37.2966v9.9685c0 .7002.1808 1.3886.5248 1.9985.3441.6099.8397 1.1207 1.439 1.4829.5993.3622 1.2819.5636 1.9819.5846.6999.0211 1.3934-.1389 2.0134-.4644l7.9484-4.1737M37.5828 44.0788l11.8452-6.2196c.6567-.3448 1.2066-.8627 1.5902-1.4975.3837-.6348.5865-1.3624.5865-2.1041 0-.7417-.2028-1.4693-.5865-2.1041-.3836-.6348-.9335-1.1526-1.5902-1.4975L24.6553 17.6485c-.62-.3256-1.3135-.4856-2.0135-.4645-.6999.021-1.3825.2224-1.9818.5846-.5993.3622-1.095.873-1.439 1.4829-.3441.6099-.5248 1.2983-.5248 1.9985v8.423"
            />
          </svg>
        </span>

        <span
          key="2"
          v-if="isExpanded"
          class="ml-3 text-sm font-medium flex shrink-0"
          >{{ name }}</span
        >
      </span>
      <span
        key="3"
        v-if="value && isExpanded"
        class="text-xs font-semibold text-gray-600 bg-indigo-100 rounded-full w-9 text-center py-1 inline-block leading-none flex-shrink-0"
        >{{ value }}
      </span>
    </transition-group>
  </router-link>
</template>

<script>
export default {
  name: "Link",
  components: {},
  props: ["name", "value", "url", "icon", "isExpanded", "isPersonal"],
  emits: ["linkClicked"],
};
</script>
