<template>
	<span
		:class="{
			'text-green-600': type === 'Active',
			'text-yellow-600': type === 'Deactive' || type === 'Pending',
			'text-red-600': type === 'Blocked' || type === 'Suspended'
		}"
	>
		{{ type }}
	</span>
</template>

<script>
export default {
	name: "Badge",
	components: {},
	props: ["type"]
}
</script>
