<template>
	<aside
		class="block w-72 2xl:hidden min-h-screen fixed inset-y-0 right-0 z-50 bg-white  dark:bg-darkWhite  font-def shadow-md border-r border-gray-300  dark:border-gray-700 overflow-y-auto transition-all duration-500"
	>
		<nav class="">
			<!-- personal info -->
			<div
				class="px-6 py-10 flex flex-col items-center justify-center border-b border-gray-300 dark:border-gray-700"
			>
				<div class="w-24 h-24 overflow-hidden">
					<img
						class="w-full h-full object-cover flex-shrink-0 rounded-full"
						:src="staffInfo.profile"
						alt="staffs profile pic"
					/>
				</div>

				<!-- badge -->
				<div
					class="mt-4 px-2 py-1 border-gray-300 dark:border-gray-500 border rounded-full text-xxs text-gray-300 dark:text-gray-500 uppercase"
				>
					Staff
				</div>

				<div class="mt-4 flex items-center flex-col">
					<h1 class="text-xl font-bold text-gray-800 dark:text-gray-100">
						{{ staffInfo.firstName }}
					</h1>
					<h3 class="mt-2 text-xs text-gray-500 dark:text-gray-400">
						{{ staffInfo.email }}
					</h3>
				</div>
			</div>

			<!-- salary info -->
			<div class="px-6 py-6 border-b border-gray-300 dark:border-gray-700">
				<h1
					class="uppercase text-gray-600 dark:text-gray-400 text-xs font-bold tracking-wide"
				>
					Salary Paid
				</h1>

				<div class="mt-3 flex justify-between">
					<div class="flex flex-col">
						<h1 class=" text-xl text-gray-900 dark:text-gray-100">
							Rs. <span class="font-bold">85000</span>
						</h1>
						<h3 class="mt-1 text-xs text-gray-500 dark:text-gray-400">
							Total salary
						</h3>
					</div>

					<div class="flex flex-col">
						<h1 class=" text-xl text-gray-900 dark:text-gray-100">
							Rs. <span class="font-bold">25000</span>
						</h1>
						<h3 class="mt-1 text-xs text-gray-500 dark:text-gray-400">
							This month salary
						</h3>
					</div>
				</div>
			</div>

			<!-- additional info -->
			<div class="px-6 py-6 border-b border-gray-300 dark:border-gray-700">
				<h1
					class="uppercase text-gray-600 dark:text-gray-400 text-xs font-bold tracking-wide"
				>
					Additional Information
				</h1>

				<div class="mt-3 flex flex-col">
					<div class="space-y-1">
						<h3 class="text-sm text-gray-500 dark:text-gray-100">Position</h3>
						<h1 class="text-sm text-gray-700 dark:text-gray-400">
							{{ staffInfo.position }}
						</h1>
					</div>
				</div>

				<div class="mt-3 w-full grid grid-cols-2 space-x-10">
					<div class="space-y-1">
						<h3 class="text-sm text-gray-500 dark:text-gray-100">Registered At</h3>
						<h1 class="text-sm text-gray-700 dark:text-gray-400">
							{{ staffInfo.createdAt.split("T")[0] }}
						</h1>
					</div>

					<div class="space-y-1">
						<h3 class="text-sm text-gray-500 dark:text-gray-100">Last login</h3>
						<h1 class="text-sm text-gray-700 dark:text-gray-400">2021-02-6</h1>
					</div>
				</div>
			</div>
		</nav>
	</aside>
</template>

<script>
export default {
	name: "SideProfile",
	components: {},
	props: ["staffInfo"]
}
</script>
