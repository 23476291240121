<template>
  <div class="min-h-screen p-3 md:p-10 pb-44 overflow-hidden">
    <header class="w-full pt-2 flex flex-col">
      <div class="w-full flex items-center justify-between">
        <h1 class="text-xl xl:text-2xl ">
          <span class="text-gray-900 dark:text-gray-200 font-bold"
            >Check Product /
          </span>
          <span class="text-gray-600 dark:text-gray-500 text-lg md:text-xl"
            >{{ name }}
          </span>
        </h1>

        <!-- upload button -->
        <router-link
          to="/motionridge/items-request"
          class="focus:outline-none px-3 py-2 text-xs font-medium rounded-md bg-white dark:bg-darkWhite dark:text-gray-200 text-gray-900 hover:bg-gray-100 dark:hover:bg-darkActive border border-gray-300 dark:border-gray-700 flex items-center justify-center"
        >
          Return to main menu
        </router-link>
      </div>
    </header>

    <main
      class="relative mt-6 bg-white dark:bg-darkWhite flex flex-col rounded-md shadow p-6"
    >
      <Transition
        enter-active-class="transition duration-500 ease-in"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100 "
        leave-active-class="transition duration-500 ease-out"
        leave-from-class="opacity-100 "
        leave-to-class="opacity-0"
      >
        <div
          v-if="getPublishedProductsLoading"
          class="absolute rounded-md z-30 inset-0 bg-white dark:bg-darkBlack opacity-95 flex items-center justify-center"
        >
          <Loader :brand="true" />
        </div>
      </Transition>
      <div
        v-if="loaded"
        class=" w-full mt-10 flex flex-col lg:grid lg:grid-cols-2 gap-10 gap-y-16"
      >
        <div class="relative">
          <!-- audio -->
          <div
            :class="[
              category === 'Sound Effects' || category === 'Royalty Free Music'
                ? 'opacity-100'
                : 'opacity-0',
            ]"
            class="relative py-3 px-3 h-56 flex flex-col items-center justify-center rounded-md bg-gray-100 dark:bg-darkWhite shadow-lg"
          >
            <div class="relative w-full h-full">
              <div
                id="waveform"
                ref="waveform"
                class="absolute inset-0 z-10 w-full h-full"
              ></div>
            </div>

            <div
              v-if="
                category === 'Sound Effects' ||
                  category === 'Royalty Free Music'
              "
              class="m-5 absolute bottom-0 z-20 right-0 flex justify-end space-x-5"
            >
              <button
                v-if="!isAudioPlaying"
                class="rounded-full bg-gray-100 dark:bg-darkWhite text-gray-700 dark:text-gray-300 checked:hover:bg-gray-300 focus:outline-none shadow-sm hover:text-gray-900 dark:hover:text-gray-400 dark:hover:bg-darkActive transition-colors duration-300"
                @click="handleAudioStart"
              >
                <svg class="w-10 h-10" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <button
                v-if="isAudioPlaying"
                class="rounded-full bg-gray-100 dark:bg-darkWhite text-gray-700 dark:text-gray-300 checked:hover:bg-gray-300 focus:outline-none shadow-sm hover:text-gray-900 dark:hover:text-gray-400 dark:hover:bg-darkActive transition-colors duration-300"
                @click="handleAudioPause"
              >
                <svg class="w-10 h-10" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>

          <!-- video -->
          <div
            v-if="
              category !== 'Sound Effects' && category !== 'Royalty Free Music'
            "
            class="absolute top-0 inset-x-0"
          >
            <div class="relative w-full overflow-hidden rounded-lg">
              <button
                v-if="!isVideoPlaying"
                @click="handleVideoPlay"
                class="p-1 absolute bottom-0 m-5 z-20 rounded-full bg-gray-600 dark:bg-darkWhite text-gray-100  focus:outline-none shadow-sm hover:text-gray-200 dark:hover:text-gray-400  transition-colors duration-300"
              >
                <svg class="w-10 h-10" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <img
                v-if="!isVideoPlaying"
                class="absolute inset-0 z-10 object-cover w-full"
                :src="thumbnail.url"
                alt="thumbnail image"
              />
              <video
                ref="video"
                @ended="handleVideoEnded"
                class="w-full mx-auto max-w-md lg:max-w-none rounded-lg focus:outline-none"
                controls
              >
                <source :src="videoPreview.url" type="video/mp4" />
                Your browser does not support HTML video.
              </video>
            </div>
          </div>
        </div>

        <div class="flex flex-col items-start justify-start">
          <div
            class="flex w-full flex-col items-start justify-center space-y-5 bg-gray-200 dark:bg-darkBlack rounded-md p-3"
          >
            <div class=" w-full flex flex-row items-center justify-between ">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                description
              </h1>
              <div
                class="mt-3 lg:mt-0 flex items-center justify-center space-x-5"
              >
                <span
                  v-if="free"
                  class="px-3 py-1 rounded-full text-xxs lg:text-xs font-medium text-white bg-rose-600"
                  >Free
                </span>
                <span
                  v-if="looped"
                  class="px-3 py-1 rounded-full text-xxs lg:text-xs font-medium text-white bg-green-600"
                >
                  Looped Audio
                </span>
                <span
                  v-if="loopedVideo"
                  class="px-3 py-1 rounded-full text-xxs lg:text-xs font-medium text-white bg-green-600"
                >
                  Looped Video
                </span>
                <span
                  v-if="editorial"
                  class="px-3 py-1 rounded-full text-xxs lg:text-xs font-medium text-white bg-blue-600"
                >
                  Editorial
                </span>
              </div>
            </div>

            <h3 class="text-xs lg:text-sm text-gray-600 dark:text-gray-400">
              {{ description }}
            </h3>
          </div>

          <div
            class="mt-6 flex flex-col mr-auto w-full items-stretch space-y-6 p-3"
          >
            <div v-if="name" class="flex items-start justify-between">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                Product name
              </h1>
              <h3 class="text-sm text-gray-600 dark:text-gray-400">
                {{ name }}
              </h3>
            </div>

            <div v-if="category" class="flex items-start justify-between">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                category
              </h1>
              <h3 class="text-sm text-gray-600 dark:text-gray-400">
                {{ category }}
              </h3>
            </div>

            <div v-if="subCategory" class="flex items-start justify-between">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                Sub Category
              </h1>
              <h3 class="text-sm text-gray-600 dark:text-gray-400">
                {{ subCategory }}
              </h3>
            </div>

            <div v-if="version" class="flex items-start justify-between">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                version
              </h1>
              <h3 class="text-sm text-gray-600 dark:text-gray-400">
                {{ version }}
              </h3>
            </div>

            <div v-if="frameRate" class="flex items-start justify-between">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                frame Rate
              </h1>
              <h3 class="text-sm text-gray-600 dark:text-gray-400">
                {{ frameRate }}
              </h3>
            </div>

            <div v-if="resolution" class="flex items-start justify-between">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                resolution
              </h1>
              <h3 class="text-sm text-gray-600 dark:text-gray-400">
                {{ resolution }}
              </h3>
            </div>

            <div v-if="bitRate" class="flex items-start justify-between">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                bit rate
              </h1>
              <h3 class="text-sm text-gray-600 dark:text-gray-400">
                {{ bitRate }}
              </h3>
            </div>

            <div v-if="sampleRate" class="flex items-start justify-between">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                sample rate
              </h1>
              <h3 class="text-sm text-gray-600 dark:text-gray-400">
                {{ sampleRate }}
              </h3>
            </div>

            <div v-if="trackLength" class="flex items-start justify-between">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                Track Duration
              </h1>
              <h3 class="text-sm text-gray-600 dark:text-gray-400">
                {{ trackLength }}
              </h3>
            </div>

            <div v-if="compression" class="flex items-start justify-between">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                Compression
              </h1>
              <h3 class="text-sm text-gray-600 dark:text-gray-400">
                {{ compression }}
              </h3>
            </div>

            <div v-if="tempo" class="flex items-start justify-between">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                Tempo
              </h1>
              <h3 class="text-sm text-gray-600 dark:text-gray-400">
                {{ tempo }}
              </h3>
            </div>

            <div v-if="stockMusic" class="flex items-start justify-between">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                stock Music
              </h1>
              <h3
                class="flex justify-end flex-wrap text-sm text-gray-600 dark:text-gray-400"
              >
                {{ stockMusic }}
              </h3>
            </div>

            <div v-if="affiliation" class="flex items-start justify-between">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                P.R.O Affiliation
              </h1>
              <h3 class="text-sm text-gray-600 dark:text-gray-400">
                {{ affiliation }}
              </h3>
            </div>

            <div v-if="youtube" class="flex items-start justify-between">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                youtube content id
              </h1>
              <h3 class="text-sm text-gray-600 dark:text-gray-400">
                {{ youtube }}
              </h3>
            </div>

            <div v-if="publisher" class="flex items-start justify-between">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                Publisher name
              </h1>
              <h3 class="text-sm text-gray-600 dark:text-gray-400">
                {{ publisher }}
              </h3>
            </div>

            <div v-if="composerName" class="flex items-start justify-between">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                composer name
              </h1>
              <h3 class="text-sm text-gray-600 dark:text-gray-400">
                {{ composerName }}
              </h3>
            </div>

            <div v-if="tags" class="flex items-start justify-between">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                Tags
              </h1>
              <div class="flex flex-wrap gap-3 justify-end w-56">
                <h3
                  v-for="tag in tags.split(',')"
                  :key="tag"
                  class="py-1 px-2 bg-gray-200 dark:bg-darkForeground rounded-full text-xs text-gray-600 dark:text-gray-400 text-right"
                >
                  {{ tag }}
                </h3>
              </div>
            </div>

            <div v-if="plugin" class="flex items-start justify-between">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                plugins
              </h1>
              <div class="flex flex-wrap gap-3 justify-end w-56">
                <h3
                  v-for="plug in plugin.split(',')"
                  :key="plug"
                  class="py-1 px-2 bg-gray-200 dark:bg-darkForeground rounded-full text-xs text-gray-600 dark:text-gray-400 text-right"
                >
                  {{ plug }}
                </h3>
              </div>
            </div>

            <div v-if="vocal" class="flex items-start justify-between">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                vocals
              </h1>
              <div class="flex flex-wrap gap-3 justify-end w-56">
                <h3
                  v-for="vcl in vocal.split(',')"
                  :key="vcl"
                  class="py-1 px-2 bg-gray-200 dark:bg-darkForeground rounded-full text-xs text-gray-600 dark:text-gray-400 text-right"
                >
                  {{ vcl }}
                </h3>
              </div>
            </div>

            <div v-if="format" class="flex items-start justify-between">
              <h1
                class="uppercase text-sm text-gray-800 dark:text-gray-200 font-semibold"
              >
                Format
              </h1>
              <div class="flex flex-wrap gap-3 justify-end w-32">
                <h3
                  v-for="frmt in format.split(',')"
                  :key="frmt"
                  class="text-sm text-gray-600 dark:text-gray-400 text-right"
                >
                  {{ frmt }}
                </h3>
              </div>
            </div>

            <div
              class="mt-2 pb-6 pt-5 px-6 flex flex-col items-center justify-center bg-gray-200 dark:bg-darkActive border-2 border-gray-300 dark:border-gray-700 rounded-md"
            >
              <div
                class="w-full space-y-1 text-center flex flex-col items-center justify-center"
              >
                <span
                  class="iconify mx-auto h-12 w-12 text-gray-400"
                  data-icon="carbon:folder-add"
                  data-inline="false"
                ></span>

                <h1
                  class="w-full block text-center text-base md:text-lg  font-bold text-green-600 dark:text-green-500"
                >
                  {{ uploadPackage.name }}
                </h1>
              </div>
              <button
                @click="downloadPackage($route.params.id)"
                class="mt-5 focus:outline-none rounded-md px-3 py-2 text-center bg-gray-800 dark:bg-gray-700 text-white darl:text-gray-800 font-medium hover:bg-gray-700 dark:hover:bg-gray-300 text-xs transition duration-300"
              >
                Download Product
              </button>
              <h3 class="mt-2 text-sm text-gray-600 dark:text-gray-500">
                {{
                  getInformationForCheck &&
                    getInformationForCheck[0].packageSize
                }}
              </h3>
            </div>

            <div v-if="!isPublished" class="my-5">
              <textarea
                v-model="changesRequiredAdditional"
                class="w-full border border-gray-300 dark:border-gray-700 focus:outline-gray-800 px-3 py-2 rounded-md dark:focus:outline-gray-300 text-gray-800 dark:text-gray-300 bg-transparent"
                rows="4"
                placeholder="aditional information for changes required (use <br> for Enter)"
              />
            </div>

            <div
              v-if="!isPublished"
              class="flex items-center justify-end space-x-5"
            >
              <button
                @click="handleProductTypeChange('rejected')"
                class="focus:outline-none rounded-md px-3 py-2 text-center bg-red-600 text-white darl:text-gray-800 font-medium hover:bg-red-700 text-xs transition duration-300"
              >
                Reject Product
              </button>

              <button
                @click="handleProductTypeChange('changes required')"
                class="focus:outline-none rounded-md px-3 py-2 text-center bg-yellow-600 text-white darl:text-gray-800 font-medium hover:bg-yellow-700 text-xs transition duration-300"
              >
                Changes Required
              </button>

              <button
                @click="handleProductTypeChange('published')"
                class="focus:outline-none rounded-md px-3 py-2 text-center bg-green-600 text-white darl:text-gray-800 font-medium hover:bg-green-700 text-xs transition duration-300"
              >
                Approve Product
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- end of final submit form -->
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "../components/utils/Loader.vue";
import WaveSurfer from "wavesurfer.js";
export default {
  name: "Edit product",
  components: {
    Loader,
  },

  mounted() {
    this.getDetailForCheck(this.$route.params.id);
  },

  computed: {
    ...mapGetters(["getPublishedProductsLoading"]),
    ...mapGetters(["getInformationForCheck"]),
  },

  methods: {
    ...mapActions(["getDetailForCheck"]),
    ...mapActions(["changeUnderReviewProductType"]),
    ...mapActions(["downloadPackage"]),

    handleProductTypeChange(type) {
      this.changeUnderReviewProductType({
        type,
        id: this.id,
        router: this.$router,
        additional: this.changesRequiredAdditional,
      });
    },

    handleVideoPlay() {
      this.isVideoPlaying = true;
      this.$refs.video.play();
    },

    handleVideoEnded() {
      this.isVideoPlaying = false;
    },

    handleAudioStart() {
      this.WaveSurfer.play();
      this.isAudioPlaying = true;
    },

    handleAudioPause() {
      this.WaveSurfer.pause();
      this.isAudioPlaying = false;
    },
  },

  data() {
    return {
      id: null,
      name: null,
      category: null,
      subCategory: null,
      version: null,
      frameRate: null,
      bitRate: null,
      plugin: null,
      resolution: null,
      compression: null,
      format: null,
      sampleRate: null,
      trackLength: null,
      tempo: null,
      vocal: null,
      youtube: null,
      affiliation: null,
      composerName: null,
      stockMusic: null,
      publisher: null,
      looped: null,
      loopedVideo: null,
      free: null,
      videoPreview: null,
      uploadPackage: null,
      thumbnail: null,
      audioPreview: null,
      description: null,
      editorial: null,
      tags: null,

      loaded: false,

      WaveSurfer: null,

      isFinal: false,

      isAudioPlaying: false,

      isVideoPlaying: false,

      changesRequiredAdditional: "",

      isPublished: false,
    };
  },

  watch: {
    getInformationForCheck: function(nv, ov) {
      const product = nv[0];
      this.isPublished = product.type === "published" ? true : false;
      this.id = product.id;
      this.name = product.productName;
      this.category = product.category;
      this.subCategory = product.subCategory;

      if (product.description) {
        this.description = product.description;
      }

      if (product.tags) {
        this.tags = product.tags;
      }

      if (product.resolution) {
        this.resolution = product.resolution;
      }

      if (product.frameRate) {
        this.frameRate = product.frameRate;
      }

      if (product.bitRate) {
        this.bitRate = product.bitRate;
      }

      if (product.sampleRate) {
        this.sampleRate = product.sampleRate;
      }

      if (product.compression) {
        this.compression = product.frameRate;
      }

      if (product.version) {
        this.version = product.version;
      }

      if (product.plugin && product.plugin !== "no plugins") {
        this.plugin = product.plugin;
      }

      if (product.stockMusic && product.stockMusic.length > 0) {
        this.stockMusic = product.stockMusic;
      }

      if (product.trackLength && product.trackLength.length > 0) {
        this.trackLength = product.trackLength;
      }

      if (product.tempo && product.tempo.length > 0) {
        this.tempo = product.tempo;
      }

      if (product.youtube) {
        this.youtube = product.youtube;
      }

      if (product.affiliation) {
        this.affiliation = product.affiliation;
      }

      if (product.composerName && product.composerName.length > 0) {
        this.composerName = product.composerName;
      }

      if (product.publisherName && product.publisherName.length > 0) {
        this.publisher = product.publisherName;
      }

      /* vocal */
      if (product.vocal && product.vocal !== "no vocal") {
        this.vocal = product.vocal;
      }
      /* end of vocal */

      /* format */
      if (product.format) {
        this.format = product.format;
      }
      /* end of format */

      if (product.previewUrl) {
        if (
          product.category === "Royalty Free Music" ||
          product.category === "Sound Effects"
        ) {
          const updt = {
            url: product.previewUrl,
            name: product.previewRef.split("-_7_-")[1],
            ref: product.previewRef,
          };
          this.audioPreview = updt;
        } else {
          const updt = {
            url: product.previewUrl,
            name: product.previewRef.split("-_7_-")[1],
            ref: product.previewRef,
          };
          this.videoPreview = updt;
        }
      }

      if (product.thumbnailUrl) {
        const updt = {
          url: product.thumbnailUrl,
          name: product.thumbnailRef.split("-_7_-")[1],
          ref: product.thumbnailRef,
        };
        this.thumbnail = updt;
      }

      if (product.packageUrl) {
        const updt = {
          url: product.packageUrl,
          name: product.packageRef.split("-_7_-")[1],
          ref: product.packageRef,
        };
        this.uploadPackage = updt;
      }

      /* togglers */

      if (product.free) {
        this.free = product.free;
      }
      if (product.looped) {
        this.looped = product.looped;
      }
      if (product.loopedVideo) {
        this.loopedVideo = product.loopedVideo;
      }
      if (product.editorial) {
        this.editorial = product.editorial;
      }

      this.loaded = true;

      setTimeout(() => {
        if (product && this.audioPreview) {
          const wavesurfer = WaveSurfer.create({
            container: "#waveform",
            waveColor: "#CBD5E1",
            progressColor: "#1DC373",
            cursorColor: "transparent",
            backend: "MediaElement",
            autoCenter: true,
            responsive: true,
          });
          wavesurfer.load(this.audioPreview.url);

          wavesurfer.on("finish", () => {
            wavesurfer.stop();
            this.isAudioPlaying = false;
          });

          this.WaveSurfer = wavesurfer;

          console.log(this.$refs.waveform.children);

          if (this.$refs.waveform.children.length > 2) {
            this.$refs.waveform.children[0].classList.add("hidden");
            console.log("has many");
          } else {
            console.log("has one");
          }
        }
      }, 600);
    },
  },
};
</script>
