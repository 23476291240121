import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import User from "../views/User.vue";
import Staff from "../views/Staff.vue";
import StaffProfile from "../views/StaffProfile.vue";
import UserProfile from "../views/UserProfile.vue";
import Personal from "../views/Personal.vue";
import UserPersonal from "../views/UserPersonal.vue";
import Subscribers from "../views/Subscribers.vue";
import Order from "../views/Order.vue";
import Downloads from "../views/Downloads.vue";
import AuthorList from "../views/AuthorList.vue";
import AuthorPayout from "../views/AuthorPayout.vue";
import AuthorEarnings from "../views/AuthorEarnings.vue";
import RequestList from "../views/RequestList.vue";
import ItemsList from "../views/ItemsList.vue";
import ItemsRequest from "../views/ItemsRequest.vue";
import CheckProduct from "../views/CheckProduct.vue";
import EmailTemplate from "../views/EmailTemplate.vue";
import Login from "../views/Login.vue";
import Affiliates from "../views/Affiliates.vue";
import AffiliatesPayout from "../views/AffiliatesPayout.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/login",
    name: "login",
    component: Login,
  },

  {
    path: "/affiliates",
    name: "affiliates",
    component: Affiliates,
  },

  {
    path: "/affiliates-payout",
    name: "affiliates payout",
    component: AffiliatesPayout,
  },

  {
    path: "/motionridge/author-list",
    name: "Author list",
    component: AuthorList,
  },

  {
    path: "/motionridge/subscribers",
    name: "subscribers",
    component: Subscribers,
  },

  {
    path: "/motionridge/author-payouts",
    name: "Author payouts",
    component: AuthorPayout,
  },
  {
    path: "/motionridge/author-earnings",
    name: "Author earnings",
    component: AuthorEarnings,
  },
  {
    path: "/motionridge/downloads",
    name: "downloads",
    component: Downloads,
  },

  {
    path: "/motionridge/items-list",
    name: "Items list",
    component: ItemsList,
  },
  {
    path: "/motionridge/check-product/:id",
    name: "check product",
    component: CheckProduct,
  },
  {
    path: "/motionridge/email-template",
    name: "Email Template",
    component: EmailTemplate,
  },
  {
    path: "/motionridge/request-list",
    name: "Request list",
    component: RequestList,
  },
  {
    path: "/motionridge/items-request",
    name: "Items Request",
    component: ItemsRequest,
  },
  {
    path: "/user",
    name: "User",
    component: User,
  },
  {
    path: "/userprofile/:id",
    name: "userProfile",
    component: UserProfile,
    children: [
      {
        path: "personal",
        component: UserPersonal,
      },
      {
        path: "order",
        component: Order,
      },
    ],
  },
  {
    path: "/staff",
    name: "Staff",
    component: Staff,
  },
  {
    path: "/staffprofile/:id",
    name: "staffProfile",
    component: StaffProfile,
    children: [
      {
        path: "personal",
        component: Personal,
      },
      {
        path: "order",
        component: Order,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
