<template>
	<div class="px-8 pt-6">
		<h1 class="text-gray-900 dark:text-gray-200 font-bold text-base md:text-lg">
			Personal Information
		</h1>
	</div>

	<!-- information -->
	<div
		class="px-8 py-8 w-full max-w-4xl grid grid-cols-1 lg:grid-cols-2 gap-x-32 gap-y-5"
	>
		<div
			class="w-full py-3 space-x-16 flex flex-grow-0 items-center justify-between"
		>
			<h1 class="text-gray-500 dark:text-gray-400 text-sm whitespace-nowrap">
				First Name
			</h1>
			<h1 class="text-gray-900 dark:text-gray-100 text-sm whitespace-nowrap">
				{{ userInfo.firstName }}
			</h1>
		</div>

		<div
			class="w-full py-3 space-x-16 flex flex-grow-0 items-center justify-between"
		>
			<h1 class="text-gray-400  dark:text-gray-400 text-sm whitespace-nowrap">
				Last Name
			</h1>
			<h1 class="text-gray-900  dark:text-gray-100 text-sm whitespace-nowrap">
				{{ userInfo.lastName }}
			</h1>
		</div>

		<div
			class="w-full py-3 flex items-center justify-between space-x-16 flex-grow-0"
		>
			<h1 class="text-gray-500 dark:text-gray-400 text-sm whitespace-nowrap">
				User name
			</h1>
			<h1 class="text-gray-900 dark:text-gray-100 text-sm whitespace-nowrap">
				{{ userInfo.userName }}
			</h1>
		</div>

		<div
			class="w-full py-3 flex items-center justify-between space-x-16 flex-grow-0"
		>
			<h1 class="text-gray-500 dark:text-gray-400 text-sm whitespace-nowrap">
				Email
			</h1>
			<h1 class="text-gray-900 dark:text-gray-100 text-sm whitespace-nowrap">
				{{ userInfo.email }}
			</h1>
		</div>

		<div
			v-if="userInfo.portfolioLink"
			class="w-full py-3 flex items-start justify-between space-x-16 flex-grow-0"
		>
			<h1 class="text-gray-500 dark:text-gray-400 text-sm whitespace-nowrap">
				Portfolio Links
			</h1>
			<div class="flex flex-col gap-2 w-full">
				<a
					v-for="link in userInfo.portfolioLink.split(',')"
					class="text-xs text-right text-indigo-500 hover:underline"
					:key="link"
					target="_blank"
					:href="link"
					>{{ link }}</a
				>
			</div>
		</div>
		<div
			v-if="userInfo.paypal_email"
			class="w-full py-3 flex items-center justify-between space-x-16 flex-grow-0"
		>
			<h1 class="text-gray-500 dark:text-gray-400 text-sm whitespace-nowrap">
				Paypal Email
			</h1>
			<h1 class="text-gray-900 dark:text-gray-100 text-sm whitespace-nowrap">
				{{ userInfo.paypal_email }}
			</h1>
		</div>
	</div>

	<!-- aditional information -->
	<div class="px-6 py-4">
		<h1
			class="uppercase font-bold text-gray-600 dark:text-gray-300 text-xs tracking-widest"
		>
			Additional information
		</h1>
	</div>
	<div
		class="px-6 py-4 w-full max-w-4xl grid grid-cols-1 lg:grid-cols-2 gap-x-32 gap-y-5"
	>
		<div class="py-3 space-x-16 flex flex-grow-0 items-center justify-between">
			<h1 class="text-gray-500 dark:text-gray-400 text-sm whitespace-nowrap">
				Subscription Type
			</h1>
			<h1 class="text-gray-900 dark:text-gray-100 text-sm whitespace-nowrap">
				{{ userInfo.subscription }}
			</h1>
		</div>
	</div>
</template>

<script>
import EffectButton from "../components/utils/EffectButton.vue"
export default {
	name: "UserPersonal",
	components: { EffectButton },
	props: ["userInfo"]
}
</script>
