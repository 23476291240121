<template>
	<div class="px-6 py-4">
		<h1 class="text-gray-900 font-bold text-base md:text-lg">
			Order
		</h1>
		<h3 class="mt-1 text-xs text-gray-700">
			Past Transactions
		</h3>

		<!-- table -->
		<div
			class="mt-10 mb-5 p-3  grid grid-cols-5 w-full rounded-md bg-gray-200 dark:bg-darkLight"
		>
			<h3 class="text-xs uppercase font-medium text-gray-700 dark:text-gray-300">
				Order id
			</h3>
			<h3 class="text-xs uppercase font-medium text-gray-700 dark:text-gray-300">
				Subscription id
			</h3>
			<h3 class="text-xs uppercase font-medium text-gray-700 dark:text-gray-300">
				Payout Date
			</h3>
			<h3 class="text-xs uppercase font-medium text-gray-700 dark:text-gray-300">
				Amount
			</h3>
			<h3 class="text-xs uppercase font-medium text-gray-700 dark:text-gray-300">
				Reciept
			</h3>
		</div>
		<div class="w-full flex flex-col max-h-96 overflow-y-auto">
			<div
				v-for="order in getOrders"
				:key="order.id"
				class="p-3  grid grid-cols-5 w-full border-t border-gray-300 dark:border-gray-700"
			>
				<h3 class="text-xs font-medium text-gray-600 dark:text-gray-400">
					{{ order.id }}
				</h3>
				<h3 class="text-xs font-medium text-gray-600 dark:text-gray-400">
					{{ order.subscription_id }}
				</h3>
				<h3 class="text-xs font-medium text-gray-600 dark:text-gray-400">
					{{ order.payout_date }}
				</h3>
				<h3 class="text-xs font-medium text-gray-600 dark:text-gray-400">
					{{ order.amount }}
				</h3>
				<a
					:href="order.receipt_url"
					target="_blank"
					class="text-xs font-medium text-indigo-500 hover:underline"
				>
					View reciept
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { computed } from "vue"
import { mapActions, mapGetters } from "vuex"
export default {
	name: "Order",
	components: {},
	mounted() {
		this.fetchOrders(this.$route.params.id)
	},
	methods: {
		...mapActions(["fetchOrders"])
	},
	computed: {
		...mapGetters(["getOrders"])
	}
}
</script>
