<template>
	<div
		class="animate-spin loader"
		:class="[
			bright ? 'loader-bright' : 'loader',
			{ largeLoader: large },
			{ 'loader-brand': brand },
			{ 'small-brand': smallBrand },
			{ 'loader-light': light }
		]"
	></div>
</template>

<script>
export default {
	name: "Loader",
	components: {},
	props: ["bright", "large", "brand", "smallBrand", "light"]
}
</script>

<style scoped>
.loader {
	border: 3px solid #067192;
	border-radius: 50%;
	border-top: 3px solid #fff;
	width: 20px;
	height: 20px;
}

.loader-light {
	border: 3px solid #f8d2da9d;
	border-radius: 50%;
	border-top: 3px solid #fff;
	width: 20px;
	height: 20px;
}

.largeLoader {
	border: 4px solid #067192;
	border-radius: 50%;
	border-top: 4px solid #fff;
	width: 80px;
	height: 80px;
}

.loader-bright {
	border: 3px solid #f73859;
	border-radius: 50%;
	border-top: 3px solid #fff;
	width: 20px;
	height: 20px;
}

.loader-brand {
	border: 4px solid #1dc373;
	border-radius: 50%;
	border-top: 4px solid transparent;
	width: 80px;
	height: 80px;
}

.small-brand {
	border: 3px solid #1dc373;
	border-radius: 50%;
	border-top: 3px solid transparent;
	width: 20px;
	height: 20px;
}
</style>
