<template>
	<div class="w-full flex flex-col flex-shrink">
		<label
			:id="`label${id}`"
			:for="id"
			class="flex-shrink-0 font-medium text-gray-900 dark:text-gray-300 text-sm"
			>{{ label }}</label
		>
		<input
			v-bind="$attrs"
			:id="id"
			:aria-labelledby="`label${id}`"
			class="w-full flex-shrink mt-1 px-3 py-2 text-sm text-gray-900 dark:text-gray-300 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-800 border border-gray-300 dark:border-gray-700 bg-transparent rounded-md"
		/>
		<div v-if="error" class="mt-1 text-sm text-red-700 dark:text-red-500">
			{{ error }}
		</div>
	</div>
</template>

<script>
export default {
	inheritAttrs: false,
	name: "TextInput",
	props: {
		label: {
			type: String,
			default: "label"
		},
		error: {
			type: String
		}
	},
	components: {},
	mounted() {
		this.id = Math.floor(Math.random() * 100000)
	},
	data() {
		return {
			id: 0
		}
	}
}
</script>
