<template>
	<!-- delete confirmation modal -->
	<Transition
		enter-active-class="ease-out duration-300"
		enter-from-class=" opacity-0"
		enter-to-class="opacity-100"
		leave-active-class="ease-in duration-200"
		leave-from-class="opacity-100"
		leave-to-class="opacity-0"
	>
		<div
			v-if="getDeleteModalState"
			@click.self="handleDeleteModal(false)"
			class="bare-modalBg p-10 fixed z-50 inset-0 flex flex-col items-center justify-center"
		>
			<Transition
				enter-active-class="ease-out duration-300"
				enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
				enter-to-class="opacity-100 translate-y-0 sm:scale-100"
				leave-active-class="ease-in duration-200"
				leave-from-class="opacity-100 translate-y-0 sm:scale-100"
				leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
			>
				<div
					v-if="getDeleteModalState"
					class="w-full md:max-w-lg mx-auto flex flex-col items-start justify-start bg-white dark:bg-darkWhite rounded-md border dark:border-gray-700 border-gray-300"
				>
					<div class="p-5 flex justify-start items-start space-x-5">
						<div
							class="flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
						>
							<svg
								class="h-6 w-6 text-red-600"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
								/>
							</svg>
						</div>

						<div class="flex flex-col items-start justify-start">
							<h1
								class="text-base md:text-xl font-bold text-gray-800 dark:text-gray-200"
							>
								Delete ?
							</h1>
							<h3 class="my-3 text-gray-600 dark:text-gray-500 text-sm">
								Are you sure you want to delete this Contributor
							</h3>
						</div>
					</div>
					<!-- action -->
					<div
						class="w-full px-4 py-3 sm:px-6 rounded-b-md sm:flex sm:flex-row-reverse bg-gray-100 dark:bg-darkActive"
					>
						<button
							@click="handleDeleteContributor"
							type="button"
							class="px-4 py-2 sm:ml-3 sm:w-auto w-full inline-flex justify-center font-medium sm:text-sm text-xs text-white bg-red-600 hover:bg-red-700 rounded-md border border-transparent shadow-sm focus:outline-none"
						>
							Delete
						</button>
						<button
							@click.self="handleDeleteModal(false)"
							type="button"
							class="mt-3 px-4 py-2 sm:ml-3 sm:mt-0 sm:w-auto w-full inline-flex justify-center font-medium sm:text-sm text-xs text-gray-700 bg-white hover:bg-gray-50 rounded-md border border-gray-300 shadow-sm focus:outline-none"
						>
							Cancel
						</button>
					</div>
				</div>
			</Transition>
		</div>
	</Transition>

	<section class="p-3 md:p-9 min-h-screen overflow-y-auto">
		<!-- header -->
		<div class="flex md:flex-row flex-col justify-between md:items-start ">
			<div class="">
				<h1 class="font-bold text-gray-900 dark:text-gray-300 text-xl md:text-2xl">
					Author Earnings
				</h1>

				<h1 class="text-2xl md:text-4xl font-bold text-gray-700 dark:text-gray-200">
					${{ getTotalPayoutAmount && getTotalPayoutAmount.total }}
				</h1>
			</div>
			<!-- control -->
			<div class="mt-3 md:mt-0  flex items-center justify-between space-x-4">
				<Loader
					v-if="getAuthorFilterLoading"
					class="flex-shrink-0"
					:smallBrand="true"
				/>

				<!-- date picker -->
				<button
					@click="handleDateClear"
					v-if="date.length > 0"
					class="text-xs whitespace-nowrap px-3 py-2 rounded-md border border-gray-300 dark:border-gray-700 text-gray-800 font-medium dark:text-gray-200"
				>
					All Earnings
				</button>

				<input
					type="month"
					v-model="date"
					class="w-full py-1 shadow-md rounded pl-2 text-sm focus:outline-none dark:bg-darkLight dark:text-white text-gray-800"
				/>
				<!-- end of date picker -->
				<!-- search -->
				<div
					class="px-3 py-2 w-full flex items-center justify-between bg-white dark:bg-darkLight border-gray-300 border dark:border-darkForeground rounded shadow"
				>
					<input
						@input="handleQuery"
						v-model="searchQuery"
						type="text"
						placeholder="Search by user name"
						class="w-full h-full flex-shrink focus:outline-none text-xs bg-transparent dark:placeholder-gray-300 placeholder-gray-400 text-gray-800 dark:text-white"
					/>
					<svg
						class="w-4 h-4 flex-shrink-0 text-gray-400"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fill-rule="evenodd"
							d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
							clip-rule="evenodd"
						/>
					</svg>
				</div>
				<!-- end of search -->

				<!-- status filter -->
				<Menu as="div" class="relative" v-slot="{ open }">
					<MenuButton
						:class="{
							'bg-gray-400 dark:bg-darkActive text-white': open,
							'text-gray-800  dark:text-gray-300': !open
						}"
						class="px-3 py-2 text-xs font-medium bg-gray-300 dark:bg-darkLight hover:bg-gray-400 hover:text-white dark:hover:bg-darkActive rounded-md flex items-center justify-between focus:outline-none transition-all duration-300 outline-none whitespace-nowrap"
					>
						<span>{{ status === "" ? "Status" : status }}</span>
						<svg class="h-4 w-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
							<path
								fill-rule="evenodd"
								d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
								clip-rule="evenodd"
							/>
						</svg>
					</MenuButton>

					<Transition
						enter-active-class="transition transform duration-100 ease-in"
						enter-from-class="opacity-0 scale-95"
						enter-to-class="opacity-100 scale-100"
						leave-active-class="transition transform duration-300 ease-out"
						leave-from-class="opacity-100 scale-100"
						leave-to-class="opacity-0 scale-95"
					>
						<MenuItems
							class="absolute z-50 py-2 w-36 mt-12  flex-shrink-0 top-0 right-0 focus:outline-none outline-none rounded-md shadow-lg bg-white  dark:bg-darkLight overflow-hidden"
						>
							<MenuItem @click="status = ''" v-slot="{ active }">
								<div
									:class="[
										active
											? 'bg-gray-100 dark:bg-darkActive dark:text-white text-gray-400'
											: 'bg-white text-gray-800 dark:text-gray-500  dark:bg-darkLight'
									]"
									class="px-3 py-2 cursor-pointer text-xs focus:outline-none outline-none"
								>
									None
								</div>
							</MenuItem>
							<MenuItem @click="status = 'paid'" v-slot="{ active }">
								<div
									:class="[
										active
											? 'bg-gray-100 dark:bg-darkActive text-green-500'
											: 'bg-white text-gray-800 dark:text-gray-500  dark:bg-darkLight'
									]"
									class="px-3 py-2 cursor-pointer text-xs focus:outline-none outline-none"
								>
									Paid
								</div>
							</MenuItem>
							<MenuItem
								@click="status = 'not paid'"
								class="whitespace-nowrap"
								v-slot="{ active }"
							>
								<div
									:class="[
										active
											? 'bg-gray-100 dark:bg-darkActive text-yellow-500'
											: 'bg-white text-gray-800 dark:text-gray-500  dark:bg-darkLight'
									]"
									class="px-3 py-2 whitespace-nowrap flex-shrink-0 cursor-pointer text-xs focus:outline-none outline-none"
								>
									Not paid
								</div>
							</MenuItem>
						</MenuItems>
					</Transition>
				</Menu>
				<!-- end of status filter -->
			</div>
			<!-- end of control -->
		</div>
		<!-- end of header -->

		<!-- table -->
		<main class="mt-8 min-h-table overflow-y-hidden overflow-x-auto">
			<header
				class="min-w-table h-12 px-4 w-full flex-shrink-0 flex items-center justify-center bg-gray-100 dark:bg-darkWhite rounded-md border border-gray-300 dark:border-darkForeground shadow"
			>
				<CustomCheckbox
					v-if="getAuthorPayouts.length > 0"
					:selected="allAuthorSelected"
				>
					<input
						@input="handleAllAuthorSelect"
						v-model="allAuthorSelected"
						type="checkbox"
						class="absolute inset-y-0 my-auto mx-auto z-10 appearance-none w-5 h-5 checked:bg-brandColorDark dark:checked:bg-brandColorDark checked:border-brandColor bg-white dark:bg-gray-500 rounded border-2 dark:border-darkLight border-gray-300 focus:outline-none cursor-pointer transition-colors duration-300 outline-none overflow-hidden"
					/>
				</CustomCheckbox>

				<section
					class="ml-4 grid grid-cols-9 w-full items-center place-content-center select-none"
				>
					<div
						class="col-span-2 uppercase text-xs font-semibold text-gray-500 dark:text-gray-300"
					>
						user
					</div>

					<div
						class="col-span-1 uppercase text-xs font-semibold text-gray-500 dark:text-gray-300"
					>
						user name
					</div>
					<div
						class="col-span-1 uppercase text-xs font-semibold text-gray-500 dark:text-gray-300"
					>
						product id
					</div>
					<div
						class="col-span-1 uppercase text-xs font-semibold text-gray-500 dark:text-gray-300"
					>
						earned
					</div>
					<div
						class="col-span-1 uppercase text-xs font-semibold text-gray-500 dark:text-gray-300"
					>
						Month
					</div>
					<div
						class="col-span-1 uppercase text-xs font-semibold text-gray-500 dark:text-gray-300"
					>
						Year
					</div>

					<div
						class="col-span-1 uppercase text-xs font-semibold text-gray-500 dark:text-gray-300"
					>
						status
					</div>
				</section>
			</header>

			<div
				v-if="!getAuthorLoading && getAuthorPayouts.length <= 0"
				class="h-72 w-full flex items-center justify-center"
			>
				<h1 class="text-xs text-gray-500">
					No eanings made
				</h1>
			</div>

			<Transition
				enter-active-class="transition transform duration-100 ease-in"
				enter-from-class="opacity-50"
				enter-to-class="opacity-100"
				leave-active-class="transition transform duration-300 ease-out"
				leave-from-class="opacity-100"
				leave-to-class="opacity-0"
			>
				<div
					v-if="getAuthorLoading"
					:class="[sidebarToggled ? 'md:ml-72 mt-16' : 'md:ml-16 mt-16']"
					class="fixed z-30 inset-0 flex items-center justify-center transition duration-300"
				>
					<Loader :large="true" :brand="true" />
				</div>
			</Transition>

			<main
				class="mt-4 max-h-table min-h-table min-w-table overflow-y-auto overflow-x-hidden flex flex-col space-y-4"
			>
				<section
					v-for="payout in getAuthorPayouts"
					:key="payout.id"
					class="min-w-table px-4 h-26 py-3 w-full flex items-center justify-center bg-white dark:bg-darkWhite rounded-md hover:shadow-md cursor-pointer"
				>
					<CustomCheckbox>
						<input
							type="checkbox"
							class="absolute inset-y-0 my-auto mx-auto z-10 appearance-none w-5 h-5 checked:bg-brandColorDark dark:checked:bg-brandColorDark checked:border-brandColor bg-white dark:bg-gray-500 rounded border-2 dark:border-darkLight border-gray-300 focus:outline-none cursor-pointer transition-all duration-300 outline-none overflow-hidden"
						/>
					</CustomCheckbox>

					<section
						class="ml-4 grid grid-cols-9 w-full items-center place-content-center select-none"
					>
						<div class="col-span-2 py-1 font-semibold text-gray-500 dark:text-white">
							<div class="flex items-center space-x-4">
								<Avatar
									class="flex-shrink-0 overflow-hidden"
									:img="payout.user.profile"
								/>
								<div class="">
									<h1 class="text-sm w-56 truncate text-gray-800 dark:text-white">
										{{ payout.user.firstName }} {{ payout.user.lastName }}
										<span
											v-if="payout.isUnclaimed"
											class="rounded-full p-1 px-2 bg-yellow-100 font-bold text-yellow-700 text-xs"
											>Unclaimed</span
										>
									</h1>
									<h3 class="text-xs w-56 truncate text-gray-500 dark:text-gray-400">
										{{ payout.user.email }}
									</h3>
								</div>
							</div>
						</div>
						<div class="text-xs w-36 truncate  text-gray-500 dark:text-white">
							{{ payout.user.userName }}
						</div>
						<div class="text-xs w-36 truncate  text-gray-500 dark:text-white">
							{{ payout.productId }}
						</div>
						<div class="text-xs w-36 truncate  text-gray-500 dark:text-white">
							$ {{ payout.amount }}
						</div>
						<div class="text-xs w-36 truncate  text-gray-500 dark:text-white">
							{{ payout.month }}
						</div>
						<div class="text-xs w-36 truncate  text-gray-500 dark:text-white">
							{{ payout.year }}
						</div>
						<div class="text-xs font-semibold text-gray-500 dark:text-white">
							<span :class="payout.isPaid ? 'text-green-600' : 'text-yellow-600'">{{
								payout.isPaid ? "paid" : "Not paid"
							}}</span>
						</div>
						<!-- action button -->
						<div class="ml-auto">
							<Menu
								as="div"
								class="relative focus:outline-none outline-none"
								v-slot="{ open }"
							>
								<MenuButton class=" focus:outline-none outline-none">
									<EffectButton
										id="menuBtn"
										ref="menuBtn"
										:rounded="true"
										:active="open"
										class="focus:outline-none outline-none"
									>
										<svg
											class="w-5 h-5 text-gray-700 dark:text-gray-400"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
											/>
										</svg>
									</EffectButton>
								</MenuButton>
								<Transition
									class="absolute z-50"
									enter-active-class="transition transform duration-100 ease-in"
									enter-from-class="opacity-0 scale-95"
									enter-to-class="opacity-100 scale-100"
									leave-active-class="transition transform duration-300 ease-out"
									leave-from-class="opacity-100 scale-100"
									leave-to-class="opacity-0 scale-95"
								>
									<MenuItems
										ref="menuItems"
										id="menuItems"
										class="absolute z-50 py-2 w-36 mt-12 top-0 right-0 focus:outline-none outline-none rounded-md shadow-lg bg-white  dark:bg-darkLight overflow-hidden border border-gray-300 dark:border-darkBackground"
									>
										<router-link :to="`/userprofile/${payout.user.id}/personal`">
											<MenuItem v-slot="{ active }">
												<div
													:class="[
														active
															? 'bg-gray-100 dark:bg-darkActive dark:text-white text-gray-800'
															: 'bg-white text-gray-500 dark:text-gray-500  dark:bg-darkLight'
													]"
													class="px-3 py-2 cursor-pointer text-xs flex items-center justify-start space-x-2 focus:outline-none"
												>
													<svg
														class="w-4 h-4"
														fill="none"
														viewBox="0 0 24 24"
														stroke="currentColor"
													>
														<path
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
															d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
														/>
														<path
															stroke-linecap="round"
															stroke-linejoin="round"
															stroke-width="2"
															d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
														/>
													</svg>
													<span>View details</span>
												</div>
											</MenuItem>
										</router-link>
										<MenuItem
											@click="handleEmailCopy(payout.user.email)"
											v-slot="{ active }"
										>
											<div
												:class="[
													active
														? 'bg-gray-100 dark:bg-darkActive dark:text-white text-gray-800'
														: 'bg-white text-gray-500 dark:text-gray-500  dark:bg-darkLight'
												]"
												class="px-3 py-2 cursor-pointer text-xs flex items-center justify-start space-x-2 focus:outline-none"
											>
												<svg
													class="w-4 h-4"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														stroke-width="2"
														d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
													/>
												</svg>
												<span>Send email</span>
											</div>
										</MenuItem>
									</MenuItems>
								</Transition>
							</Menu>
						</div>
					</section>
				</section>
			</main>

			<!-- table footer -->
		</main>
		<!-- table footer -->
		<div
			class="mt-4 py-5 px-5 rounded-md bg-white dark:bg-darkWhite flex flex-col md:flex-row items-center justify-between"
		>
			<Pagination :pages="getAuthorPages" v-on:onClick="currentPage = $event" />

			<div class="flex items-center space-x-6">
				<div class="">
					<Select
						:opts="getAuthorListSize"
						v-on:onChange="usersPerPage = $event.name"
					/>
				</div>
				<h1 class="mt-4 md:mt-0 text-gray-700 dark:text-gray-300 text-xs md:text-sm">
					page{{ getAuthorPages === 0 ? 0 : currentPage }} of {{ getAuthorPages }}
				</h1>
			</div>
		</div>
		<!-- end of table -->
	</section>
</template>

<script>
import moment from "moment"
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue"
import EffectButton from "../components/utils/EffectButton.vue"
import CustomCheckbox from "../components/inputs/CustomCheckbox.vue"
import Avatar from "../components/utils/Avatar.vue"
import Badge from "../components/utils/Badge.vue"
import { mapActions, mapGetters } from "vuex"
import Loader from "../components/utils/Loader.vue"
import Pagination from "../components/utils/Pagination.vue"
import Select from "../components/inputs/Select.vue"

export default {
	name: "Author payout",
	props: ["sidebarToggled"],
	components: {
		Menu,
		MenuButton,
		MenuItems,
		MenuItem,
		EffectButton,
		CustomCheckbox,
		Avatar,
		Badge,
		Loader,
		Pagination,
		Select
	},

	mounted() {
		let year = ""
		let month = ""

		const date = moment()
		this.date = date.format("YYYY-MM")
		month = date.format("MMMM")
		year = date.format("YYYY")

		this.usersPerPage = this.getAuthorListSize[0].name
	},

	computed: {
		...mapGetters(["getAuthorPayouts"]),

		...mapGetters(["getAuthorLoading"]),
		...mapGetters(["getAuthorListSize"]),
		...mapGetters(["getAuthorPages"]),
		...mapGetters(["getAuthorFilterLoading"]),
		...mapGetters(["getDeleteModalState"]),
		...mapGetters(["getDeleteModalLoading"]),
		...mapGetters(["getTotalPayoutAmount"])
	},

	methods: {
		...mapActions(["getAllAuthors"]),
		...mapActions(["filterAuthor"]),
		...mapActions(["filterAuthorStatus"]),
		...mapActions(["appendAlert"]),
		...mapActions(["selectIndividualAuthor"]),
		...mapActions(["selectAllAuthor"]),
		...mapActions(["changeSelectedAuthorStatus"]),
		...mapActions(["setDeleteModalState"]),

		...mapActions(["fetchAuthorEarnings"]),

		handleDateClear() {
			this.date = ""
			this.fetchAuthorEarnings({
				query: this.searchQuery,
				year: "",
				month: "",
				status: this.status,
				size: this.getAuthorListSize[0].name,
				page: 0
			})
		},

		handleQuery(e) {
			let year = ""
			let month = ""
			if (this.date) {
				const date = moment(this.date)
				month = date.format("MMMM")
				year = date.format("YYYY")
			}
			this.fetchAuthorEarnings({
				query: e.target.value,
				year,
				month,
				status: this.status,
				size: this.getAuthorListSize[0].name,
				page: 0
			})
		},

		async handleEmailCopy(email) {
			navigator.clipboard
				.writeText(email)
				.then(() => {
					this.appendAlert({
						title: "Email Copied!",
						sub: `${email} has been copied to clipboard`,
						icon: "heroicons-solid:check-circle",
						type: "success"
					})
				})
				.catch(() => console.log("copying email failed"))
		}
	},

	data() {
		return {
			usersPerPage: 25,
			currentPage: 1,
			searchQuery: "",
			status: "none",
			individualAuthorSelected: [],
			allAuthorSelected: false,
			date: ""
		}
	},

	watch: {
		status: function(nv) {
			let month = ""
			let year = ""
			if (this.date) {
				const date = moment(this.date)
				month = date.format("MMMM")
				year = date.format("YYYY")
			}
			this.fetchAuthorEarnings({
				query: this.searchQuery,
				month,
				year,
				status: nv,
				size: this.getAuthorListSize[0].name,
				page: 0
			})
		},
		date: function(nv) {
			if (nv) {
				const date = moment(nv)
				const month = date.format("MMMM")
				const year = date.format("YYYY")
				this.fetchAuthorEarnings({
					query: this.searchQuery,
					month,
					year,
					status: this.status,
					size: this.getAuthorListSize[0].name,
					page: 0
				})
			}
		},
		usersPerPage: function(nv, ov) {
			let month = ""
			let year = ""
			if (this.date) {
				const date = moment(this.date)
				month = date.format("MMMM")
				year = date.format("YYYY")
			}

			this.fetchAuthorEarnings({
				query: this.searchQuery,
				month,
				year,
				status: this.status,
				size: nv,
				page: 0
			})
		},

		searchQuery: function(nv, ov) {
			if (nv.length <= 0) {
				let month = ""
				let year = ""
				if (this.date) {
					const date = moment(this.date)
					month = date.format("MMMM")
					year = date.format("YYYY")
				}
				this.fetchAuthorEarnings({
					month,
					year,
					status: this.status,
					size: this.getAuthorListSize[0].name,
					page: 0
				})
			}
		},

		currentPage: function(nv, ov) {
			let month = ""
			let year = ""
			if (this.date) {
				const date = moment(this.date)
				month = date.format("MMMM")
				year = date.format("YYYY")
			}

			this.fetchAuthorEarnings({
				query: this.searchQuery,
				size: this.usersPerPage,
				month,
				year,
				status: this.status,
				page: (nv - 1) * this.usersPerPage
			})
		}
	}
}
</script>
