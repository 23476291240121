<template>
	<div
		class="px-6 py-3 w-full text-sm text-gray-500 flex items-center justify-start whitespace-nowrap transition-all duration-100 cursor-pointer focus:outline-none outline-none"
		:class="{ 'text-gray-800 dark:text-white': active, 'py-5': large }"
	>
		<slot />
	</div>
</template>

<script>
export default {
	name: "Sublinks",
	components: {},
	props: ["active", "large"]
}
</script>
