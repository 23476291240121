<template>
	<div
		class="w-6 h-6 bg-transparent relative flex items-center justify-center overflow-hidden"
	>
		<slot />
		<svg
			:class="selected ? 'dark:text-white' : 'dark:text-gray-500'"
			class="absolute inset-0 mt-1 ml-1 z-20 h-4 w-4 text-white bg-transparent pointer-events-none"
			viewBox="0 0 20 20"
			fill="currentColor"
		>
			<path
				fill-rule="evenodd"
				d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
				clip-rule="evenodd"
			/>
		</svg>
	</div>
</template>

<script>
export default {
	name: "CustomCheckbox",
	components: {},
	props: ["selected"]
}
</script>
