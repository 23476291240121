<template>
	<nav
		class="relative z-0 inline-flex shadow-sm -space-x-px"
		aria-label="Pagination"
	>
		<button
			href="#"
			@click="handlePrevious"
			class="px-2 py-1 inline-flex items-center relative dark:text-gray-400 font-medium text-gray-500 text-sm bg-white dark:bg-darkLight dark:hover:bg-darkActive hover:bg-gray-50 rounded-l-md border border-gray-300 dark:border-gray-700 focus:outline-none"
		>
			<span class="sr-only">Previous</span>
			<svg
				class="h-4 w-4"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="currentColor"
				aria-hidden="true"
			>
				<path
					fill-rule="evenodd"
					d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
					clip-rule="evenodd"
				/>
			</svg>
		</button>

		<button
			v-for="page in getPages"
			:key="page"
			@click="page === '...' ? handleSkip : handleClick(page)"
			:class="[{ 'bg-gray-100 dark:bg-darkActive': selectedPage == page }]"
			class="px-3 py-1 inline-flex items-center relative dark:text-gray-400 font-medium text-gray-700 text-sm bg-white dark:bg-darkLight dark:hover:bg-darkActive hover:bg-gray-50 border border-gray-300 dark:border-gray-700 focus:outline-none outline-none"
		>
			{{ page }}
		</button>

		<button
			@click="handleNext"
			href="#"
			class="px-2 py-1 inline-flex items-center relative dark:text-gray-400 font-medium text-gray-500 text-sm bg-white dark:bg-darkLight dark:hover:bg-darkActive hover:bg-gray-50 rounded-r-md border border-gray-300 dark:border-gray-700 focus:outline-none"
		>
			<span class="sr-only">Next</span>
			<svg
				class="h-4 w-4"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="currentColor"
				aria-hidden="true"
			>
				<path
					fill-rule="evenodd"
					d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
					clip-rule="evenodd"
				/>
			</svg>
		</button>
	</nav>
</template>

<script>
export default {
	name: "Pagination",
	components: {},
	props: ["pages"],
	emits: ["onClick"],
	computed: {
		getPages() {
			let pagesArray = []

			if (this.pages > 6) {
				if (this.selectedPage < this.pages - 3) {
					for (let i = this.selectedPage; i < this.selectedPage + 3; i++) {
						pagesArray.push(i)
					}
				} else {
					pagesArray.push(this.pages - 3)
				}

				if (this.selectedPage < this.pages - 3) {
					pagesArray.push("...")
				}

				for (let i = this.pages - 2; i <= this.pages; i++) {
					pagesArray.push(i)
				}
				return pagesArray
			} else {
				for (let i = 1; i <= this.pages; i++) {
					pagesArray.push(i)
				}
				return pagesArray
			}
		}
	},
	methods: {
		handleSkip() {
			console.log("skip")
		},
		handleClick(val) {
			this.selectedPage = val
			this.$emit("onClick", this.selectedPage)
		},
		handleNext() {
			if (this.selectedPage < this.pages) {
				this.selectedPage = this.selectedPage + 1
				this.$emit("onClick", this.selectedPage)
			}
		},
		handlePrevious() {
			if (this.selectedPage > 1) {
				this.selectedPage = this.selectedPage - 1
				this.$emit("onClick", this.selectedPage)
			}
		}
	},

	data() {
		return { selectedPage: 1 }
	}
}
</script>
