<template>
	<div
		@click="isUserProfile = !isUserProfile"
		class="2xl:hidden bg-black opacity-20 z-40 inset-0 fixed transition-opacity"
		:class="isUserProfile ? 'block ease-in' : 'hidden ease-out'"
	></div>
	<SideUserProfile
		v-if="getUserInformation && !getUserLoading"
		:userInfo="getUserInformation"
		class="2xl:hidden transform 2xl:translate-x-0 2xl:static 2xl:inset-auto"
		:class="
			isUserProfile
				? 'translate-x-0 ease-out transition-transform'
				: 'translate-x-full ease-in transition-transform'
		"
	/>
	<section class="min-h-screen p-6">
		<Transition
			enter-active-class="transition transform duration-100 ease-in"
			enter-from-class="opacity-50"
			enter-to-class="opacity-100"
			leave-active-class="transition transform duration-300 ease-out"
			leave-from-class="opacity-100"
			leave-to-class="opacity-0"
		>
			<div
				v-if="getUserLoading"
				:class="[sidebarToggled ? 'md:ml-72 mt-16' : 'md:ml-16 mt-16']"
				class="fixed z-30 inset-0 flex items-center justify-center transition duration-300"
			>
				<Loader :large="true" :brand="true" />
			</div>
		</Transition>
		<div v-if="getUserInformation && !getUserLoading">
			<header class="w-full pt-2 flex items-center justify-between">
				<h1 class="text-xl xl:text-2xl ">
					<span class="text-gray-900 dark:text-gray-200 font-bold"
						>{{ getUserInformation.type }} /
					</span>
					<span class=" text-lg xl:text-xl text-gray-600 dark:text-gray-300">{{
						getUserInformation.firstName
					}}</span>
				</h1>

				<!-- back button -->
				<button
					@click="$router.go(-1)"
					class="focus:outline-none flex items-center justfy-center bg-white dark:bg-darkWhite rounded-md border-gray-300 dark:border-gray-700 px-3 py-2 text-xs font-medium text-gray-900 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800 transition duration-300"
				>
					<svg
						class="text-gray-800 dark:text-gray-300 h-4 w-4 mr-2"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fill-rule="evenodd"
							d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
							clip-rule="evenodd"
						/>
					</svg>
					Back
				</button>
			</header>

			<main class="mt-6 bg-white dark:bg-darkWhite flex rounded-md shadow">
				<div class="w-full">
					<header
						class="border-b px-8 pt-2 flex items-center justify-between border-gray-300 dark:border-gray-700"
					>
						<div class="flex items-center space-x-5">
							<router-link to="personal">
								<div
									:class="[
										this.$route.path.split('/')[3] === 'personal'
											? ' text-brandColorDark border-b-4 border-brandColorDark'
											: 'text-gray-800 dark:text-gray-300 border-b-0'
									]"
									class="flex items-center py-2 justify-center text-xs font-semibold"
								>
									<svg
										class="w-5 h-5 mr-1"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
										/>
									</svg>
									Personal
								</div>
							</router-link>
							<router-link to="order">
								<div
									:class="[
										this.$route.path.split('/')[3] === 'order'
											? ' text-brandColorDark border-b-4 border-brandColorDark'
											: 'text-gray-600 dark:text-gray-300 border-b-0'
									]"
									class="flex items-center py-2 justify-center text-xs font-semibold hover:text-gray-900"
								>
									<svg class="w-5 h-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
										<path
											fill-rule="evenodd"
											d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
											clip-rule="evenodd"
										/>
									</svg>
									Order
								</div>
							</router-link>
						</div>

						<EffectButton
							@click="isUserProfile = true"
							:rounded="true"
							class="block 2xl:hidden focus:outline-none"
						>
							<span
								class="iconify w-5 h-5 text-gray-800 dark:text-gray-300"
								data-icon="bx:bxs-user-detail"
								data-inline="false"
							></span>
						</EffectButton>
					</header>
					<router-view :userInfo="getUserInformation" />
				</div>

				<div
					class="hidden 2xl:block w-full max-w-xs border-l border-gray-300 dark:border-gray-700"
				>
					<div
						class="px-8 py-10 flex flex-col items-center justify-center border-b border-gray-300 dark:border-gray-700"
					>
						<div class="w-24 h-24 overflow-hidden">
							<img
								class="w-full h-full object-cover flex-shrink-0 rounded-full"
								:src="getUserInformation.profile"
								alt="staffs profile pic"
							/>
						</div>

						<!-- badge -->
						<div
							class="mt-4 px-2 py-1 border-gray-300 dark:border-gray-600 border rounded-full text-xxs text-gray-300 dark:text-gray-600 uppercase"
						>
							{{ getUserInformation.type }}
						</div>

						<div class="mt-4 flex items-center flex-col">
							<h1 class="text-xl font-bold text-gray-800 dark:text-gray-200">
								{{ getUserInformation.firstName }}
							</h1>
							<h3 class="mt-2 text-xs text-gray-500  dark:text-gray-400">
								{{ getUserInformation.email }}
							</h3>
						</div>
					</div>

					<!-- salary info -->
					<div class="px-6 py-6 border-b border-gray-300 dark:border-gray-700">
						<h1
							class="uppercase text-gray-600 text-xs font-bold tracking-wide dark:text-gray-400"
						>
							Salary Paid
						</h1>

						<div class="mt-3 flex justify-between">
							<div class="flex flex-col">
								<h1 class=" text-xl text-gray-900 dark:text-gray-200">
									Rs. <span class="font-bold">85000</span>
								</h1>
								<h3 class="mt-1 text-xs text-gray-500 dark:text-gray-400">
									Total salary
								</h3>
							</div>

							<div class="flex flex-col">
								<h1 class=" text-xl text-gray-900 dark:text-gray-200">
									Rs. <span class="font-bold">25000</span>
								</h1>
								<h3 class="mt-1 text-xs text-gray-500 dark:text-gray-400">
									This month salary
								</h3>
							</div>
						</div>
					</div>

					<!-- additional info -->
					<div class="px-6 py-6 border-b border-gray-300 dark:border-gray-700">
						<h1
							class="uppercase text-gray-600 dark:text-gray-400 text-xs font-bold tracking-wide"
						>
							Additional Information
						</h1>

						<div class="mt-3 flex flex-col">
							<div class="space-y-1">
								<h3 class="text-sm text-gray-500 dark:text-gray-200">Position</h3>
								<h1 class="text-sm text-gray-700 dark:text-gray-400">
									{{ getUserInformation.position }}
								</h1>
							</div>
						</div>

						<div class="mt-3 w-full grid grid-cols-2 space-x-10">
							<div class="space-y-1">
								<h3 class="text-sm text-gray-500 dark:text-gray-200">
									Registered At
								</h3>
								<h1 class="text-sm text-gray-700 dark:text-gray-400">
									{{ getUserInformation.createdAt.split("T")[0] }}
								</h1>
							</div>

							<div class="space-y-1">
								<h3 class="text-sm text-gray-500 dark:text-gray-200">Last login</h3>
								<h1 class="text-sm text-gray-700 dark:text-gray-400">2021-02-6</h1>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	</section>
</template>

<script>
import EffectButton from "../components/utils/EffectButton.vue"
import SideUserProfile from "../components/SideUserProfile.vue"
import Loader from "../components/utils/Loader.vue"
import { mapActions, mapGetters } from "vuex"
export default {
	name: "UserProfile",
	components: { EffectButton, SideUserProfile, Loader },
	props: ["sidebarToggled"],
	methods: {
		...mapActions(["getUserInformationById"])
	},

	computed: {
		...mapGetters(["getUserInformation"]),
		...mapGetters(["getUserLoading"])
	},

	mounted() {
		this.getUserInformationById(this.$route.params.id)
	},

	data() {
		return { isUserProfile: false }
	}
}
</script>
