import { v4 as uuid } from "uuid";
import axios from "axios";
import setAuthToken from "../../config/setAuthToken";

const state = {
  admin: null,
};

const getters = {
  getAdmin: (state) => state.admin,
};

const actions = {
  async adminLogin({ commit }, params) {
    try {
      const admin = await axios.post(
        `${process.env.VUE_APP_BASE_URl}/cheif/login`,
        {
          email: params.email,
          password: params.password,
        }
      );
      const token = admin.data.token;

      if (token) {
        setAuthToken(token);
        commit("setAdmin", true);
        localStorage.setItem("token", token);
        params.router.push("/");
      }
    } catch (error) {
      window.location = "/login";
      console.log(error);
    }
  },

  async checkAdmin({ commit }) {
    try {
      let token = localStorage.getItem("token");
      setAuthToken(token);

      if (token) {
        const { data } = await axios.get(
          `${process.env.VUE_APP_BASE_URl}/cheif/check`
        );

        if (data) {
          commit("setAdmin", true);
        }

        if (!data) {
          commit("setAdmin", false);
          localStorage.removeItem("token");
          window.location = "/login";
        }
      } else {
        commit("setAdmin", false);
        localStorage.removeItem("token");
      }
    } catch (error) {
      window.location = "/login";
      console.log(error.response);
    }
  },

  logout({ commit }) {
    commit("setAdmin", false);
    localStorage.removeItem("token");
  },
};

const mutations = {
  setAdmin: (state, val) => (state.admin = val),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
