<template>
	<img
		class="object-cover rounded-full flex-shrink-0"
		:class="{ 'h-8 w-8': !small && !large, 'h-6 w-6': small, 'h-12 w-12': large }"
		:src="img"
		alt="users profile image"
	/>
</template>

<script>
export default {
	name: "Avatar",
	components: {},
	props: ["img", "small", "large"]
}
</script>
