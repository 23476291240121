<template>
	<teleport to="#modal">
		<Transition
			enter-active-class="transition duration-300 ease-in"
			enter-from-class="opacity-0 "
			enter-to-class="opacity-100"
			leave-active-class="transition duration-500 ease-out"
			leave-from-class="opacity-100 "
			leave-to-class="opacity-0"
		>
			<section
				v-if="getAddStaffModalState"
				class="modalBg p-10 w-full h-screen fixed inset-0 z-50 transition-opacity flex items-center justify-center"
			>
				<div
					class="overflow-y-auto w-full relative max-h-full m-10 bg-gray-200 dark:bg-darkBackground p-5 md:p-6 rounded-lg shadow-lg"
				>
					<Transition
						enter-active-class="transition duration-300 ease-in"
						enter-from-class="opacity-0 "
						enter-to-class="opacity-100"
						leave-active-class="transition duration-500 ease-out"
						leave-from-class="opacity-100 "
						leave-to-class="opacity-0"
					>
						<div
							v-if="loading"
							class="modalWhiteBg fixed z-50 inset-0 flex items-center justify-center"
						>
							<Loader :large="true" />
						</div>
					</Transition>

					<!-- close btn -->
					<button
						@click="handleModalClose"
						class="m-5 md:mt-6 md:mr-6 absolute top-0 right-0 focus:outline-none text-gray-600 dark:text-gray-300"
					>
						<svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
							<path
								fill-rule="evenodd"
								d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
								clip-rule="evenodd"
							/>
						</svg>
					</button>

					<div class="overflow-y-auto">
						<slot />
					</div>
				</div>
			</section>
		</Transition>
	</teleport>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import Loader from "./utils/Loader.vue"
export default {
	name: "Modal",
	props: {
		loading: {
			default: false
		}
	},
	mounted() {
		if (this.getAddStaffModalState) {
			document.body.style.overflow = "hidden"
		}
	},
	components: { Loader },
	methods: {
		...mapActions(["setAddStaffModalState"]),

		handleModalClose() {
			this.setAddStaffModalState(false)
		}
	},
	computed: {
		...mapGetters(["getAddStaffModalState"])
	}
}
</script>
