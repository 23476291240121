import { v4 as uuid } from "uuid";

const state = {
  sidebarLinks: [],

  rootLinks: [
    {
      title: "Site case preview",
      links: [
        {
          id: uuid(),
          name: "Motion Ridge",
          url: "/motionridge",
          icon: "heroicons-solid:chart-pie",
          isPersonal: "true",
        },
        {
          id: uuid(),
          name: "Graphic Ridge",
          url: "/graphicridge",
          icon: "heroicons-solid:chart-pie",
        },
      ],
    },
    {
      title: "Dashboard",
      links: [
        {
          id: uuid(),
          name: "HR Management",
          url: "/hr-management",
          icon: "heroicons-solid:users",
        },
        {
          id: uuid(),
          name: "Site profit",
          url: "/site-profit",
          icon: "heroicons-solid:presentation-chart-bar",
        },
      ],
    },
    {
      title: "Manage User",
      links: [
        {
          id: uuid(),
          name: "Site Users",
          url: "/user",
          icon: "heroicons-solid:user",
        },
        /*  */
      ],
    },
    {
      title: "Manage Affiliates",
      links: [
        {
          id: uuid(),
          name: "Site Affiliates",
          url: "/affiliates",
          icon: "heroicons-solid:user",
        },
        {
          id: uuid(),
          name: "Affiliate Payouts",
          url: "/affiliates-payout",
          icon: "fluent:payment-24-filled",
        },
        /*  */
      ],
    },
    {
      title: "Management",
      links: [
        /* {
					id: uuid(),
					name: "Contributor",
					url: "/contributor",
					icon: "heroicons-solid:user-add"
				}, */
        {
          id: uuid(),
          name: "Staff",
          url: "/staff",
          icon: "heroicons-solid:user-group",
        },
        {
          id: uuid(),
          name: "Staff Payout",
          url: "/staff-payout",
          icon: "fluent:payment-24-filled",
        },
      ],
    },
    {
      title: "Application",
      links: [
        {
          id: uuid(),
          name: "messages",
          url: "/messages",
          icon: "ant-design:message-filled",
        },
        {
          id: uuid(),
          name: "File manager",
          url: "/file-manager",
          icon: "heroicons-solid:folder-open",
        },
      ],
    },
    {
      title: "Misc pages",
      links: [
        {
          id: uuid(),
          name: "privacy",
          url: "/privacy",
          icon: "dashicons:privacy",
        },
        {
          id: uuid(),
          name: "sitemap",
          url: "/sitemap",
          icon: "fa-solid:map",
        },
        {
          id: uuid(),
          name: "contact",
          url: "/contact",
          icon: "carbon:phone-filled",
        },
      ],
    },
  ],

  motionridgeLinks: [
    {
      title: "Dashboard",
      links: [
        {
          id: uuid(),
          name: "Default",
          url: "/motionridge/default",
          icon: "heroicons-solid:chart-pie",
        },
        {
          id: uuid(),
          name: "Sales Monitoring",
          url: "/motionridge/sales-monitoring",
          icon: "heroicons-solid:presentation-chart-bar",
        },
        {
          id: uuid(),
          name: "Contributor monitoring",
          url: "/motionridge/contributor-monitoring",
          icon: "fluent:chart-person-20-filled",
        },
      ],
    },
    {
      title: "Order & Invoice",
      links: [
        {
          id: uuid(),
          name: "Subscribers",
          url: "/motionridge/subscribers",
          icon: "clarity:assign-user-solid",
        },
        {
          id: uuid(),
          name: "Downloaded items",
          url: "/motionridge/downloads",
          icon: "heroicons-outline:download",
        },
        {
          id: uuid(),
          name: "Payment History",
          url: "/motionridge/payment-history",
          icon: "mdi:history",
        },
      ],
    },
    {
      title: "Contributor",
      links: [
        {
          id: uuid(),
          name: "Request List",
          url: "/motionridge/request-list",
          icon: "fa-solid:clipboard-list",
        },
        {
          id: uuid(),
          name: "Author List",
          url: "/motionridge/author-list",
          icon: "heroicons-solid:pencil-alt",
        },
        {
          id: uuid(),
          name: "Author payouts",
          url: "/motionridge/author-payouts",
          icon: "fluent:payment-24-filled",
        },
        {
          id: uuid(),
          name: "Author earnings",
          url: "/motionridge/author-earnings",
          icon: "fluent:payment-24-filled",
        },
      ],
    },
    {
      title: "All items",
      links: [
        {
          id: uuid(),
          name: "Items Request",
          url: "/motionridge/items-request",
          icon: "clarity:list-solid",
        },
        {
          id: uuid(),
          name: "Items List",
          url: "/motionridge/items-list",
          icon: "heroicons-solid:pencil-alt",
        },
      ],
    },
    {
      title: "Application",
      links: [
        {
          id: uuid(),
          name: "Email",
          url: "/motionridge/email",
          icon: "dashicons:email-alt",
        },
        {
          id: uuid(),
          name: "Email template",
          url: "/motionridge/email-template",
          icon: "dashicons:email-alt",
        },
      ],
    },
    {
      title: "Misc pages",
      links: [
        {
          id: uuid(),
          name: "About",
          url: "/motionridge/about",
          icon: "ant-design:info-circle-filled",
        },
        {
          id: uuid(),
          name: "Lisence",
          url: "/motionridge/license",
          icon: "fa:drivers-license",
        },
        {
          id: uuid(),
          name: "Terms & Conditions",
          url: "/motionridge/ters-conditions",
          icon: "heroicons-solid:folder-open",
        },
      ],
    },
    {
      title: "return back",
      links: [
        {
          id: uuid(),
          name: "Main",
          url: "/",
          icon: "heroicons-solid:folder-open",
        },
      ],
    },
  ],

  graphicridgeLinks: [
    {
      title: "Dashboard graphic ridge",
      links: [
        {
          id: uuid(),
          name: "Default",
          url: "/motionridge/default",
          icon: "heroicons-solid:folder-open",
        },
        {
          id: uuid(),
          name: "Sales Monitoring",
          url: "/motionridge/sales-monitoring",
          icon: "heroicons-solid:folder-open",
        },
        {
          id: uuid(),
          name: "Contributor monitoring",
          url: "/motionridge/contributor-monitoring",
          icon: "heroicons-solid:folder-open",
        },
      ],
    },
  ],
};

const getters = {
  getSideBarLinks: (state) => state.sidebarLinks,
};

const actions = {
  changeLinks({ commit, state }, type) {
    if (type === "motionridge") {
      commit("setLinks", state.motionridgeLinks);
    } else if (type === "graphicridge") {
      commit("setLinks", state.graphicridgeLinks);
    } else {
      commit("setLinks", state.rootLinks);
    }
  },
};

const mutations = {
  setLinks: (state, val) => (state.sidebarLinks = val),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
