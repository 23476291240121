import axios from "axios"
import { v4 as uuid } from "uuid"

const state = {
	users: [],
	userInformation: null,
	userLoading: false,
	userFilterLoading: false,
	userPages: 0,
	numberOfUsers: 0,

	userListSize: [
		{
			id: uuid(),
			name: 25,
			unavailable: false
		},
		{
			id: uuid(),
			name: 50,
			unavailable: false
		},
		{
			id: uuid(),
			name: 100,
			unavailable: false
		}
	]
}

const getters = {
	getNumberOfUsers: (state) => state.numberOfUsers,
	getUserLoading: (state) => state.userLoading,
	getUserFilterLoading: (state) => state.userFilterLoading,
	getUsers: (state) => state.users,
	getUserInformation: (state) => state.userInformation,
	getUserPages: (state) => state.userPages,
	getUserListSize: (state) => state.userListSize
}

const actions = {
	async getAllUsers({ commit }, params) {
		try {
			commit("setUserLoading", true)
			const { data } = await axios.get(
				`${process.env.VUE_APP_BASE_URl}/user/users/${params.size}/${params.page}`
			)
			commit("setAllUsers", data.rows)
			commit("setNumberOfUsers", data.count)
			commit("setUserPages", data.pages)
			commit("setUserLoading", false)
		} catch (error) {
			commit("setUserLoading", false)
			console.log(error)
		}
	},

	/* get user info by id */
	async getUserInformationById({ commit }, id) {
		try {
			commit("setUserLoading", true)
			const {
				data
			} = await axios.post(`${process.env.VUE_APP_BASE_URl}/user/getuser`, { id })

			console.log(data)
			commit("setUserLoading", false)
			commit("setUserInformation", data)
		} catch (error) {
			commit("setUserLoading", false)
			console.log(error)
		}
	},

	/* filter users according to status */
	async filterUserStatus({ commit }, params) {
		if (params.type !== "none") {
			try {
				commit("setUserFilterLoading", true)
				const {
					data
				} = await axios.post(
					`${process.env.VUE_APP_BASE_URl}/user/searchfilter/${params.size}/${params.page}`,
					{ type: params.type }
				)

				commit("setAllUsers", data.data.rows)
				commit("setUserPages", data.pages)
				commit("setUserFilterLoading", false)
			} catch (error) {
				commit("setUserFilterLoading", false)
				console.log(error)
			}
		}
	},

	/* filter user according to user name */
	async filterUser({ commit }, params) {
		if (params.query.length > 0) {
			try {
				commit("setUserFilterLoading", true)
				const {
					data
				} = await axios.post(
					`${process.env.VUE_APP_BASE_URl}/user/filter/${params.size}/${params.page}`,
					{ query: params.query }
				)
				commit("setAllUsers", data.data.rows)
				commit("setUserPages", data.pages)
				commit("setUserFilterLoading", false)
			} catch (error) {
				commit("setUserFilterLoading", false)
				console.log(error)
			}
		}
	}
}

const mutations = {
	setUserLoading: (state, val) => (state.userLoading = val),
	setUserFilterLoading: (state, val) => (state.userFilterLoading = val),
	setNumberOfUsers: (state, val) => (state.numberOfUsers = val),
	setAllUsers: (state, val) => (state.users = val),
	setUserPages: (state, val) => (state.userPages = val),
	setUserInformation: (state, val) => (state.userInformation = val)
}

export default {
	state,
	getters,
	actions,
	mutations
}
