import { v4 as uuid } from "uuid"

const state = {
	isAddStaffModalOpen: false
}

const getters = {
	getAddStaffModalState: (state) => state.isAddStaffModalOpen
}

const actions = {
	setAddStaffModalState({ commit }, val) {
		commit("setAddStaffModalState", val)
	}
}

const mutations = {
	setAddStaffModalState: (state, val) => (state.isAddStaffModalOpen = val)
}

export default {
	state,
	getters,
	actions,
	mutations
}
