<template>
	<div class="px-8 pt-6">
		<h1 class="text-gray-900 dark:text-gray-200 font-bold text-base md:text-lg">
			Personal Information
		</h1>
	</div>

	<!-- information -->
	<div
		class="px-8 py-8 w-full max-w-4xl grid grid-cols-1 lg:grid-cols-2 gap-x-32 gap-y-5"
	>
		<div class="py-3 space-x-16 flex flex-grow-0 items-center justify-between">
			<h1 class="text-gray-500 dark:text-gray-400 text-sm whitespace-nowrap">
				First Name
			</h1>
			<h1 class="text-gray-900 dark:text-gray-100 text-sm whitespace-nowrap">
				{{ staffInfo.firstName }}
			</h1>
		</div>

		<div class="py-3 space-x-16 flex flex-grow-0 items-center justify-between">
			<h1 class="text-gray-400  dark:text-gray-400 text-sm whitespace-nowrap">
				Last Name
			</h1>
			<h1 class="text-gray-900  dark:text-gray-100 text-sm whitespace-nowrap">
				{{ staffInfo.lastName }}
			</h1>
		</div>

		<div class="flex items-center flex-grow-0 justify-between space-x-16">
			<h1 class="text-gray-500  dark:text-gray-400 text-sm whitespace-nowrap">
				Phone number
			</h1>

			<div
				class="flex items-center flex-shrink justify-center space-x-1 transition-spacing duration-300"
			>
				<div class="">
					<input
						type="text"
						@input="handleInput('phone')"
						:disabled="!phone.edit"
						v-model="phone.value"
						class="bg-transparent w-full min-w-min disabled:bg-transparent bg-indigo-50 dark:disabled:bg-transparent dark:bg-darkBlack rounded-md px-2 focus:outline-none py-2 text-sm text-right text-gray-900  dark:text-gray-100"
					/>

					<div
						v-if="phone.error || getStaffErrors.updatePhone"
						class="text-xs text-right text-red-600 dark:text-red-500 px-2"
					>
						{{ phone.error || getStaffErrors.updatePhone }}
					</div>
				</div>

				<!-- cancel -->
				<EffectButton
					v-if="phone.edit"
					@click="handleFormCancel('phone')"
					class="flex-shrink-0"
					:rounded="true"
				>
					<svg
						class="text-gray-500 dark:text-gray-300 w-4 h-4"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fill-rule="evenodd"
							d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
							clip-rule="evenodd"
						/>
					</svg>
				</EffectButton>

				<!-- save -->
				<EffectButton
					@click="handleFormUpdate('phone')"
					class="flex-shrink-0"
					v-if="phone.edit"
					:rounded="true"
				>
					<svg
						class="text-green-700 dark:text-green-500 w-4 h-4"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fill-rule="evenodd"
							d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
							clip-rule="evenodd"
						/>
					</svg>
				</EffectButton>

				<!-- edit -->
				<EffectButton
					@click="phone.edit = true"
					v-if="!phone.edit"
					:rounded="true"
					class="flex-shrink-0"
				>
					<svg
						class="text-blue-700 dark:text-blue-500 w-4 h-4"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
						/>
					</svg>
				</EffectButton>
			</div>
		</div>

		<div class="py-3 space-x-16 flex flex-grow-0 items-center justify-between">
			<h1 class="text-gray-500 dark:text-gray-400 text-sm whitespace-nowrap">
				Date of Birth
			</h1>
			<h1 class="text-gray-900 dark:text-gray-100 text-sm whitespace-nowrap">
				{{ staffInfo.dob.split("T")[0] }}
			</h1>
		</div>

		<div class="py-3 flex items-center justify-between space-x-16 flex-grow-0">
			<h1 class="text-gray-500 dark:text-gray-400 text-sm whitespace-nowrap">
				User name
			</h1>
			<h1 class="text-gray-900 dark:text-gray-100 text-sm whitespace-nowrap">
				username
			</h1>
		</div>

		<div class="flex items-center justify-between space-x-16 flex-grow-0">
			<h1 class="text-gray-500 dark:text-gray-400 text-sm whitespace-nowrap">
				Email
			</h1>

			<div
				class="flex items-center flex-shrink-0 justify-center space-x-1 transition-spacing duration-300"
			>
				<div class="">
					<input
						type="email"
						@input="handleInput('email')"
						:disabled="!email.edit"
						v-model="email.value"
						class="bg-transparent w-full min-w-min disabled:bg-transparent bg-indigo-50 dark:disabled:bg-transparent dark:bg-darkBlack rounded-md px-2 focus:outline-none py-2 text-sm text-right text-gray-900 dark:text-gray-100"
					/>

					<div
						v-if="email.error || getStaffErrors.updateEmail"
						class="text-xs text-right text-red-600 px-2"
					>
						{{ email.error || getStaffErrors.updateEmail }}
					</div>
				</div>

				<!-- cancel -->
				<EffectButton
					v-if="email.edit"
					@click="handleFormCancel('email')"
					:rounded="true"
				>
					<svg
						class="text-gray-500 dark:text-gray-300 w-4 h-4"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fill-rule="evenodd"
							d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
							clip-rule="evenodd"
						/>
					</svg>
				</EffectButton>

				<!-- save -->
				<EffectButton
					@click="handleFormUpdate('email')"
					v-if="email.edit"
					:rounded="true"
				>
					<svg
						class="text-green-700 dark:text-green-500 w-4 h-4"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fill-rule="evenodd"
							d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
							clip-rule="evenodd"
						/>
					</svg>
				</EffectButton>

				<!-- edit -->
				<EffectButton @click="email.edit = true" v-if="!email.edit" :rounded="true">
					<svg
						class="text-blue-700 dark:text-blue-700 w-4 h-4"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
						/>
					</svg>
				</EffectButton>
			</div>
		</div>
	</div>

	<!-- aditional information -->
	<div class="px-6 py-4">
		<h1
			class="uppercase font-bold text-gray-600 dark:text-gray-300 text-xs tracking-widest"
		>
			Additional information
		</h1>
	</div>
	<div
		class="px-6 py-4 w-full max-w-4xl grid grid-cols-1 lg:grid-cols-2 gap-x-32 gap-y-5"
	>
		<div class="py-3 space-x-16 flex flex-grow-0 items-center justify-between">
			<h1 class="text-gray-500 dark:text-gray-400 text-sm whitespace-nowrap">
				Joining Date
			</h1>
			<h1 class="text-gray-900 dark:text-gray-100 text-sm whitespace-nowrap">
				{{ staffInfo.createdAt.split("T")[0] }}
			</h1>
		</div>

		<div class="py-3 space-x-16 flex flex-grow-0 items-center justify-between">
			<h1 class="text-gray-500 dark:text-gray-400 text-sm whitespace-nowrap">
				Country
			</h1>
			<h1 class="text-gray-900 dark:text-gray-100 text-sm whitespace-nowrap">
				{{ staffInfo.country }}
			</h1>
		</div>

		<div class="py-3 space-x-16 flex flex-grow-0 items-center justify-between">
			<h1 class="text-gray-500 dark:text-gray-400 text-sm whitespace-nowrap">
				Province/State
			</h1>
			<h1 class="text-gray-900 dark:text-gray-100 text-sm whitespace-nowrap">
				{{ staffInfo.state }}
			</h1>
		</div>

		<div class="py-3 space-x-16 flex flex-grow-0 items-center justify-between">
			<h1 class="text-gray-500 dark:text-gray-400 text-sm whitespace-nowrap">
				Postal code
			</h1>
			<h1 class="text-gray-900 dark:text-gray-100 text-sm whitespace-nowrap">
				{{ staffInfo.postalCode }}
			</h1>
		</div>

		<div class="py-3 flex items-center justify-between space-x-16 flex-grow-0">
			<h1 class="text-gray-500 dark:text-gray-400 text-sm whitespace-nowrap">
				City
			</h1>
			<h1 class="text-gray-900 dark:text-gray-100 text-sm whitespace-nowrap">
				{{ staffInfo.city }}
			</h1>
		</div>
	</div>

	<!-- job information -->
	<div class="px-6 py-4">
		<h1
			class="uppercase font-bold text-gray-600 dark:text-gray-300 text-xs tracking-widest"
		>
			Job information
		</h1>
	</div>
	<div
		class="px-6 py-4 w-full max-w-4xl grid grid-cols-1 lg:grid-cols-2 gap-x-32 gap-y-5"
	>
		<div class="flex items-center flex-grow-0 justify-between space-x-16">
			<h1 class="text-gray-500 dark:text-gray-400 text-sm whitespace-nowrap">
				Position
			</h1>

			<div
				class="flex items-center flex-shrink justify-center space-x-1 transition-spacing duration-300"
			>
				<div class="">
					<input
						type="text"
						@input="handleInput('position')"
						:disabled="!position.edit"
						v-model="position.value"
						class="bg-transparent w-full min-w-min disabled:bg-transparent bg-indigo-50 dark:bg-darkBlack dark:disabled:bg-transparent rounded-md px-2 focus:outline-none py-2 text-sm text-right text-gray-900 dark:text-gray-100"
					/>

					<div
						v-if="position.error || getStaffErrors.updatePosition"
						class="text-xs text-right text-red-600 dark:text-red-500 px-2"
					>
						{{ position.error || getStaffErrors.updatePosition }}
					</div>
				</div>

				<!-- cancel -->
				<EffectButton
					v-if="position.edit"
					@click="handleFormCancel('position')"
					class="flex-shrink-0"
					:rounded="true"
				>
					<svg
						class="text-gray-500 dark:text-gray-300 w-4 h-4"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fill-rule="evenodd"
							d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
							clip-rule="evenodd"
						/>
					</svg>
				</EffectButton>

				<!-- save -->
				<EffectButton
					@click="handleFormUpdate('position')"
					class="flex-shrink-0"
					v-if="position.edit"
					:rounded="true"
				>
					<svg
						class="text-green-700 dark:text-green-500 w-4 h-4"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fill-rule="evenodd"
							d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
							clip-rule="evenodd"
						/>
					</svg>
				</EffectButton>

				<!-- edit -->
				<EffectButton
					@click="position.edit = true"
					v-if="!position.edit"
					:rounded="true"
					class="flex-shrink-0"
				>
					<svg
						class="text-blue-700 dark:text-blue-500 w-4 h-4"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
						/>
					</svg>
				</EffectButton>
			</div>
		</div>

		<div class="flex items-center justify-between space-x-16 flex-grow-0">
			<h1 class="text-gray-500 dark:text-gray-400 text-sm whitespace-nowrap">
				Salary
			</h1>

			<div
				class="flex items-center flex-shrink-0 justify-center space-x-1 transition-spacing duration-300"
			>
				<div>
					<input
						type="text"
						:disabled="!salary.edit"
						v-model="salary.value"
						class="bg-transparent w-full min-w-min disabled:bg-transparent bg-indigo-50 dark:disabled:bg-transparent dark:bg-darkBlack rounded-md px-2 focus:outline-none py-2 text-sm text-right text-gray-900 dark:text-gray-100"
					/>

					<div
						v-if="salary.error"
						class="text-xs text-right text-red-600 dark:text-red-500 px-2"
					>
						{{ salary.error }}
					</div>
				</div>

				<!-- cancel -->
				<EffectButton
					v-if="salary.edit"
					@click="handleFormCancel('salary')"
					:rounded="true"
				>
					<svg
						class="text-gray-500 dark:text-gray-300 w-4 h-4"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fill-rule="evenodd"
							d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
							clip-rule="evenodd"
						/>
					</svg>
				</EffectButton>

				<!-- save -->
				<EffectButton
					@click="handleFormUpdate('salary')"
					v-if="salary.edit"
					:rounded="true"
				>
					<svg
						class="text-green-700 dark:text-green-500 w-4 h-4"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fill-rule="evenodd"
							d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
							clip-rule="evenodd"
						/>
					</svg>
				</EffectButton>

				<!-- edit -->
				<EffectButton
					@click="salary.edit = true"
					v-if="!salary.edit"
					:rounded="true"
				>
					<svg
						class="text-blue-700 dark:text-blue-500 w-4 h-4"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
						/>
					</svg>
				</EffectButton>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import EffectButton from "../components/utils/EffectButton.vue"
export default {
	name: "Personal",
	components: { EffectButton },
	props: ["staffInfo"],

	computed: {
		...mapGetters(["getStaffErrors"])
	},

	methods: {
		...mapActions(["updateEmail"]),
		...mapActions(["updateSalary"]),
		...mapActions(["clearStaffErrors"]),
		...mapActions(["updatePhone"]),
		...mapActions(["updatePosition"]),
		...mapActions(["getStaffInformationById"]),

		handleFormUpdate(field) {
			if (field === "email") {
				const regExEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
				if (this.email.value.length <= 0) {
					this.email.error = "email is required"
				} else if (!regExEmail.exec(this.email.value)) {
					this.email.error = "Invalid email"
				} else {
					this.email.error = null
					this.email.edit = false
					this.updateEmail({ id: this.staffInfo.id, email: this.email.value })
				}
			}

			if (field === "salary") {
				const regExSalary = /^[0-9]*$/i
				if (this.salary.value.length <= 0) {
					this.salary.error = "salary is required"
				} else if (!regExSalary.exec(this.salary.value)) {
					this.salary.error = "This doesnt look like salary"
				} else {
					this.salary.error = null
					this.salary.edit = false
					this.updateSalary({ id: this.staffInfo.id, salary: this.salary.value })
				}
			}

			if (field === "phone") {
				const regExPhone = /^[0-9+]*$/i
				if (this.phone.value.length <= 0) {
					this.phone.error = "Phone number is required"
				} else if (!regExPhone.exec(this.phone.value)) {
					this.phone.error = "This doesnt look like phone number"
				} else {
					this.phone.error = null
					this.phone.edit = false
					this.updatePhone({ id: this.staffInfo.id, phone: this.phone.value })
				}
			}

			if (field === "position") {
				if (this.position.value.length <= 0) {
					this.position.error = "Position is required"
				} else {
					this.position.error = null
					this.position.edit = false
					this.updatePosition({
						id: this.staffInfo.id,
						position: this.position.value
					})
				}
			}
		},

		handleInput(field) {
			this.clearStaffErrors()
			this[field].error = null
		},

		handleFormCancel(field) {
			this[field].edit = false
			this[field].error = null
			this.clearStaffErrors()
			this[field].value = this[field].beforeEdit
		}
	},

	mounted() {
		this.email.value = this.staffInfo.email
		this.salary.value = this.staffInfo.salary
		this.phone.value = this.staffInfo.phone
		this.position.value = this.staffInfo.position
		this.email.beforeEdit = this.staffInfo.email
		this.salary.beforeEdit = this.staffInfo.salary
		this.phone.beforeEdit = this.staffInfo.phone
		this.position.beforeEdit = this.staffInfo.position
	},

	data() {
		return {
			email: {
				value: "",
				beforeEdit: "",
				edit: false,
				error: null
			},
			salary: {
				value: "",
				beforeEdit: "",
				edit: false,
				error: null
			},
			phone: {
				value: "",
				beforeEdit: "",
				edit: false,
				error: null
			},
			position: {
				value: "",
				beforeEdit: "",
				edit: false,
				error: null
			}
		}
	}
}
</script>
