<template>
	<main :class="[darkMode ? 'dark' : '']">
		<!-- alert -->
		<div class="fixed w-full mx-auto z-50 inset-0 pointer-events-none">
			<div class="absolute m-8 mb-20 bottom-0 right-0 z-50 pointer-events-none">
				<transition-group
					tag="div"
					class="flex flex-col items-end justify-start gap-5 pointer-events-auto"
					:enter-active-class="
						getAlert.length > 1
							? 'transition transform delay-100 duration-300 ease-in'
							: 'transition transform duration-300 ease-in'
					"
					enter-from-class="translate-x-full opacity-0"
					enter-to-class="translate-x-0 opacity-100"
					leave-active-class="transition transform duration-300 ease-out"
					leave-from-class="translate-x-0 opacity-100"
					leave-to-class="translate-x-full opacity-0"
					move-class="transition duration-500"
				>
					<Alert
						v-for="alert in getAlert"
						:key="alert.id"
						:id="alert.id"
						:icon="alert.icon"
						:title="alert.title"
						:sub="alert.sub"
						:type="alert.type"
						:button="alert.button"
					/>
				</transition-group>
			</div>
		</div>

		<!-- modals -->
		<div id="modal"></div>
		<!-- modals -->
		<div
			@click="isMobileNav = !isMobileNav"
			class="md:hidden bg-black opacity-20 z-40 inset-0 fixed transition-opacity"
			:class="isMobileNav ? 'block ease-in' : 'hidden ease-out'"
		></div>
		<MobileNav
			v-if="$route.name !== 'login'"
			class="md:hidden transform lg:translate-x-0 lg:static lg:inset-auto"
			:class="
				isMobileNav
					? 'translate-x-0 ease-out transition-transform'
					: '-translate-x-full ease-in transition-transform'
			"
		/>
		<Sidebar
			v-if="$route.name !== 'login'"
			v-on:toggled="sidebarToggled = !sidebarToggled"
		/>
		<main
			class="font-def bg-gray-200 dark:bg-darkBackground w-full min-h-screen overflow-x-hidden"
		>
			<header
				v-if="$route.name !== 'login'"
				class="flex-1 min-w-full fixed z-30 inset-y-0 h-16 px-3 md:px-6 bg-white dark:bg-darkForeground flex items-center border-b border-gray-300 dark:border-gray-700 shadow-xs"
			>
				<!-- mobile nav toggler-->
				<EffectButton :rounded="true" @click="isMobileNav = !isMobileNav">
					<svg
						class="md:hidden w-6 h-6 text-gray-600 transform rotate-180"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M4 6h16M4 12h16m-7 6h7"
						/>
					</svg>
				</EffectButton>

				<div
					:class="sidebarToggled ? 'md:ml-64' : 'md:ml-10'"
					class="flex flex-1 items-center justify-start transition-all duration-500"
				>
					<!-- search icon -->
					<svg class="w-5 h-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
						<path
							fill-rule="evenodd"
							d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
							clip-rule="evenodd"
						/>
					</svg>

					<!-- search input-->
					<input
						type="text"
						placeholder="Search anything"
						class="focus:outline-none w-20 md:w-full flex-shrink md:px-3 md:py-4 text-xs flex-1 dark:bg-darkForeground text-gray-800 dark:text-white placeholder-gray-300"
					/>

					<!-- notification -->
					<Menu
						as="div"
						class="relative focus:outline-none outline-none"
						v-slot="{ open }"
					>
						<MenuButton class="focus:outline-none outline-none rounded-full">
							<EffectButton class="flex-shrink-0" :rounded="true" :active="open">
								<div class="relative">
									<span class="absolute top-0 right-0 flex h-3 w-3">
										<span
											class="animate-ping absolute inline-flex h-full w-full rounded-full bg-brandColorLight opacity-75"
										></span>
										<span
											class="relative inline-flex rounded-full h-3 w-3 bg-brandColor"
										></span>
									</span>
									<svg
										class="w-6 h-6 text-gray-800 dark:text-gray-300"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
										/>
									</svg>
								</div>
							</EffectButton>
						</MenuButton>
						<Transition
							enter-active-class="transition transform duration-100 ease-in"
							enter-from-class="opacity-0 scale-95"
							enter-to-class="opacity-100 scale-100"
							leave-active-class="transition transform duration-500 ease-out"
							leave-from-class="opacity-100 scale-100"
							leave-to-class="opacity-0 scale-95"
						>
							<MenuItems
								class=" absolute w-64 md:w-96 mt-12 top-0 right-0 focus:outline-none outline-none rounded-md shadow-lg bg-white  dark:bg-darkLight overflow-hidden"
							>
								<div
									class="h-16 px-6 py-3 flex items-center justify-between bg-gray-100 dark:bg-darkLight border-b border-gray-300 dark:border-darkBackground"
								>
									<h1 class="text-xs text-gray-800 dark:text-gray-300">
										Notifications
									</h1>
									<button class="focus:outline-none text-indigo-600 text-xs">
										Mark all as Read
									</button>
								</div>

								<!-- notification -->
								<div class="max-h-80 overflow-y-auto">
									<div
										class="px-6 py-5 bg-gray-50 dark:bg-darkLight flex items-center justify-start space-x-4 border-b border-gray-300 dark:border-darkBackground"
									>
										<div class="p-2 rounded-full bg-green-100 text-green-900">
											<svg
												class="w-5 h-5"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
												/>
											</svg>
										</div>
										<div class="flex flex-col text-sm space-y-1">
											<h1
												class="text-gray-800 truncate w-56 dark:text-white md:w-64"
											>
												Mayuran subscribed yearly
											</h1>
											<h3 class="text-xs text-gray-500 dark:text-gray-400">
												2hrs ago
											</h3>
										</div>
									</div>
									<div
										class="px-6 py-5 bg-gray-50 dark:bg-darkLight flex items-center justify-start space-x-4 border-b border-gray-300 dark:border-darkBackground"
									>
										<div class="p-2 rounded-full bg-green-100 text-green-900">
											<svg
												class="w-5 h-5"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
												/>
											</svg>
										</div>
										<div class="flex flex-col text-sm space-y-1">
											<h1
												class="text-gray-800 truncate w-56 dark:text-white md:w-64"
											>
												Mayuran subscribed yearly
											</h1>
											<h3 class="text-xs text-gray-500 dark:text-gray-400">
												2hrs ago
											</h3>
										</div>
									</div>
									<div
										class="px-6 py-5 bg-gray-50 dark:bg-darkLight flex items-center justify-start space-x-4 border-b border-gray-300 dark:border-darkBackground"
									>
										<div class="p-2 rounded-full bg-green-100 text-green-900">
											<svg
												class="w-5 h-5"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
												/>
											</svg>
										</div>
										<div class="flex flex-col text-sm space-y-1">
											<h1
												class="text-gray-800 truncate w-56 dark:text-white md:w-64"
											>
												Mayuran subscribed yearly
											</h1>
											<h3 class="text-xs text-gray-500 dark:text-gray-400">
												2hrs ago
											</h3>
										</div>
									</div>
									<div
										class="px-6 py-5 bg-gray-50 dark:bg-darkLight flex items-center justify-start space-x-4 border-b border-gray-300 dark:border-darkBackground"
									>
										<div class="p-2 rounded-full bg-green-100 text-green-900">
											<svg
												class="w-5 h-5"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
												/>
											</svg>
										</div>
										<div class="flex flex-col text-sm space-y-1">
											<h1
												class="text-gray-800 truncate w-56 dark:text-white md:w-64"
											>
												Mayuran subscribed yearly
											</h1>
											<h3 class="text-xs text-gray-500 dark:text-gray-400">
												2hrs ago
											</h3>
										</div>
									</div>
									<div
										class="px-6 py-5 bg-gray-50 dark:bg-darkLight flex items-center justify-start space-x-4 border-b border-gray-300 dark:border-darkBackground"
									>
										<div class="p-2 rounded-full bg-green-100 text-green-900">
											<svg
												class="w-5 h-5"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
												/>
											</svg>
										</div>
										<div class="flex flex-col text-sm space-y-1">
											<h1
												class="text-gray-800 truncate w-56 dark:text-white md:w-64"
											>
												Mayuran subscribed yearly
											</h1>
											<h3 class="text-xs text-gray-500 dark:text-gray-400">
												2hrs ago
											</h3>
										</div>
									</div>
									<div
										class="px-6 py-5 bg-gray-50 dark:bg-darkLight flex items-center justify-start space-x-4 border-b border-gray-300 dark:border-darkBackground"
									>
										<div class="p-2 rounded-full bg-green-100 text-green-900">
											<svg
												class="w-5 h-5"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
												/>
											</svg>
										</div>
										<div class="flex flex-col text-sm space-y-1">
											<h1
												class="text-gray-800 truncate w-56 dark:text-white md:w-64"
											>
												Mayuran subscribed yearly
											</h1>
											<h3 class="text-xs text-gray-500 dark:text-gray-400">
												2hrs ago
											</h3>
										</div>
									</div>
									<div
										class="px-6 py-5 bg-gray-50 dark:bg-darkLight flex items-center justify-start space-x-4 border-b border-gray-300 dark:border-darkBackground"
									>
										<div class="p-2 rounded-full bg-green-100 text-green-900">
											<svg
												class="w-5 h-5"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
												/>
											</svg>
										</div>
										<div class="flex flex-col text-sm space-y-1">
											<h1
												class="text-gray-800 truncate w-56 dark:text-white md:w-64"
											>
												Mayuran subscribed yearly
											</h1>
											<h3 class="text-xs text-gray-500 dark:text-gray-400">
												2hrs ago
											</h3>
										</div>
									</div>
									<div
										class="px-6 py-5 bg-gray-50 dark:bg-darkLight flex items-center justify-start space-x-4 border-b border-gray-300 dark:border-darkBackground"
									>
										<div class="p-2 rounded-full bg-green-100 text-green-900">
											<svg
												class="w-5 h-5"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
												/>
											</svg>
										</div>
										<div class="flex flex-col text-sm space-y-1">
											<h1
												class="text-gray-800 truncate w-56 dark:text-white md:w-64"
											>
												Mayuran subscribed yearly
											</h1>
											<h3 class="text-xs text-gray-500 dark:text-gray-400">
												2hrs ago
											</h3>
										</div>
									</div>
								</div>
							</MenuItems>
						</Transition>
					</Menu>

					<!-- avatar -->
					<div class="flex items-center md:space-x-3">
						<div class="flex items-center md:space-x-3">
							<Avatar
								class="md:ml-4 flex-shrink-0"
								img="https://images.unsplash.com/photo-1497316730643-415fac54a2af?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&fit=face&facepad=2.3&w=700&q=80"
							/>
							<div class="hidden md:flex flex-col items-start select-none">
								<span class="text-xs text-gray-500 dark:text-gray-400"
									>Administrator</span
								>
								<h1 class="text-sm font-semibold text-gray-800 dark:text-white">
									Udhay
								</h1>
							</div>
						</div>
						<Menu as="div" class="relative  focus:outline-none outline-none">
							<MenuButton class="focus:outline-none outline-none rounded-full">
								<EffectButton class="md:ml-3 flex-shrink-0" :rounded="true">
									<svg
										class="w-5 h-5 text-gray-600"
										viewBox="0 0 20 20"
										fill="currentColor"
									>
										<path
											fill-rule="evenodd"
											d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
											clip-rule="evenodd"
										/>
									</svg>
								</EffectButton>
							</MenuButton>

							<Transition
								enter-active-class="transition transform duration-100 ease-in"
								enter-from-class="opacity-0 scale-95"
								enter-to-class="opacity-100 scale-100"
								leave-active-class="transition transform duration-500 ease-out"
								leave-from-class="opacity-100 scale-100"
								leave-to-class="opacity-0 scale-95"
							>
								<MenuItems
									as="div"
									class="absolute w-72 mt-12 top-0 right-0 focus:outline-none outline-none rounded-md shadow-lg bg-white  dark:bg-darkLight overflow-hidden"
								>
									<div
										class="bg-gray-100 dark:bg-darkForeground h-24 px-4 py-3 flex items-center justify-between border-b border-gray-300 dark:border-darkBackground select-none"
									>
										<div class="flex items-center space-x-5">
											<Avatar
												:large="true"
												class="flex-shrink-0"
												img="https://images.unsplash.com/photo-1497316730643-415fac54a2af?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&fit=face&facepad=2.3&w=700&q=80"
											/>
											<div class="flex flex-col items-start space-y-1">
												<h1
													class="text-base font-semibold text-gray-800 dark:text-white"
												>
													Udhay
												</h1>
												<span class="text-xs text-gray-500 dark:gray-300"
													>udhaysandh@gmail.com</span
												>
											</div>
										</div>

										<!-- dark mode toggler -->
										<div class="flex flex-col items-center space-y-4">
											<!-- sun -->
											<svg
												class="w-4 h-4"
												:class="darkMode ? 'text-gray-700' : 'text-gray-800'"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
												/>
											</svg>
											<Switch
												as="button"
												v-model="darkMode"
												class="h-4 w-8 inline-flex items-center justify-center relative transform rotate-90 rounded-full focus:outline-none outline-none transition-all duration-300"
												:class="darkMode ? 'bg-gray-600' : 'bg-gray-200'"
												v-slot="{ checked }"
											>
												<span
													class="h-4 w-4 inline-block absolute inset-0 bg-white transform rounded-full transition-all duration-300"
													:class="{
														'translate-x-5': checked,
														'translate-x-0': !checked,
														'bg-darkActive': !darkMode,
														'bg-white': darkMode
													}"
												/>
											</Switch>
											<!-- moon -->
											<svg
												class="w-4 h-4 text-gray-300"
												:class="{ 'text-gray-300': darkMode }"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
												/>
											</svg>
										</div>
									</div>
									<!-- links -->
									<div class="py-3">
										<router-link to="/profie">
											<MenuItem class="w-full" v-slot="{ active }">
												<Sublinks :active="active">
													<span
														><svg
															class="h-5 w-5 mr-3"
															fill="none"
															viewBox="0 0 24 24"
															stroke="currentColor"
														>
															<path
																stroke-linecap="round"
																stroke-linejoin="round"
																stroke-width="2"
																d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
															/></svg
													></span>
													<span>View profile</span>
												</Sublinks>
											</MenuItem>
										</router-link>

										<router-link to="/settings">
											<MenuItem class="w-full" v-slot="{ active }">
												<Sublinks url="#" :active="active">
													<span>
														<svg
															class="w-5 h-5 mr-3"
															fill="none"
															viewBox="0 0 24 24"
															stroke="currentColor"
														>
															<path
																stroke-linecap="round"
																stroke-linejoin="round"
																stroke-width="2"
																d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
															/>
															<path
																stroke-linecap="round"
																stroke-linejoin="round"
																stroke-width="2"
																d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
															/>
														</svg>
													</span>
													<span>Account settings</span>
												</Sublinks>
											</MenuItem>
										</router-link>

										<router-link to="/activity">
											<MenuItem class="w-full" v-slot="{ active }">
												<Sublinks url="#" :active="active">
													<span>
														<svg
															class="h-5 w-5 mr-3"
															fill="none"
															viewBox="0 0 24 24"
															stroke="currentColor"
														>
															<path
																stroke-linecap="round"
																stroke-linejoin="round"
																stroke-width="2"
																d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
															/>
														</svg>
													</span>
													<span>Login Activity</span>
												</Sublinks>
											</MenuItem>
										</router-link>
									</div>
									<!-- sign out -->
									<MenuItem
										class="w-full border-t border-gray-300 dark:border-darkBackground"
									>
										<button
											@click="handleLogout"
											class="focus:outline-none px-6 py-3 text-sm text-gray-700 flex items-start justify-start  w-full"
										>
											<span>
												<svg
													class="h-5 w-5 mr-3"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														stroke-width="2"
														d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
													/>
												</svg>
											</span>
											<span>Sign out</span>
										</button>
									</MenuItem>
								</MenuItems>
							</Transition>
						</Menu>
					</div>
				</div>
			</header>
			<!-- routes -->
			<div
				class="transition-spacing duration-500"
				:class="[sidebarToggled ? 'md:ml-72 mt-16' : 'md:ml-16 mt-16']"
			>
				<router-view :sidebarToggled="sidebarToggled" />
				<footer
					v-if="$route.name !== 'login'"
					class="w-full bg-white border-t border-gray-300 dark:border-gray-700 dark:bg-darkForeground py-6 select-none text-gray-500 flex justify-center items-center text-xs md:text-sm"
				>
					<span>&copy; {{ date }} Exvaxo. All rights reserved</span>
				</footer>
			</div>
		</main>
	</main>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem, Switch } from "@headlessui/vue"
import MobileNav from "./components/sidebar/MobileNav.vue"
import Sidebar from "./components/sidebar/Sidebar.vue"
import Avatar from "./components/utils/Avatar.vue"
import Alert from "./components/utils/Alert.vue"
import EffectButton from "./components/utils/EffectButton.vue"
import Sublinks from "./components/utils/Sublinks.vue"
import { mapActions, mapGetters } from "vuex"
export default {
	name: "App",
	components: {
		Sidebar,
		EffectButton,
		Avatar,
		MobileNav,
		Menu,
		MenuButton,
		MenuItems,
		MenuItem,
		Switch,
		Sublinks,
		Alert
	},
	created() {
		this.checkAdmin()
	},
	methods: {
		...mapActions(["checkAdmin"]),
		...mapActions(["logout"]),

		handleLogout(){
			this.logout()
			this.$router.push('/login')
		}
	},
	computed: {
		...mapGetters(["getAddStaffModalState"]),
		...mapGetters(["getAlert"]),
		...mapGetters(["getAdmin"]),

		date() {
			let d = new Date()
			return d.getFullYear()
		}
	},
	data() {
		return { sidebarToggled: true, isMobileNav: false, darkMode: false }
	},
	watch: {
		getAdmin: function(nv) {
			if (!nv) {
				this.$router.push("/login")
			}
		}
	}
}
</script>
