<template>
  <section class="p-3 md:p-9 min-h-screen overflow-y-auto">
    <!-- header -->
    <div class="flex md:flex-row flex-col justify-between md:items-center ">
      <h1
        class="font-bold text-gray-900 dark:text-gray-300 text-xl md:text-3xl"
      >
        User Information
      </h1>
      <!-- control -->
      <div class="mt-3 md:mt-0  flex items-center justify-between space-x-4">
        <Loader
          v-if="getUserFilterLoading"
          class="flex-shrink-0"
          :smallBrand="true"
        />
        <!-- search -->
        <div
          class="px-3 py-2 w-full flex items-center justify-between bg-white dark:bg-darkLight border-gray-300 border dark:border-darkForeground rounded shadow"
        >
          <input
            @input="handleFilter"
            v-model="searchQuery"
            type="text"
            placeholder="Search by user name"
            class="w-full h-full flex-shrink focus:outline-none text-xs bg-transparent dark:placeholder-gray-300 placeholder-gray-400 text-gray-800 dark:text-white"
          />
          <svg
            class="w-4 h-4 flex-shrink-0 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <!-- end of search -->

        <!-- status filter -->
        <Menu as="div" class="relative" v-slot="{ open }">
          <MenuButton
            :class="{
              'bg-gray-400 dark:bg-darkActive text-white': open,
              'text-gray-800  dark:text-gray-300': !open,
            }"
            class="px-3 py-2 text-xs font-medium bg-gray-300 dark:bg-darkLight hover:bg-gray-400 hover:text-white dark:hover:bg-darkActive rounded-md flex items-center justify-between focus:outline-none transition-all duration-300 outline-none"
          >
            <span>{{ statusFilter === "none" ? "Status" : statusFilter }}</span>
            <svg class="h-4 w-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </MenuButton>

          <Transition
            enter-active-class="transition transform duration-100 ease-in"
            enter-from-class="opacity-0 scale-95"
            enter-to-class="opacity-100 scale-100"
            leave-active-class="transition transform duration-300 ease-out"
            leave-from-class="opacity-100 scale-100"
            leave-to-class="opacity-0 scale-95"
          >
            <MenuItems
              class="absolute z-50 py-2 w-36 mt-12 top-0 right-0 focus:outline-none outline-none rounded-md shadow-lg bg-white  dark:bg-darkLight overflow-hidden"
            >
              <MenuItem @click="handleStatusFilter('none')" v-slot="{ active }">
                <div
                  :class="[
                    active
                      ? 'bg-gray-100 dark:bg-darkActive dark:text-white text-gray-400'
                      : 'bg-white text-gray-800 dark:text-gray-500  dark:bg-darkLight',
                  ]"
                  class="px-3 py-2 cursor-pointer text-xs focus:outline-none outline-none"
                >
                  None
                </div>
              </MenuItem>
              <MenuItem
                @click="handleStatusFilter('Active')"
                v-slot="{ active }"
              >
                <div
                  :class="[
                    active
                      ? 'bg-gray-100 dark:bg-darkActive text-green-500'
                      : 'bg-white text-gray-800 dark:text-gray-500  dark:bg-darkLight',
                  ]"
                  class="px-3 py-2 cursor-pointer text-xs focus:outline-none outline-none"
                >
                  Active
                </div>
              </MenuItem>
              <MenuItem
                @click="handleStatusFilter('Pending')"
                v-slot="{ active }"
              >
                <div
                  :class="[
                    active
                      ? 'bg-gray-100 dark:bg-darkActive text-yellow-500'
                      : 'bg-white text-gray-800 dark:text-gray-500  dark:bg-darkLight',
                  ]"
                  class="px-3 py-2 cursor-pointer text-xs focus:outline-none outline-none"
                >
                  Pending
                </div>
              </MenuItem>
              <MenuItem
                @click="handleStatusFilter('Suspended')"
                v-slot="{ active }"
              >
                <div
                  :class="[
                    active
                      ? 'bg-gray-100 dark:bg-darkActive text-red-500'
                      : 'bg-white text-gray-800 dark:text-gray-500  dark:bg-darkLight',
                  ]"
                  class="px-3 py-2 cursor-pointer text-xs focus:outline-none outline-none"
                >
                  Suspended
                </div>
              </MenuItem>
            </MenuItems>
          </Transition>
        </Menu>
        <!-- end of status filter -->
      </div>
      <!-- end of control -->
    </div>
    <!-- end of header -->

    <!-- table -->
    <main class="mt-8 min-h-table overflow-y-hidden overflow-x-auto">
      <header
        class="min-w-table h-12 px-4 w-full flex-shrink-0 flex items-center justify-center bg-gray-100 dark:bg-darkWhite rounded-md border border-gray-300 dark:border-darkForeground shadow"
      >
        <CustomCheckbox>
          <input
            type="checkbox"
            class="absolute inset-y-0 my-auto mx-auto z-10 appearance-none w-5 h-5 checked:bg-brandColorDark dark:checked:bg-brandColorDark checked:border-brandColor bg-white dark:bg-gray-500 rounded border-2 dark:border-darkLight border-gray-300 focus:outline-none cursor-pointer transition-colors duration-300 outline-none overflow-hidden"
          />
        </CustomCheckbox>

        <section
          class="ml-4 grid grid-cols-6 w-full items-center place-content-center select-none"
        >
          <div
            class="col-span-2 uppercase text-xs font-semibold text-gray-500 dark:text-gray-300"
          >
            user ({{ getNumberOfUsers }})
          </div>

          <div
            class="uppercase text-xs font-semibold text-gray-500 dark:text-gray-300"
          >
            user name
          </div>

          <div
            class="uppercase text-xs font-semibold text-gray-500 dark:text-gray-300"
          >
            membership
          </div>
          <div
            class="uppercase text-xs font-semibold text-gray-500 dark:text-gray-300"
          >
            status
          </div>
          <!-- action button -->
          <div class="ml-auto mt-1">
            <Menu
              as="div"
              class="relative focus:outline-none outline-none"
              v-slot="{ open }"
            >
              <MenuButton class="focus:outline-none outline-none">
                <EffectButton
                  :rounded="true"
                  :active="open"
                  class="focus:outline-none outline-none"
                >
                  <svg
                    class="w-5 h-5 text-gray-700 dark:text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                    />
                  </svg>
                </EffectButton>
              </MenuButton>
              <Transition
                enter-active-class="transition transform duration-100 ease-in"
                enter-from-class="opacity-0 scale-95"
                enter-to-class="opacity-100 scale-100"
                leave-active-class="transition transform duration-300 ease-out"
                leave-from-class="opacity-100 scale-100"
                leave-to-class="opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute z-50 py-2 w-36 mt-12 top-0 right-0 focus:outline-none outline-none rounded-md shadow-lg bg-white  dark:bg-darkLight overflow-hidden border border-gray-300 dark:border-darkBackground"
                >
                  <MenuItem v-slot="{ active }">
                    <div
                      :class="[
                        active
                          ? 'bg-gray-100 dark:bg-darkActive dark:text-white text-gray-800'
                          : 'bg-white text-gray-500 dark:text-gray-500  dark:bg-darkLight ',
                      ]"
                      class="px-3 py-2 cursor-pointer text-xs flex items-center justify-start space-x-2 focus:outline-none"
                    >
                      <svg
                        class="w-4 h-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                      <span>Send email</span>
                    </div>
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
          </div>
        </section>
      </header>

      <div
        v-if="getUsers.length === 0"
        class="h-72 w-full flex items-center justify-center"
      >
        <h1 v-if="!getUserLoading" class="text-xs text-gray-500">
          No Users to show
        </h1>
      </div>

      <Transition
        enter-active-class="transition transform duration-100 ease-in"
        enter-from-class="opacity-50"
        enter-to-class="opacity-100"
        leave-active-class="transition transform duration-300 ease-out"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="getUserLoading"
          :class="[sidebarToggled ? 'md:ml-72 mt-16' : 'md:ml-16 mt-16']"
          class="fixed z-30 inset-0 flex items-center justify-center transition duration-300"
        >
          <Loader :large="true" :brand="true" />
        </div>
      </Transition>

      <main
        class="mt-4 max-h-table min-h-table min-w-table overflow-y-auto overflow-x-hidden flex flex-col space-y-4"
      >
        <section
          v-for="user in getUsers"
          :key="user.id"
          class="min-w-table px-4 h-26 py-3 w-full flex items-center justify-center bg-white dark:bg-darkWhite rounded-md hover:shadow-md cursor-pointer"
        >
          <CustomCheckbox>
            <input
              type="checkbox"
              class="absolute inset-y-0 my-auto mx-auto z-10 appearance-none w-5 h-5 checked:bg-brandColorDark dark:checked:bg-brandColorDark checked:border-brandColor bg-white dark:bg-gray-500 rounded border-2 dark:border-darkLight border-gray-300 focus:outline-none cursor-pointer transition-all duration-300 outline-none overflow-hidden"
            />
          </CustomCheckbox>

          <section
            class="ml-4 grid grid-cols-6 w-full items-center place-content-center select-none"
          >
            <div
              class="col-span-2 py-1 font-semibold text-gray-500 dark:text-white"
            >
              <div class="flex items-center space-x-4">
                <Avatar
                  class="flex-shrink-0 overflow-hidden"
                  :img="user.profile"
                />
                <div class="">
                  <span
                    v-if="user.isCanceled"
                    class="p-1 bg-red-100 text-xxs font-medium text-red-800 rounded-full"
                    >canceled</span
                  >
                  <h1
                    class="text-sm w-56 truncate text-gray-800 dark:text-white"
                  >
                    {{ user.firstName }} {{ user.lastName }}
                  </h1>
                  <h3
                    class="text-xs w-56 truncate text-gray-500 dark:text-gray-400"
                  >
                    {{ user.email }}
                  </h3>
                </div>
              </div>
            </div>
            <div class="text-xs w-36 truncate  text-gray-500 dark:text-white">
              {{ user.userName }}
            </div>

            <div class="text-xs w-36 truncate  text-gray-500 dark:text-white">
              {{ user.subscription }}
            </div>
            <div class="text-xs font-semibold text-gray-500 dark:text-white">
              <Badge :type="user.status" />
            </div>
            <!-- action button -->
            <div class="ml-auto">
              <Menu
                as="div"
                class="relative focus:outline-none outline-none"
                v-slot="{ open }"
              >
                <MenuButton class=" focus:outline-none outline-none">
                  <EffectButton
                    id="menuBtn"
                    ref="menuBtn"
                    :rounded="true"
                    :active="open"
                    class="focus:outline-none outline-none"
                  >
                    <svg
                      class="w-5 h-5 text-gray-700 dark:text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                      />
                    </svg>
                  </EffectButton>
                </MenuButton>
                <Transition
                  class="absolute z-50"
                  enter-active-class="transition transform duration-100 ease-in"
                  enter-from-class="opacity-0 scale-95"
                  enter-to-class="opacity-100 scale-100"
                  leave-active-class="transition transform duration-300 ease-out"
                  leave-from-class="opacity-100 scale-100"
                  leave-to-class="opacity-0 scale-95"
                >
                  <MenuItems
                    ref="menuItems"
                    id="menuItems"
                    class="absolute z-50 py-2 w-36 mt-12 top-0 right-0 focus:outline-none outline-none rounded-md shadow-lg bg-white  dark:bg-darkLight overflow-hidden border border-gray-300 dark:border-darkBackground"
                  >
                    <router-link :to="`/userprofile/${user.id}/personal`">
                      <MenuItem v-slot="{ active }">
                        <div
                          :class="[
                            active
                              ? 'bg-gray-100 dark:bg-darkActive dark:text-white text-gray-800'
                              : 'bg-white text-gray-500 dark:text-gray-500  dark:bg-darkLight',
                          ]"
                          class="px-3 py-2 cursor-pointer text-xs flex items-center justify-start space-x-2 focus:outline-none"
                        >
                          <svg
                            class="w-4 h-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                          </svg>
                          <span>View details</span>
                        </div>
                      </MenuItem>
                    </router-link>
                    <MenuItem
                      @click="handleEmailCopy(user.email)"
                      v-slot="{ active }"
                    >
                      <div
                        :class="[
                          active
                            ? 'bg-gray-100 dark:bg-darkActive dark:text-white text-gray-800'
                            : 'bg-white text-gray-500 dark:text-gray-500  dark:bg-darkLight',
                        ]"
                        class="px-3 py-2 cursor-pointer text-xs flex items-center justify-start space-x-2 focus:outline-none"
                      >
                        <svg
                          class="w-4 h-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          />
                        </svg>
                        <span>Send email</span>
                      </div>
                    </MenuItem>
                  </MenuItems>
                </Transition>
              </Menu>
            </div>
          </section>
        </section>
      </main>

      <!-- table footer -->
    </main>
    <!-- table footer -->
    <div
      class="mt-4 py-5 px-5 rounded-md bg-white dark:bg-darkWhite flex flex-col md:flex-row items-center justify-between"
    >
      <Pagination :pages="getUserPages" v-on:onClick="currentPage = $event" />

      <div class="flex items-center space-x-6">
        <div class="">
          <Select
            :opts="getUserListSize"
            v-on:onChange="usersPerPage = $event.name"
          />
        </div>
        <h1
          class="mt-4 md:mt-0 text-gray-700 dark:text-gray-300 text-xs md:text-sm"
        >
          page{{ getUserPages === 0 ? 0 : currentPage }} of {{ getUserPages }}
        </h1>
      </div>
    </div>
    <!-- end of table -->
  </section>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import EffectButton from "../components/utils/EffectButton.vue";
import CustomCheckbox from "../components/inputs/CustomCheckbox.vue";
import Avatar from "../components/utils/Avatar.vue";
import Badge from "../components/utils/Badge.vue";
import { mapActions, mapGetters } from "vuex";
import Loader from "../components/utils/Loader.vue";
import Pagination from "../components/utils/Pagination.vue";
import Select from "../components/inputs/Select.vue";

export default {
  name: "User",
  props: ["sidebarToggled"],
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    EffectButton,
    CustomCheckbox,
    Avatar,
    Badge,
    Loader,
    Pagination,
    Select,
  },

  methods: {
    ...mapActions(["getAllUsers"]),
    ...mapActions(["filterUser"]),
    ...mapActions(["filterUserStatus"]),
    ...mapActions(["appendAlert"]),

    handleStatusFilter(type) {
      this.statusFilter = type;
      if (type !== "none") {
        this.filterUserStatus({
          type,
          size: this.getUserListSize[0].name,
          page: 0,
        });
      } else {
        this.getAllUsers({ size: this.getUserListSize[0].name, page: 0 });
      }
    },

    handleFilter(e) {
      this.filterUser({
        query: e.target.value,
        size: this.getUserListSize[0].name,
        page: 0,
      });
    },

    async handleEmailCopy(email) {
      navigator.clipboard
        .writeText(email)
        .then(() => {
          this.appendAlert({
            title: "Email Copied!",
            sub: `${email} has been copied to clipboard`,
            icon: "heroicons-solid:check-circle",
            type: "success",
          });
        })
        .catch(() => console.log("copying email failed"));
    },
  },

  mounted() {
    this.getAllUsers({ size: this.getUserListSize[0].name, page: 0 });
    this.usersPerPage = this.getUserListSize[0].name;
  },

  computed: {
    ...mapGetters(["getUsers"]),
    ...mapGetters(["getUserLoading"]),
    ...mapGetters(["getUserListSize"]),
    ...mapGetters(["getUserPages"]),
    ...mapGetters(["getUserFilterLoading"]),
    ...mapGetters(["getNumberOfUsers"]),
  },

  data() {
    return {
      usersPerPage: 25,
      currentPage: 1,
      searchQuery: "",
      statusFilter: "none",
    };
  },

  watch: {
    usersPerPage: function(nv, ov) {
      if (this.searchQuery.length > 0) {
        this.filterUser({
          query: this.searchQuery,
          size: nv,
          page: this.currentPage - 1,
        });
      } else if (this.statusFilter !== "none") {
        this.filterUserStatus({
          type: this.statusFilter,
          size: nv,
          page: this.currentPage - 1,
        });
      } else {
        this.getAllUsers({ size: nv, page: this.currentPage - 1 });
      }
    },

    searchQuery: function(nv, ov) {
      if (nv.length <= 0) {
        this.getAllUsers({ size: this.getUserListSize[0].name, page: 0 });
      }
    },

    currentPage: function(nv, ov) {
      if (this.searchQuery.length > 0) {
        this.filterUser({
          query: this.searchQuery,
          size: this.usersPerPage,
          page: (nv - 1) * this.usersPerPage,
        });
      } else if (this.statusFilter !== "none") {
        this.filterUserStatus({
          type: this.statusFilter,
          size: this.usersPerPage,
          page: (nv - 1) * this.usersPerPage,
        });
      } else {
        this.getAllUsers({
          size: this.usersPerPage,
          page: (nv - 1) * this.usersPerPage,
        });
      }
    },
  },
};
</script>
