<template>
	<div
		:class="{
			'after-effects-templates': category === 'After Effects Templates',
			'after-effects-presets': category === 'After Effects Presets',
			'premiere-pro-templates': category === 'Premiere Pro Templates',
			'premiere-pro-presets': category === 'Premiere Pro Presets',
			'bg-green-400': category === 'Sound Effects',
			'bg-green-600': category === 'Royalty Free Music',
			'bg-indigo-400': category === 'Motion Graphics Templates',
			'bg-pink-400': category === 'Stock Motion Graphics'
		}"
		class="h-12 w-12 flex items-center justify-center rounded-full"
	>
		<span
			v-if="category.split(' ').includes('After')"
			class="iconify h-5 w-5 text-white"
			data-icon="cib:adobe-after-effects"
			data-inline="false"
		></span>

		<span
			v-if="category.split(' ').includes('Premiere')"
			class="iconify h-5 w-5 text-white"
			data-icon="cib:adobe-premiere"
			data-inline="false"
		></span>

		<span
			v-if="
				category.split(' ').includes('Sound') ||
					category.split(' ').includes('Royalty')
			"
			class="iconify h-5 w-5 text-white"
			data-icon="bi:file-music"
			data-inline="false"
		></span>

		<span
			v-if="category === 'Motion Graphics Templates'"
			class="iconify h-5 w-5 text-white"
			data-icon="heroicons-solid:template"
			data-inline="false"
		>
		</span>

		<span
			v-if="category === 'Stock Motion Graphics'"
			class="iconify h-5 w-5 text-white"
			data-icon="heroicons-solid:video-camera"
			data-inline="false"
		></span>
	</div>
</template>

<script>
export default {
	name: "CategoryBadge",
	props: ["category"],
	components: {}
}
</script>

<style>
.after-effects-presets {
	background-color: #4b4785;
}

.after-effects-templates {
	background-color: #393665;
}

.premiere-pro-presets {
	background-color: #6f4f7d;
}

.premiere-pro-templates {
	background-color: #523a5c;
}
</style>
