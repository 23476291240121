import { createStore } from "vuex";
import config from "./modules/config";
import modal from "./modules/modal";
import user from "./modules/user";
import author from "./modules/author";
import staff from "./modules/staff";
import alert from "./modules/alert";
import items from "./modules/items";
import email from "./modules/email";
import cheif from "./modules/cheif";
import order from "./modules/order";
import affiliate from "./modules/affiliate";

export default createStore({
  modules: {
    affiliate,
    order,
    user,
    email,
    author,
    cheif,
    items,
    alert,
    staff,
    modal,
    config,
  },
});
