<template>
	<div class="w-full flex flex-col flex-shrink">
		<label
			:id="`label${id}`"
			:for="id"
			class="flex-shrink-0 font-medium text-gray-900 dark:text-gray-300 text-sm"
			>{{ label }}</label
		>
		<div
			class="mt-1 text-sm flex items-center w-full flex-shrink border border-gray-300 dark:border-gray-700 rounded-md"
		>
			<!-- select -->
			<Listbox
				as="div"
				v-model="selectedOption"
				class="relative focus:outline-none flex-shrink-0"
			>
				<ListboxButton
					as="button"
					v-on:blur="$emit('onBlur')"
					class="flex w-full rounded-md focus:outline-none bg-gray-100 border-r border-gray-300 dark:border-gray-900 dark:bg-darkLight"
				>
					<span
						v-on:blur="handleFocusOut"
						class="px-3 py-2 w-full flex items-center justify-between text-left text-sm focus:outline-none rounded border text-gray-800 dark:text-gray-300"
					>
						{{ selectedOption.value }}
						<svg
							class="w-4 h-4 text-gray-600 dark:text-gray-300"
							viewBox="0 0 20 20"
							fill="currentColor"
						>
							<path
								fill-rule="evenodd"
								d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
								clip-rule="evenodd"
							/>
						</svg>
					</span>
				</ListboxButton>

				<Transition
					enter-active-class="transition duration-100"
					enter-from-class="opacity-0"
					enter-to-class="opacity-100"
					leave-active-class="transition duration-300"
					leave-from-class="opacity-100"
					leave-to-class="opacity-0"
				>
					<ListboxOptions
						class="max-h-56 min-w-full 2xl:w-72 mt-1 py-1 absolute overflow-y-auto focus:outline-none shadow border rounded-md bg-white z-50"
					>
						<ListboxOption
							v-for="option in opts"
							:key="option.id"
							:value="option"
							:disabled="option.unavailable"
							v-slot="{ selected, active }"
							class="focus:outline-none"
						>
							<div
								class="cursor-pointer flex-shrink-0 whitespace-nowrap px-3 py-2 relative bg-white focus:outline-none"
								:class="{
									'bg-gray-800 text-white': active,
									'bg-gray-100 text-gray-400': option.unavailable
								}"
							>
								{{ option.name }}
								<span
									v-show="selected && !option.unavailable"
									class="pr-2 flex items-center absolute inset-y-0 right-0"
								>
									<svg
										class="w-4 h-4 ml-3 "
										:class="active ? 'text-white' : 'text-gray-700'"
										viewBox="0 0 20 20"
										fill="currentColor"
									>
										<path
											fill-rule="evenodd"
											d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
											clip-rule="evenodd"
										/>
									</svg>
								</span>
							</div>
						</ListboxOption>
					</ListboxOptions>
				</Transition>
			</Listbox>
			<!-- end of select -->
			<input
				v-bind="$attrs"
				:id="id"
				:aria-labelledby="`label${id}`"
				class="w-full h-full px-3 flex-shrink text-sm bg-transparent text-gray-900 dark:text-gray-300 placeholder-gray-500 focus:outline-none"
			/>
		</div>
		<div v-if="error" class="mt-1 text-sm text-red-700">{{ error }}</div>
	</div>
</template>

<script>
import {
	Listbox,
	ListboxButton,
	ListboxOptions,
	ListboxOption
} from "@headlessui/vue"
export default {
	inheritAttrs: false,
	name: "InputWithSelect",
	components: {
		Listbox,
		ListboxButton,
		ListboxOptions,
		ListboxOption
	},
	emits: ["onChange", "onBlur"],
	props: {
		opts: {
			type: Array,
			required: true
		},
		error: String,
		label: {
			type: String
		},
		select: {
			type: Number,
			default: 0
		}
	},
	mounted() {
		this.id = Math.floor(Math.random() * 100000)
	},
	data() {
		return {
			id: 0,
			selectedOption: this.opts[this.select]
		}
	},

	watch: {
		selectedOption: function(val, oldVal) {
			this.$emit("onChange", val)
		}
	}
}
</script>
