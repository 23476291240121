import { v4 as uuid } from "uuid"
import axios from "axios"

const state = {
	orders: []
}

const getters = {
	getOrders: (state) => state.orders
}

const actions = {
	async fetchOrders({ commit }, id) {
		try {
			const { data } = await axios.get(
				`${process.env.VUE_APP_BASE_URl}/subscriber/order/${id}`
			)
			console.log({ data }, "order")
			commit("setOrders", data)
		} catch (error) {
			console.log(error)
		}
	}
}

const mutations = {
	setOrders: (state, val) => (state.orders = val)
}

export default {
	state,
	getters,
	actions,
	mutations
}
