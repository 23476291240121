import { v4 as uuid } from "uuid"
import axios from "axios"

const state = {
	emailTemplates: []
}

const getters = {
	getEmailTemplates: (state) => state.emailTemplates
}

const actions = {
	async handleUpdateEmailTemplate({ commit }, form) {
		try {
			await axios.patch(
				`${process.env.VUE_APP_BASE_URl}/email-template/update-email-template`,
				{ form }
			)
			commit("setAlert", {
				id: Math.floor(Math.random() * 10000),
				title: `${form.name} template updated`,
				sub: "",
				icon: "ant-design:check-circle-filled",
				type: "success"
			})
		} catch (error) {
			console.log(error)
		}
	},
	async handleFetchEmailTemplate({ commit }) {
		try {
			const { data } = await axios.get(
				`${process.env.VUE_APP_BASE_URl}/email-template/get-email-template`
			)
			commit("setEmailTemplates", data)
		} catch (error) {
			console.log(error)
		}
	}
}

const mutations = {
	setEmailTemplates: (state, val) => (state.emailTemplates = val)
}

export default {
	state,
	getters,
	actions,
	mutations
}
