import firebase from "firebase/app"
import "firebase/storage"
import "firebase/firestore"

const firebaseConfig = {
	apiKey: "AIzaSyBztEttDOOntwcmWn9TpQosKW_Rn1rFlJI",
	authDomain: "motion-ridge-admin.firebaseapp.com",
	projectId: "motion-ridge-admin",
	storageBucket: "motion-ridge-admin.appspot.com",
	messagingSenderId: "258583450006",
	appId: "1:258583450006:web:c3b6f02d185ba17c2ecdb7"
}
firebase.initializeApp(firebaseConfig)

const storage = firebase.storage()
const db = firebase.firestore()

export { storage }
